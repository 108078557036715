import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Button, Layout } from 'element-react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Form, Input, AsyncSelect, FormCollection, Contacts } from '../Common';
import {validateLength, validateLeastOneModel, likeFilter, whereInFilter} from '../../utils/utility';
import { apiSearchProductCategory, apiSearchCountry, apiSearchBusinessCompany } from '../../api/remote-search';
import permission from '../../utils/permission';

export class VendorCompanyForm extends Component {
    state = {
        rules: {
	        name_jp: [
		        { validator: (_, value, callback) => validateLength(value, callback, 40, this.props.t('businessCompany.validMaxNameLength')) }
	        ],
            business_companies: [
                { required: true, validator: (_, value, callback) => validateLeastOneModel(value, callback, this.props.t('global.pleaseSelectBusinessCompany')) }
            ],
            product_categories: [
                { required: true, validator: (_, value, callback) => validateLeastOneModel(value, callback, this.props.t('global.pleaseSelectProductCategory')) }
            ],
        },
        businessCompanies: [],
        productCategories: [],
        searching: false
    }

    componentDidUpdate(prevProps) {
        if (this.props.visible && !prevProps.visible) {
            if (!this.props.data.id) {
	            this.onCollectionAdd(null, 'business_companies');
	            this.onCollectionAdd(null, 'product_categories');
            } else {
                this.updateProductCategories();
            }
            if (!this.state.businessCompanies.length) {
                this.fetchData(apiSearchBusinessCompany, 'businessCompanies');
            }
        }
        if (!this.props.businessDialogVisible && prevProps.businessDialogVisible) {
            this.fetchData(apiSearchBusinessCompany, 'businessCompanies');
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        try {
          await this.validateName(this.props.data)
          .then(() => {
            this.refs.form.validate((valid) => {
                if (valid) {
                    this.props.onSubmit();
                    this.setState({ err: null })
                }
            });
          })
        } catch (err) {
            this.setState({ err: err.message })
        }
    }

    validateName = async (data) => {
        if (!data.name_jp && !data.name_en && !data.name_ch) {
            throw new Error(this.props.t('vendorCompany.pleaseInputName'))
        }
        return true
    }

    onCancel = () => {
        this.setState({ err: null });
        this.props.onCancel();
    }

    fetchData = async (searchFn, stateKey, query) => {
        this.setState({ [stateKey]: [] });
        query = query || {};
        const { data } = await searchFn({ ...query, limit: 'unlimited' });
        this.setState({ [stateKey]: data });
    }

    onSearch = async (query, searchFn) => {
        return new Promise(async (resolve) => {
            const { data } = await searchFn({ ...likeFilter('name', query) });
            resolve(data);
        });
    }

    updateProductCategories = () => {
	    let ids = this.props.data.business_companies.map(item => {
		    return item.id;
	    });
        if (ids.length) {
            this.fetchData(
                apiSearchProductCategory,
                'productCategories',
                whereInFilter('business_company_id', ids)
            );
        }
    }

	onCollectionAdd = (event, field) => {
		event && event.preventDefault();
		this.props.data[field].push({});
		this.props.onChangeHandler(this.props.data[field], field);
	}

	onCollectionChange = (id, item, index, field, callback) => {
		let data = this.props.data[field];
		data[index] = item;
		this.props.onChangeHandler(data, field, callback);
	}

	onCollectionRemove = (event, index, field, callback) => {
		event.preventDefault();
		const data = this.props.data[field];
		data.splice(index, 1);
		this.props.onChangeHandler(data, field, callback);
	}

    render() {
        const {
        	t,
	        data,
	        visible,
	        onAddContact,
	        onRemoveContact,
	        onChangeContactHandler,
	        newBusinessCompanyButton
        } = this.props;
        if (!data) return null;
        return (
            <Modal backdrop="static" size="lg" isOpen={visible} toggle={this.onCancel}>
                <Form onSubmit={this.handleSubmit} model={data} ref="form" rules={this.state.rules} labelWidth="160">
                    <ModalHeader toggle={this.onCancel}>
	                    {data.id ? t('vendorCompany.edit') : t('vendorCompany.add')}
                    </ModalHeader>
                    <ModalBody className="scrollable-modal">
                        <Layout.Row>
                            <Layout.Col span="22">
	                            <Form.Item label={t('global.name_jp')} prop="name_jp">
		                            <Input
			                            value={data.name_jp}
			                            onChange={(value) => this.props.onChangeHandler(value, 'name_jp')}
		                            />
	                            </Form.Item>
	                            <Form.Item label={t('global.name_en')} prop="name_en">
		                            <Input
			                            value={data.name_en}
			                            onChange={(value) => this.props.onChangeHandler(value, 'name_en')}
		                            />
	                            </Form.Item>
	                            <Form.Item label={t('global.name_ch')} prop="name_ch">
		                            <Input
			                            value={data.name_ch}
			                            onChange={(value) => this.props.onChangeHandler(value, 'name_ch')}
		                            />
                              {this.state.err && <div className="el-form-item__error">{this.state.err}</div>}
	                            </Form.Item>
	                            <Layout.Row>
		                            <Layout.Col md={permission('business-company:create') && newBusinessCompanyButton ? '16' : '24'}>
			                            <Form.Item label={t('global.businessCompany')} prop="business_companies">
				                            <FormCollection
					                            field={'business_companies'}
					                            items={data.business_companies}
					                            options={this.state.businessCompanies}
					                            onAdd={this.onCollectionAdd}
					                            onChange={this.onCollectionChange}
					                            onRemove={this.onCollectionRemove}
					                            updateData={this.updateProductCategories}
				                            />
			                            </Form.Item>
		                            </Layout.Col>
		                            {(permission('business-company:create') && newBusinessCompanyButton) &&
			                            <Layout.Col md="8">
				                            <Button className="pull-right" onClick={this.props.onAddBusinessCompany}>
					                            {t('businessCompany.add')}
				                            </Button>
			                            </Layout.Col>
		                            }
	                            </Layout.Row>
	                            <Form.Item label={t('global.productCategory')} prop="product_categories">
		                            <FormCollection
			                            field={'product_categories'}
			                            items={data.product_categories}
			                            options={this.state.productCategories}
			                            onAdd={this.onCollectionAdd}
			                            onChange={this.onCollectionChange}
			                            onRemove={this.onCollectionRemove}
		                            />
	                            </Form.Item>
	                            <Form.Item label={t('global.address')} prop="address">
		                            <Input
			                            value={data.address}
			                            onChange={(value) => this.props.onChangeHandler(value, 'address')}
		                            />
	                            </Form.Item>
	                            <Form.Item label={t('company.country')} prop="country_id">
		                            <AsyncSelect
			                            selected={data.country}
			                            value={data.country_id}
			                            remoteMethod={(value) => this.onSearch(value, apiSearchCountry)}
			                            onChange={(value) => this.props.onChangeHandler(value, 'country_id')}
			                            subLabel="code"
		                            />
	                            </Form.Item>
	                            <Contacts
		                            contacts={data.contacts}
		                            onAdd={onAddContact}
		                            onRemove={onRemoveContact}
		                            onChange={onChangeContactHandler}
	                            />
                            </Layout.Col>
                        </Layout.Row>
                    </ModalBody>
                    <ModalFooter>
                        <Form.Item>
                            <Button onClick={this.onCancel}>
                                {t('global.cancel')}
                            </Button>
                            <Button type="primary" nativeType="submit">
                                {t('global.submit')}
                            </Button>
                        </Form.Item>
                    </ModalFooter>
                </Form>
            </Modal>
        );
    }
}

export default withNamespaces()(VendorCompanyForm);

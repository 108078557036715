import * as actionTypes from './email.actions';
import { updateObject, paginationData } from '../../utils/utility';

const initialState = {
    filterData: {
        ...paginationData
    },
    emails: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_EMAIL: return getEmail(state, action);
        case actionTypes.FILTER_EMAIL: return filterEmail(state, action);
        default: return state;
    }
};

const getEmail = (state, action) => updateObject(state, {
    emails: action.emails,
    filterData: {
        ...state.filterData,
        totalPage: action.totalPage,
        total: action.total
    }
});

const filterEmail = (state, action) => updateObject(state, {
    filterData: action.filterData
});

export default reducer;

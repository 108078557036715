export default class UserRegistrationModel {
	constructor(data = {}) {
		this.assigner_no = data.assigner_no || null
		this.cert_draft_person = data.cert_draft_person || null;
		this.signature_one_person = data.signature_one_person || null;
		this.hand_in_draft_person = data.hand_in_draft_person || null;
		this.request_signature_person = data.request_signature_person || null;
    this.signature_two_person = data.signature_two_person || null;
		this.confirmation_send_person = data.confirmation_send_person || null;
	}
}

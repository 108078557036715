import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import createEncryptor from 'redux-persist-transform-encrypt';
import storage from 'redux-persist/lib/storage';
import contextReducer from './context.reducer';
import settingsReducer from './settings.reducer';
import themesReducer from './themes.reducers';
import staticDataReducer from './static-data.reducer';
import authReducer from '../../containers/Authentication/auth.reducers';
import countryReducer from '../../containers/Country/country.reducers';
import rootProductCategoryReducer from '../../containers/RootProductCategory/root-product-category.reducers';
import productCategoryReducer from '../../containers/ProductCategory/product-category.reducers';
import contactReducer from '../../containers/Contact/contact.reducers';
import companyReducer from '../../containers/Company/company.reducers';
import activityReducer from '../../containers/Activity/activity.reducers';
import emailReducer from '../../containers/Email/email.reducers';
import businessCompanyReducer from '../../containers/BusinessCompany/business-company.reducers';
import { vendorCompanyRecuder } from '../../containers/VendorCompany';
import { userReducer } from '../../containers/User';
import { factoryEvaluationReducer } from '../../containers/FactoryEvaluation';
import { vendorFactoryReducer } from '../../containers/VendorFactory';
import { roleReducer } from '../../containers/Role';
import { certificationIssuanceReducer } from '../../containers/CertificateIssuance';

const encryptor = createEncryptor({
    secretKey: process.env.REACT_APP_PERSIT_SECRET_KEY,
    onError: function (error) {
        console.log(error)
    }
});

const staticDataPersistConfig = {
    transforms: [encryptor],
    key: 'staticData',
    storage: storage,
    blacklist: ["isLoaded"]
};

const appReducer = combineReducers({
    context: contextReducer,
    settings: settingsReducer,
    theme: themesReducer,
    staticData: persistReducer(staticDataPersistConfig, staticDataReducer),
    auth: authReducer,
    country: countryReducer,
    rootProductCategory: rootProductCategoryReducer,
    productCategory: productCategoryReducer,
    contact: contactReducer,
    company: companyReducer,
    businessCompany: businessCompanyReducer,
    vendorCompany: vendorCompanyRecuder,
    user: userReducer,
    factoryEvaluation: factoryEvaluationReducer,
    vendorFactory: vendorFactoryReducer,
    role: roleReducer,
    activity: activityReducer,
    certificationIssuance: certificationIssuanceReducer,
    email: emailReducer
});

const rootReducer = (state, action) => {
    if (action.type === 'AUTH_LOGOUT') {
        // storage.removeItem('persist:root');
        state = {
            settings: state.settings,
            theme: state.theme,
            staticData: state.staticData
        };
        // state = undefined;
    }
    return appReducer(state, action)
}

export default rootReducer;

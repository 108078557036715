import { FETCH_CONTEXT } from '../actions/actions';

const initialSettings = {
    name: '',
    color: '',
};

const contextReducer = (state = initialSettings, action) => {

    if (action.type === FETCH_CONTEXT) {
        state = {...action.data}
    }

    return state;
}

export default contextReducer;

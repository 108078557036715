import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button, Loading } from 'element-react';
import { Select } from '../../components/Common';
import { Table } from 'reactstrap';
import { apiGetSelectUsers, apiGetCertificationIssuanceConfiguration, apiSaveCertificationIssuanceConfiguration } from '../../api/certification-issuance';
import { UserRegistrationModel } from '../../models';
import permission from '../../utils/permission';
import Message from '../../utils/Message';

class UserRegistrationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditing: false,
            isSubmitting: false,
            data: null,
            amount: 0,
        }
    }

    async componentDidMount() {
        let users = await apiGetSelectUsers({ limit: 'unlimited' })
        let data = await apiGetCertificationIssuanceConfiguration({ config: true });
        let config = [];
        data.data.forEach((el) => {
          let model = new UserRegistrationModel(el)
          config.push(model)
        });
        this.setState({ users: users.data, amount: data.data.length, data: config})
    }

    onChangeHandler = (value, no, dataKey) => {
      const data = { ...this.state.data };
      data[no][dataKey] = value;
      this.setState({ data });
    }

    toggleEdit = () => {
        this.setState({ isEditing: !this.state.isEditing })
    }

    onSubmit = async () => {
        this.setState({
            ...this.state,
            isEditing: false,
            isSubmitting: true
        })
        await apiSaveCertificationIssuanceConfiguration(this.state.data).then(res => {
            this.setState({
                ...this.state,
                isSubmitting: false
            })
            Message.success(this.props.t('global.success'))
        })
        .catch(err => Message.error(err));
    }

    getHeaders = (amount) => {
        let headers = [];
        for (let i = 0; i < this.state.amount; i++) {
            headers.push(
                <React.Fragment key={i}>
                    <th><p style={{width: '150px'}}>{i + 1 + '. ' + this.props.t('certificationIssuance.name')}</p></th>
                    <th><p style={{width: '150px'}}>{i + 1 + '. ' + this.props.t('certificationIssuance.email')}</p></th>
                </React.Fragment>
            );
        }
        return headers;
    }

    getEmail = (step) => {
        if (!step) {
            return
        } else if (Number.isInteger(step)) {
            let user = this.state.users.find(el => el.id === step)
            if (!user) {
                return 'undefined';
            }
            return user.email
        } else {
            return step.email
        }
    }

    getName = (step) => {
        if (!step) {
            return
        } else if (Number.isInteger(step)) {
            let user = this.state.users.find(el => el.id === step)
            if (!user) {
                return 'undefined';
            }
            return user.full_name
        } else {
            return step.full_name
        }
    }

    getUsers = (step) => {
        let users = [];
        for (let i = 0; i < this.state.amount; i++) {
            users.push(
                <React.Fragment key={i}>
                    <td style={{width: '200px'}}>
                        {this.state.isEditing ?
                        <Select
                            value={this.state.data[i][step] && (this.state.data[i][step].id || this.state.data[i][step])}
                            onChange={val => this.onChangeHandler(val, i, step)}
                            options={this.state.users}
                            label={'full_name'}
                            menuPortalTarget={document.body}
                        /> : this.state.data[i][step] && this.getName(this.state.data[i][step])}
                    </td>
                    <td style={{width: '200px'}}>{this.state.data[i][step] && this.getEmail(this.state.data[i][step])}</td>
                </React.Fragment>
            );
        }
        return users;
    }

    addUser = () => {
        let user = new UserRegistrationModel();
        user.assigner_no = this.state.amount + 1;
        this.setState(prevState => {
            return {
                data: prevState.data ? [...prevState.data, user] : [user],
                amount: prevState.amount + 1
            }
        })
    }

    render() {
        const { t, isOpen, toggle } = this.props;
        const { isEditing, isSubmitting } = this.state
        return (
            <React.Fragment>
              <Modal className='registration-modal' size='xl' backdrop="static" isOpen={isOpen} toggle={toggle}>
                  <ModalHeader toggle={toggle}>
                    {t('certificationIssuance.userRegistration')}
                  </ModalHeader>
                  <ModalBody className="user-container scrollable-modal">
                  <Loading loading={!this.state.users}>
                  <Table striped bordered hover responsive>
                      <thead>
                          <tr>
                              <th><p style={{width: '150px'}}>{t('certificationIssuance.role')}</p></th>
                              <th><p style={{width: '150px'}}>{t('certificationIssuance.organization')}</p></th>
                              {this.getHeaders(this.state.amount)}
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td style={{width: '200px'}}>{t('certificationIssuance.createDraft')}</td>
                              <td style={{width: '200px'}}>{t('certificationIssuance.tuvRheinlandJapan')}</td>
                              {this.getUsers('cert_draft_person')}
                          </tr>
                          <tr>
                              <td style={{width: '200px'}}>{t('certificationIssuance.signatureOne')}</td>
                              <td style={{width: '200px'}}>{t('certificationIssuance.tuvRheinlandJapan')}</td>
                              {this.getUsers('signature_one_person')}
                          </tr>
                          <tr>
                              <td style={{width: '200px'}}>{t('certificationIssuance.handInDraft')}</td>
                              <td style={{width: '200px'}}>{t('certificationIssuance.tuvRheinlandJapan')}</td>
                              {this.getUsers('hand_in_draft_person')}
                          </tr>
                          <tr>
                              <td style={{width: '200px'}}>{t('certificationIssuance.requestSignature')}</td>
                              <td style={{width: '200px'}}>{t('certificationIssuance.sevenAndI')}</td>
                              {this.getUsers('request_signature_person')}
                          </tr>
                          <tr>
                              <td style={{width: '200px'}}>{t('certificationIssuance.signatureTwo')}</td>
                              <td style={{width: '200px'}}>{t('certificationIssuance.sevenAndI')}</td>
                              {this.getUsers('signature_two_person')}
                          </tr>
                          <tr>
                              <td style={{width: '200px'}}>{t('certificationIssuance.sendConfirmation')}</td>
                              <td style={{width: '200px'}}>{t('certificationIssuance.sevenAndI')}</td>
                              {this.getUsers('confirmation_send_person')}
                          </tr>
                      </tbody>
                  </Table>
                  </Loading>
                  </ModalBody>
                  {permission('isAdmin') &&
                  <ModalFooter>
                      <Button onClick={() => this.toggleEdit()}>{isEditing ? t('global.editing') : t('global.edit')}</Button>
                      <Button onClick={() => this.onSubmit()}>{isSubmitting ? <em className="fa fa-circle-notch fa-spin"></em> : t('global.submit')}</Button>
                      <Button onClick={() => this.addUser()}>{t('certificationIssuance.addUser')}</Button>
                  </ModalFooter>
                  }
              </Modal>
            </React.Fragment>
        );
    }
}

export default UserRegistrationModal;

import React from 'react';
import { Table } from 'reactstrap';
import { TableActions } from '../Common';
import { withNamespaces } from 'react-i18next';
import permission from '../../utils/permission';
import { apiGetFilters } from '../../api/vendor-company';
import FilterContent from '../Common/FixedTable/FilterContent';

export const VendorCompanyList = (props) => {
    const { vendorCompanies, t } = props;
    return (
        <Table striped bordered hover responsive className='vendor-table'>
            <thead>
                <tr>
                    <th className="text-center">#</th>
                    <th><p style={{width: '150px'}}>{t('global.name')}</p>
                        <FilterContent
                            id={1}
                            emptyOnEnd={false}
                            onlyOne={false}
                            loadDataFn={() => apiGetFilters('company_name')}
                            filterOptions={{
                              id: 'name',
                              label: 'name'
                            }}
                            initData={props.filterData['company_name']}
                            onFilterChange={(data) => props.onChangeWithFilter(data, 'company_name')}
                            i18n={t}
                        >
                        <span className="el-table__column-filter-trigger" id={'Popover-' + 1}>
                            <i className={`fa fa-caret-square-down ${(props.filterData['company_name'] && props.filterData['company_name'].length) ? "filtered" : ""}`} />
                        </span>
                        </FilterContent>
                    </th>
                    <th><p style={{width: '150px'}}>{t('global.businessCompany')}</p>
                        <FilterContent
                            id={2}
                            emptyOnEnd={false}
                            withoutNull={true}
                            onlyOne={false}
                            loadDataFn={() => apiGetFilters('business_company')}
                            filterOptions={{
                              id: 'id',
                              label: 'name'
                            }}
                            initData={props.filterData['business_company']}
                            onFilterChange={(data) => props.onChangeWithFilter(data, 'business_company')}
                            i18n={t}
                        >
                        <span className="el-table__column-filter-trigger" id={'Popover-' + 2}>
                            <i className={`fa fa-caret-square-down ${(props.filterData['business_company'] && props.filterData['business_company'].length) ? "filtered" : ""}`} />
                        </span>
                        </FilterContent>
                    </th>
                    <th><p style={{width: '150px'}}>{t('global.productCategory')}</p>
                        <FilterContent
                            id={3}
                            emptyOnEnd={false}
                            withoutNull={true}
                            onlyOne={false}
                            loadDataFn={() => apiGetFilters('product_category')}
                            filterOptions={{
                              id: 'id',
                              label: 'name'
                            }}
                            initData={props.filterData['product_category']}
                            onFilterChange={(data) => props.onChangeWithFilter(data, 'product_category')}
                            i18n={t}
                        >
                        <span className="el-table__column-filter-trigger" id={'Popover-' + 3}>
                            <i className={`fa fa-caret-square-down ${(props.filterData['product_category'] && props.filterData['product_category'].length) ? "filtered" : ""}`} />
                        </span>
                        </FilterContent>
                    </th>
                    <th><p style={{width: '150px'}}>{t('global.address')}</p>
                        <FilterContent
                            id={4}
                            emptyOnEnd={false}
                            onlyOne={false}
                            loadDataFn={() => apiGetFilters('address')}
                            filterOptions={{
                              id: 'name',
                              label: 'name'
                            }}
                            initData={props.filterData['address']}
                            onFilterChange={(data) => props.onChangeWithFilter(data, 'address')}
                            i18n={t}
                        >
                        <span className="el-table__column-filter-trigger" id={'Popover-' + 4}>
                            <i className={`fa fa-caret-square-down ${(props.filterData['address'] && props.filterData['address'].length) ? "filtered" : ""}`} />
                        </span>
                        </FilterContent>
                    </th>
                    <th><p style={{width: '150px'}}>{t('company.country')}</p>
                        <FilterContent
                            id={5}
                            emptyOnEnd={false}
                            onlyOne={false}
                            loadDataFn={() => apiGetFilters('country')}
                            filterOptions={{
                              id: 'id',
                              label: 'name'
                            }}
                            initData={props.filterData['country_id']}
                            onFilterChange={(data) => props.onChangeWithFilter(data, 'country_id')}
                            i18n={t}
                        >
                        <span className="el-table__column-filter-trigger" id={'Popover-' + 5}>
                            <i className={`fa fa-caret-square-down ${(props.filterData['country_id'] && props.filterData['country_id'].length) ? "filtered" : ""}`} />
                        </span>
                        </FilterContent>
                    </th>
                    {Array.apply(null, Array(6)).map((i, idx)=> (
                        <React.Fragment key={idx}>
                            <th><p style={{minWidth: '100px'}}>{idx+1}. {t('contact.fullName')}</p></th>
                            <th><p style={{minWidth: '100px'}}>{idx+1}. {t('contact.email')}</p></th>
                            <th><p style={{minWidth: '100px'}}>{idx+1}. {t('contact.mobile')}</p></th>
                            <th><p style={{minWidth: '100px'}}>{idx+1}. {t('contact.phone')}</p></th>
                        </React.Fragment>
                    ))}
                    {permission('vendor-company:update,delete') && <th/>}
                </tr>
            </thead>
            <tbody>
                {vendorCompanies.map((vendor) => (
                    <tr key={vendor.id}>
                        <td align="center">{vendor.id}</td>
                        <td>{vendor.name}</td>
                        <td>{vendor.business_companies && vendor.business_companies.map(item => item.name).join(', ')}</td>
                        <td>{vendor.product_categories && vendor.product_categories.map(item => item.name).join(', ')}</td>
                        <td>{vendor.address}</td>
                        <td>{vendor.country && vendor.country.name}</td>
                        {vendor.contacts && vendor.contacts.slice(0,6).map((contact) => (
                            <React.Fragment key={contact.id}>
                                <td>{contact && contact.name}</td>
                                <td>{contact && contact.email}</td>
                                <td>{contact && contact.mobile_number}</td>
                                <td>{contact && contact.phone_number}</td>
                            </React.Fragment>
                        ))}
                        {vendor.contacts && vendor.contacts.length <= 6 && Array.apply(null, Array(6 - vendor.contacts.length)).map((i, idx)=> (
                            <React.Fragment key={idx}>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </React.Fragment>
                        ))}
                        {permission('vendor-company:update,delete') &&
                            <td align="center">
                                <TableActions
                                    roleName="vendor-company"
                                    onSelect={() => props.onSelect(vendor)}
                                    onDelete={() => props.onDelete(vendor.id)}
                                />
                            </td>
                        }
                    </tr>
                ))}
                {!vendorCompanies.length &&
                    <tr>
                        <td colSpan="9" align="center">
                            {t('global.notMatchingRecordsFound')}
                        </td>
                    </tr>
                }
            </tbody>
        </Table>
    )
}

export default withNamespaces()(VendorCompanyList);

import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({height, path}) => {
	return (
		<img src={path} height={height} alt="Logo" />
	);
}

Logo.defaultProps = {
    path: 'img/logo.png',
    height: 80,
};

Logo.propTypes = {
    path: PropTypes.string,
    height: PropTypes.number,
}

export default Logo;

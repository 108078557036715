import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import { connect } from 'react-redux';
import { Button, Loading, Layout } from 'element-react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Message from '../../utils/Message';
import permission from '../../utils/permission';
import Editor from '../../components/Common/Editor';
import { Form } from '../../components/Common';
import Websocket from '../../config/echo';
import { withFilter } from '../../hoc';
import { Pagination } from '../../components/Common';
import ResultsNumber from '../../components/Common/ResultsNumber';
import * as actions from '../../store/actions/actions';
import { ConfirmAction, CertificateIssuanceList, CreateDraftModal, CertPreviewModal, UserRegistrationModal, ScapPreviewModal } from '../../components/CertificateIssuance';
import { CertificationDraftModel } from '../../models';
import {
    apiCreateCert,
    apiAddSignature,
    apiHandInDraft,
    apiRequestSignature,
    apiCertificateConfirmation,
    apiFindCertificationIssuance,
    apiGetCertificationIssuanceConfiguration,
    apiBatchSign,
    apiProxyRequest,
    apiDeclineAssignment,
    apiPullback,
    apiRemindMail,
    apiCancelCertification,
    apiUpdateComment,
    apiGetCount,
    apiResendCertificate,
    apiGetB64Data
} from '../../api/certification-issuance';
import { apiAddHash } from '../../api/blockchain';
import {
    fetchStaticData,
    setStaticDataLoaded,
} from '../../store/actions/actions';
import { jsPDF } from "jspdf";
import * as html2canvas from "html2canvas";
import { getStepIds } from '../../utils/certificates';

class CertificateIssuance extends Component {
    _isMounted = false;
    _id = this.props.match.params.id;
    constructor(props) {
  		super(props);
  		this.getEditorData = null;
	    this.editors = {};
      this.state = {
        submitting: false,
        batchSubmitting: false,
        proxySubmitting: false,
        filters: {},
        firstLoading: true,
        draft: false,
        preview: false,
        previewButtons: true,
        scapPreview: false,
        userRegistration: false,
        data: null,
        certIssuance: null,
        config: null,
        isCommentOpen: false,
        editorTitle: null,
  			editorField: null,
  			editorContent: null,
        awaitingCertificates: [],
        message: '',
        confirmationModal: false
      }
    }

    async componentDidMount() {
        this._isMounted = true;
        let config = await apiGetCertificationIssuanceConfiguration({ config: false });
        let total = await apiGetCount();
        if (this._id) {
            await this.initData(this._id);
        } else {
            this.filter();
        }
        this.setState({ config: config.data, total: total.data });
        this.getStaticData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    filter = async (params) => {
        const filterColumns = [
            'business_company_name',
            'audit_number',
            'vendor_factory_name',
            'vendor_company_name',
            'cert_recipient_name',
            'cert_recipient_email',
            'cert_period',
            'evaluation',
            'cert_draft',
            'signature_1',
            'hand_in_draft',
            'signature_2_request',
            'signature_2',
            'confirmation',
            'cert_receipt'
        ];

        const newFilter = { ...this.props.filterData, ...params };
        let filter = {
            limit: newFilter.limit,
            page: newFilter.page,
            ...this.props.likeFilter('name', newFilter.name),
            ...this.props.orderBy(newFilter.orderBy),
        };
        filterColumns.forEach(column => filter = {
            ...filter,
            ...this.props.whereInFilter(column, newFilter[column])
        });
        await this.props.fetchCertificationIssuance(filter);
            if (this._isMounted) {
                this.setState({
                    filters: filter,
                    firstLoading: false,
                });
          }
    }

    onChangeWithFilter = (value, key) => {
        this.props.onChangeWithFilterAndResetPage(value, key);
        this.setState(prevState => ({
            isRenderList: !prevState.isRenderList
        }));
    };

    initData = async (id) => {
  		const { data } = await apiFindCertificationIssuance(id);
      this.setState({ certificationIssuances: [data] });
      this.filter();
  	};

    toggleDraftModal = (e, certIssuance) => {
        this.setState({ draft: !this.state.draft, dataModel: certIssuance ? new CertificationDraftModel(certIssuance) : null, data: certIssuance ? certIssuance : null  })
    }

    togglePreviewModal = async (e, certIssuance, buttons = true, step = null) => {
        if (e && e.target.nodeName === 'INPUT') {
            return;
        } else {
            let b64Data = certIssuance && certIssuance.certificate_file && await apiGetB64Data(certIssuance.id, { path: certIssuance.certificate_file.path });
            this.setState({ preview: !this.state.preview, previewButtons: buttons, data: certIssuance ? certIssuance : null, currentStep: step, b64Data: b64Data ? b64Data : null})
        }
    }

    toggleScapPreviewModal = (certIssuance) => {
        this.setState({ scapPreview: !this.state.scapPreview, preview: this.state.preview, data: certIssuance ? certIssuance : this.state.data })
    }

    toggleUserRegistrationModal = () => {
        this.setState({ userRegistration: !this.state.userRegistration })
    }


    onChangeHandler = (value, dataKey) => {
      const dataModel = { ...this.state.dataModel };
      dataModel[dataKey] = value;
      this.setState({ dataModel });
    }

    getStaticData = async () => {
      if (!this.props.staticDataLoaded) {
          Promise.all([
              this.props.fetchStaticData('certification_issuances'),
              this.props.fetchStaticData('latest_evaluation_scores')
            ]).then(() => {
                this.props.setStaticDataLoaded();
            })
        }
    }

    createPdf = async () => {
        this.setState({ submitting: true })
        let content = await document.getElementById('printable');
        html2canvas(content, {quality: 1, scale: 2, allowTaint: true , scrollX:0, scrollY: 0}).then(async canvas => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF("p", "pt", "a4", true);
          const imgProps= pdf.getImageProperties(imgData);
          const width = pdf.internal.pageSize.getWidth();
          const height = (imgProps.height * width) / imgProps.width;

          pdf.addImage(imgData, 'png', 0, 0,  width,  height, 'FAST');
          let file = pdf.output('blob');

          await apiCreateCert({ issuance_id: this.state.data.id, file: file, name: `${this.state.dataModel.audit_number}-cert` })
          this.setState({ submitting: false });
          this.filter();
          this.toggleDraftModal();
        });
    }

    addSignature = async (type) => {
        this.setState({ submitting: true })
        await apiAddSignature({ issuance_id: this.state.data.id, type: type })
        await this.filter();
        let signed = await apiFindCertificationIssuance(this.state.data.id)
        document.getElementById('cert-preview').src = `/scap?file=/pdf?file=${signed.data.certificate_file.path}`
        this.setState({ submitting: false, data: signed ? signed.data : null })

    }

    handInDraft = async () => {
        this.setState({ submitting: true })
        await apiHandInDraft({ issuance_id: this.state.data.id })
        this.setState({ submitting: false });
        this.filter();
        this.togglePreviewModal();
    }

    requestSignature = async () => {
        this.setState({ submitting: true })
        await apiRequestSignature({ issuance_id: this.state.data.id})
        this.setState({ submitting: false });
        this.filter();
        this.togglePreviewModal();
    }

    certConfirm  = async () => {
        this.setState({ submitting: true })
        await apiCertificateConfirmation({ issuance_id: this.state.data.id })
        await apiAddHash(this.state.data.id);
        this.setState({ submitting: false });
        this.filter();
        this.togglePreviewModal();
    }

    decline = async (data, step) => {
        this.onToggleConfirmationModal(this.props.t('certificationIssuance.declineMsg'), data, step, 'decline', 'comments', 'Comment');
    }

    pullback = async (data, step) => {
        this.onToggleConfirmationModal(this.props.t('certificationIssuance.pullbackMsg'), data, step, 'pullback', 'comments', 'Comment');
    }

    remind = async (data, step) => {
        this.onToggleConfirmationModal(this.props.t('certificationIssuance.remindMsg'), data, step, 'remind', 'comments', 'Comment');
    }

    cancel = async (data) => {
        this.onToggleConfirmationModal(this.props.t('certificationIssuance.cancelMsg'), data, null, 'cancel', 'comments', 'Comment');
    }

    toggleResendDialog = async (id, contact, visible) => {
        this.setState({
            resend: {
                ...this.state.resend,
                visible: visible,
                id: id,
                contact: contact
            }
        })
    }

    resendCertificate = async (id) => {
            this.setState({
                resend: {
                    ...this.state.resend,
                    loading: true
                }
            })
            await apiResendCertificate({ issuance_ids: [id]}).then(res => {
            this.setState({
                resend: {
                    ...this.state.resend,
                    loading: false,
                    visible: false,
                    id: null,
                    contact: null
                }
            })
            Message.success(this.props.t('global.success'))
            })
            .catch(err => Message.error(err));
    }

    resendAllCertificates = async () => {
            this.setState({
                resend: {
                    ...this.state.resend,
                    resendingAll: true
                }
            })
            await this.props.fetchAllCertificationIssuance();
            let ids = this.props.allCertificationIssuances.filter(item => item.confirmation !== null && item.cert_receipt === null).map(cert => cert.id);
            await apiResendCertificate({ issuance_ids: ids}).then(res => {
            this.setState({
                resend: {
                    ...this.state.resend,
                    resendingAll: false
                }
            })
            Message.success(this.props.t('global.success'))
            })
            .catch(err => Message.error(err));
    }

    openComment = (issuanceId, issuance, step=null, action, editorField, editorTitle) => {
        apiFindCertificationIssuance(issuanceId).then(item => {
    			let editorContent = item.data[editorField];
    			this.setState({
    				...{submitLock: false},
            isCommentOpen: true,
    				issuanceId,
    				issuance,
    				editorField,
    				editorContent,
    				editorTitle,
            step: step,
            action: action,
    			});
    		});
    }

    closeComment = () => {
      this.setState(prevState => {
  			return {
  				isCommentOpen: !prevState.isCommentOpen
  			}
  		});
    }

    handleCommentSubmit = async (e) => {
        e.preventDefault();
        await apiUpdateComment(this.state.data.id, {issuance_id: this.state.data.id, comment: this.editors.editor1()})
        switch (this.state.action) {
            case 'decline':
                await apiDeclineAssignment({certIssuance: this.state.data, step: this.state.step});
                    break;
            case 'pullback':
                await apiPullback({certIssuance: this.state.data, step: this.state.step});
                    break;
            case 'cancel':
                await apiCancelCertification({issuance_id: this.state.data.id});
                    break;
            default: return;
        }
        this.filter();
        this.closeComment();
        this.togglePreviewModal();
        this.onToggleConfirmationModal();
    }

    getScapFile = (certIssuance) => {
        if (certIssuance.factory_evaluation && certIssuance.factory_evaluation.audit_files) {
            let arr = certIssuance.factory_evaluation.audit_files.flatMap((file) => !file.is_scap ? [] : file)
            return arr[arr.length - 1] && arr[arr.length - 1].path;
        }
    }

    addToAwaitingCertificates = (e, cert) => {
      let awaitingCertificates = this.state.awaitingCertificates || []
      if (e.target.checked) {
          awaitingCertificates.push(cert.id)
          this.setState({ awaitingCertificates: awaitingCertificates })
      } else {
          let idx = this.state.awaitingCertificates.indexOf(cert.id)
          awaitingCertificates.splice(idx, 1)
          this.setState({ awaitingCertificates: awaitingCertificates })
      }
    }

    isChecked = (certIssuance) => {
        if (this.state.awaitingCertificates) {
            return this.state.awaitingCertificates.indexOf(certIssuance.id) !== -1 ? true : false;
        } else {
            return false;
        }
    }

    proxyRequest = async () => {
        this.setState({ proxySubmitting: true });
        if (this.state.awaitingCertificates.length !== 0) {
            await apiProxyRequest({ issuances: this.state.awaitingCertificates, email: this.props.user.email })
            await this.filter();
            this.setState({ proxySubmitting: false, awaitingCertificates: [] });
        } else {
            this.setState({ proxySubmitting: false });
        }
    }

    batchSubmit  = async () => {
        this.setState({ batchSubmitting: true });
        await this.props.fetchAllCertificationIssuance();
        let signatureOneIds = getStepIds(this.state.config, 'signature_one_person')
        let signatureTwoIds = getStepIds(this.state.config, 'signature_two_person')
        if (signatureOneIds.includes(this.props.user.id)) {
            let awaitingCertificates = []
            this.props.allCertificationIssuances.forEach(cert => {
                if (cert.signature_1_status === 2) {
                    return awaitingCertificates.push(cert.id)
                } else {
                    return;
                }
            })
            awaitingCertificates.length !== 0 && await apiBatchSign({ issuances: awaitingCertificates, email: this.props.user.email });
            awaitingCertificates.length !== 0 && await this.filter();
            this.setState({ batchSubmitting: false });
        } else if (signatureTwoIds.includes(this.props.user.id)) {
            let awaitingCertificates = []
            this.props.allCertificationIssuances.forEach(cert => {
                if (cert.signature_2_status === 2) {
                    return awaitingCertificates.push(cert.id)
                } else {
                    return;
                }
            })
            awaitingCertificates.length !== 0 && await apiBatchSign({ issuances: awaitingCertificates, email: this.props.user.email });
            awaitingCertificates.length !== 0 && await this.filter();
            this.setState({ batchSubmitting: false });
        } else {
            if (this.state.awaitingCertificates.length !== 0) {
                await apiBatchSign({ issuances: this.state.awaitingCertificates, email: this.props.user.email })
                await this.filter();
                this.setState({ batchSubmitting: false, awaitingCertificates: [] });
            } else {
                this.setState({ batchSubmitting: false });
            }
        }
    }

    setTopScrollbarWidth = () => {
        let topBar = document.querySelector('.top-scrollbar__content');
        let table = document.querySelector('.cert-table');
        if (topBar && table) {
            topBar.style.width = table.offsetWidth + 'px';
        }
    }

    syncScrollbars = () => {
      let topBar = document.querySelector('.top-scrollbar');
      let grid = document.querySelector('.cert-scroll');
      if (grid && topBar) {
          topBar.scrollLeft = grid.scrollLeft
      }
    }

    onToggleConfirmationModal = (message, data, step=null, action, editorField, editorTitle) => {
        if (!this.state.confirmationModal) {
            this.setState({ confirmationModal: true, message, data, step, action, editorField, editorTitle })
        } else {
            this.setState({ confirmationModal: false })
        }
    }

    handleConfirm = async () => {
        if(this.state.action === 'remind' && this.state.step === 'signature_2') {
              await apiRemindMail({certIssuance: this.state.data, step: this.state.step})
              this.filter();
              this.onToggleConfirmationModal();
              this.togglePreviewModal();
        } else {
            this.openComment(this.state.data.id, this.state.data, this.state.step, this.state.action, this.state.editorField, this.state.editorTitle);
        }
    }

    handleCancel = () => {
        this.setState({ confirmationModal: false, message: '', data: null, step: null, action: '', editorField: '', editorTitle: '' })
    }

    isFilterApplied = () => {
      let filters = Object.assign({}, this.state.filters);
      return Object.keys(filters).length > 2;
    }

    canSendProxyReq = (userId) => {
        let certDraftIds = getStepIds(this.state.config, 'cert_draft_person');
        let requestSignatureIds = getStepIds(this.state.config, 'request_signature_person');
        let ids = certDraftIds.concat(requestSignatureIds)

        return ids.includes(userId) ? false : true;
    }

    render() {
        const { t, filterData } = this.props;
        const { data, b64Data, editorField, editorContent, editorTitle } = this.state;
        let isFilterApplied = this.isFilterApplied();
        return (
            <ContentWrapper>
                <ConfirmAction
                    isOpen={this.state.confirmationModal}
                    message={this.state.message}
                    onToggle={this.onToggleConfirmationModal}
                    onSubmit={this.handleConfirm}
                />
                {this.state.userRegistration && <UserRegistrationModal
                    isOpen={this.state.userRegistration}
                    toggle={this.toggleUserRegistrationModal}
                    t={this.props.t}
                />}
                <Modal backdrop="static" size="xl" isOpen={this.state.isCommentOpen} toggle={this.closeComment}>
        					<Form onSubmit={this.handleCommentSubmit} ref="form" labelWidth="150">
        						<ModalHeader toggle={this.closeComment}>
          							{editorTitle}
        						</ModalHeader>
        						<ModalBody className="user-container scrollable-modal">
        							{this.state.data && this.state.data.id && <Editor
        								t={t}
								        id={this.state.data.id}
                        name={editorField}
        								text={editorContent}
        								pull={callback => this.editors.editor1 = callback}
        								mute={!Websocket.isEnable()}
        								userName={this.props.user.group_name}
                        lock={false}
                        sync={false}
        							/>}
        						</ModalBody>
        						<ModalFooter>
        							<Form.Item>
        								<Button onClick={this.closeComment}>
        									{t('global.cancel')}
        								</Button>
        								<Button type="primary" nativeType="submit" disabled={this.state.submitLock}>
        									{t('global.submit')}
        								</Button>
        							</Form.Item>
        						</ModalFooter>
        					</Form>
        				</Modal>
                {this.state.data && this.state.draft && <CreateDraftModal
                    isOpen={this.state.draft}
                    toggle={this.toggleDraftModal}
                    data={this.state.data}
                    dataModel={this.state.dataModel}
                    onChangeHandler={this.onChangeHandler}
                    createPdf={this.createPdf}
                    loading={this.state.submitting}
                    t={this.props.t}
                    user={this.props.user}
                    config={this.state.config}
                    getScapFile={this.getScapFile}
                    openScapPreview={this.toggleScapPreviewModal}
                />}
                {this.state.data && this.state.preview && <CertPreviewModal
                    isOpen={this.state.preview}
                    toggle={this.togglePreviewModal}
                    title='Preview'
                    data={data}
                    b64Data={b64Data.data}
                    userEmail={this.props.user.email}
                    config={this.state.config}
                    t={this.props.t}
                    loading={this.state.submitting}
                    type={
                        getStepIds(this.state.config, 'signature_one_person').includes(this.props.user.id) && !data.signature_2_request ? 1 :
                        getStepIds(this.state.config, 'signature_two_person').includes(this.props.user.id) && data.signature_2_request ? 2 :
                        null
                    }
                    previewButtons={this.state.previewButtons}
                    addSignature={this.addSignature}
                    handInDraft={this.handInDraft}
                    requestSignature={this.requestSignature}
                    sendConfirmation={this.certConfirm}
                    decline={this.decline}
                    pullback={this.pullback}
                    remind={this.remind}
                    cancel={this.cancel}
                    getScapFile={this.getScapFile}
                    openScapPreview={this.toggleScapPreviewModal}
                    currentStep={this.state.currentStep && this.state.currentStep}
                />}
                {this.state.data && this.state.scapPreview && <ScapPreviewModal
                    t={this.props.t}
                    isOpen={this.state.scapPreview}
                    toggle={this.toggleScapPreviewModal}
                    path={this.getScapFile(this.state.data)}
                />}
                <div className="content-heading">
                    <div>
                      {t('certificationIssuance.certificateIssuance')}
                    </div>
                </div>
                <Layout.Row>
                    <Layout.Col sm="5">
                        {isFilterApplied && <ResultsNumber count={filterData.total} />}
                    </Layout.Col>
                    <Layout.Col sm="5">
                        {this.state.total && <ResultsNumber count={this.state.total} allData={true} />}
                    </Layout.Col>
                    <Layout.Col sm={isFilterApplied ? "14" : "19"}>
                        <div className="pagination-right" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <div className="mr-2">
                                    <Button onClick={() => this.toggleUserRegistrationModal()}>{t('certificationIssuance.userRegistration')}</Button>
                                </div>
                            <Pagination
                                pageCount={filterData.totalPage}
                                total={filterData.total}
                                pageSize={filterData.limit}
                                initialPage={filterData.page}
                                onPageChange={this.props.onPageChange}
                            />
                        </div>
                    </Layout.Col>
                </Layout.Row>
                <Loading loading={this.state.firstLoading && !this.props.certificationIssuances.length}>
                    <CertificateIssuanceList
                        certificateIssuances={this.props.certificationIssuances}
                        filterData={filterData}
                        openDraft={this.toggleDraftModal}
                        openPreview={this.togglePreviewModal}
                        openScapPreview={this.toggleScapPreviewModal}
                        language={this.props.language}
                        certPeriodOpts={this.props.certification_issuances}
                        evalScoreOpts={this.props.latest_evaluation_scores}
                        config={this.state.config}
                        user={this.props.user}
                        getScapFile={this.getScapFile}
                        refresh={this.filter}
                        onChangeWithFilter={this.onChangeWithFilter}
                        addToAwaitingCertificates={this.addToAwaitingCertificates}
                        isChecked={this.isChecked}
                        setTopScrollbarWidth={this.setTopScrollbarWidth}
                        syncScrollbars={this.syncScrollbars}
                        resendCertificate={this.resendCertificate}
                        toggleResendDialog={this.toggleResendDialog}
                        resend={this.state.resend && this.state.resend}
                    />
                </Loading>
                <Button style={{ margin: '5px 5px 5px 0', width: '200px' }} type='primary' loading={this.state.batchSubmitting} onClick={() => this.batchSubmit()}>{t('certificationIssuance.batchSubmit')}</Button>
                <Button style={{ margin: '5px 5px 5px 0', width: '200px' }} type='primary' loading={this.state.proxySubmitting} disabled={this.state.config ? this.canSendProxyReq(this.props.user.id) : true} onClick={() => this.proxyRequest()}>{t('certificationIssuance.proxySignReq')}</Button>
                {permission('isAdmin') && <Button style={{ margin: '5px 5px 5px 0', width: '200px' }} type='primary'  onClick={() => this.resendAllCertificates()}>{this.state.resend && this.state.resend.resendingAll ? <em className="fa fa-circle-notch fa-spin"></em> : t('certificationIssuance.resendAll') }</Button>}
            </ContentWrapper>
        );
    }
}

const mapStateToProps = state => {
    return {
        certificationIssuances: state.certificationIssuance.certificationIssuances,
        allCertificationIssuances: state.certificationIssuance.allCertificationIssuances,
        filterData: state.certificationIssuance.filterData,
        language: state.settings.language,
        user: state.auth.user,
        certification_issuances: state.staticData.certification_issuances,
        latest_evaluation_scores: state.staticData.latest_evaluation_scores,
    };
};

const mapDispatchToProps = dispatch => ({
    fetchCertificationIssuance: (params) => dispatch(actions.fetchCertificationIssuance(params)),
    fetchAllCertificationIssuance: () => dispatch(actions.fetchAllCertificationIssuance()),
    fetchStaticData: (table) => dispatch(fetchStaticData(table)),
    setStaticDataLoaded: () => dispatch(setStaticDataLoaded()),
    setReduxFilter: (params) => dispatch(actions.setFilterCertificationIssuance(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(withFilter(CertificateIssuance)));

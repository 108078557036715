import { apiGetContext } from '../../api/context';

export const FETCH_CONTEXT = 'FETCH_CONTEXT';

const fetchContextSuccess = (res) => ({
    type: FETCH_CONTEXT,
    data: res.data
});

export const fetchContext = () => {
    return async dispatch => {
        try {
            const res = await apiGetContext();
            dispatch(fetchContextSuccess(res));
            return Promise.resolve(res.data);
        } catch (err) {
            return Promise.reject(err);
        }
    };
};

import React, { Component } from 'react';
import {
    Popover as BSPopover,
    PopoverHeader,
    PopoverBody
} from 'reactstrap';
import { Checkbox, Loading, Button, Layout } from 'element-react';
import DateFilterGroup from '../DateFilterGroup';

class FilterContent extends Component {
    state = {
        popoverOpen: false,
        loading: true,
        checkAll: false,
        checkAllFiltered: false,
        label: this.props.filterOptions['label'],
        keyId: this.props.filterOptions['id'],
        filtersData: [],
        checkedFilters: [],
        isIndeterminate: false,
        filterText: ''
    }

    toggle = async () => {
        const prevPopoverStatus = this.state.popoverOpen;
        this.setState({
            popoverOpen: !this.state.popoverOpen
        });
        if (!prevPopoverStatus) {
            let { data } = await this.props.loadDataFn();
            const initData = this.props.initData;

            if (this.props.emptyOnEnd) {
	            data = this.appendNullOption(data, this.state.label);
            } else {
              if (!this.props.withoutNull) {
	                data = this.prependNullOption(data, this.state.label);
              }
            }

			const isNotNull = initData && 1 === initData.length && 'not_null' === initData[0];
            const filtersData = data.map((item, key) => {
            	if (isNotNull) {
		            item.checked = 0 === key ? false : true;
	            } else if (initData && initData.length) {
                    item.checked = initData.includes(item[this.state.keyId]);
                } else {
                    item.checked = this.state.checkAll;
                }
                return item;
            });
            const uncheckedIndex = filtersData.findIndex(item => !item.checked);
            const checkAll = uncheckedIndex !== -1 ? false : true;
            const checkedIndex = filtersData.findIndex(item => item.checked);
            const isIndeterminate = !checkAll ? (checkedIndex !== -1 ? true : false) : false;

            this.setState({
                filtersData,
                checkAll,
                isIndeterminate,
                loading: false
            });
        }
    }

    handleCheckAllFilteredChange(checked, callback) {
        let filtersData = this.state.filtersData.map(item => {
            if (this.isVisible(item[this.state.label])) {
                    item.checked = checked;
                    return item;
            } else {
                    item.checked = false;
                    return item;
            }
        })

        let filterText = this.state.filterText;

        this.setState({
            isIndeterminate: false,
            checkAll: false,
            checkAllFiltered: checked,
            filtersData,
            filterText: filterText
        }, () => callback && callback());
    }

    handleCheckAllChange(checked, callback) {
        let filtersData = this.state.filtersData.map(item => {
            item.checked = checked;
            return item;
        });

        let filterText = '';

        this.setState({
            isIndeterminate: false,
            checkAll: checked,
            checkAllFiltered: checked,
            filtersData,
            filterText: filterText
        }, () => callback && callback());
    }

    handleFilterAllByYear = (checked, year) => {
        let filtersData = this.state.filtersData.map(item => {
            if (item[this.state.label] !== null) {
                if (item[this.state.label].split('-')[0] === year.name) {
                    item.checked = checked;
                }
            }
            return item;
        });
        this.setState({ filtersData })
    }

    handleFilterAllByMonth = (checked, year, month) => {
        let filtersData = this.state.filtersData.map(item => {
            if (item[this.state.label] !== null) {
                if (item[this.state.label].split('-')[0] === year.name && item[this.state.label].split('-')[1] === month.name) {
                    item.checked = checked;
                }
            }
            return item;
        });
        this.setState({ filtersData })
    }

    handleDayChange = (item) => {
        let filtersData = this.state.filtersData.map(el => {
            if (el[this.state.label] !== null) {
                if (el[this.state.label] === item.name) {
                    el.checked = !el.checked;
                }
            }
            return el;
        });
        this.setState({ filtersData })
    }

    handleFilterNull = (item) => {
        let filtersData = this.state.filtersData.map(el => {
            if (el[this.state.label] === null) {
                if (el[this.state.label] === item.name) {
                    el.checked = !el.checked;
                }
            }
            return el;
        });
        this.setState({ filtersData })
    }


    handleFiltersChange(value, index) {
        const filtersData = [...this.state.filtersData];
        if (this.props.onlyOne) {
            filtersData.map((el) => {
              if (filtersData.indexOf(el) !== index) {
                  el.checked = false;
              }
              return el;
            })
        }
        filtersData[index].checked = value;
        const uncheckedIndex = filtersData.findIndex(item => !item.checked);
        const checkAll = uncheckedIndex !== -1 ? false : true;
        const checkedIndex = filtersData.findIndex(item => item.checked);
        const isIndeterminate = !checkAll ? (checkedIndex !== -1 ? true : false) : false;

        this.setState({
            filtersData,
            checkAll,
            isIndeterminate,
        });
    }

    onFilter = (e) => {
        let filterText = e.target.value;
        this.handleCheckAllChange(false);
        this.setState({ filterText: filterText });
    }

    isVisible = (value) => {
        if (null === value || '' === this.state.filterText.trim()) {
            return true;
        } else {
            if (typeof value  === 'string') {
                value = value.toLowerCase();
                let filterText = this.state.filterText.toLowerCase();
                return value.includes(filterText);
            }
        }
    };

	appendNullOption = (data, labelKey) => {
		let empty = {};
		empty[labelKey] = null;
		data.push(empty);
		return data;
	};

    prependNullOption = (data, labelKey) => {
        let empty = {};
        empty[labelKey] = null;
        return [empty].concat(data);
    };

    onConfirm = () => {
        this.toggle();

        if (this.state.checkAll) {
            return this.props.onFilterChange([]);
        }
	    const ignored = this.state.filtersData
		    .filter(item => !item.checked);

        let selected = [];

	    if (1 === ignored.length && null === ignored[0][this.state.label]) {
	    	selected = ['not_null'];
	    } else {
		    selected = this.state.filtersData
			    .filter(item => item.checked)
			    .map(item => item[this.state.keyId]);
	    }

        this.props.onFilterChange(selected);
    }

    onSortBy = (value) => {
        this.toggle();
        this.props.sortByChange(this.props.sortColumn, value);
    }

    isDate = (name) => {
        let re = /^\d{4}[/-](0?[1-9]|1[012])[/-](0?[1-9]|[12][0-9]|3[01])$/;
        let ok = re.exec(name);
        return ok
    }

    render() {
        const { title, id, i18n, sortColumn, currentSortData } = this.props;
        const { filtersData, label } = this.state;
        return (
            <React.Fragment>
                <span onClick={this.toggle}>
                    {this.props.children}
                </span>
                <BSPopover className="filter-content-container" placement={'bottom'} isOpen={this.state.popoverOpen} target={'Popover-' + id} toggle={this.toggle}>
                    <PopoverHeader>{title}</PopoverHeader>
                    <PopoverBody className={'el-table-filter'}>
                        <Loading loading={this.state.loading}>
                            <div className="el-table-filter__content" key="content">
                                {sortColumn ?
                                    <Layout.Row gutter="15" className="mb-sm">
                                        <Layout.Col span="12">
                                            <Button
                                                className="btn-block"
                                                type={(currentSortData
                                                    && currentSortData.column === sortColumn
                                                    && currentSortData.value === 'asc'
                                                ) ? 'primary' : null}
                                                icon="arrow-down"
                                                size="mini"
                                                onClick={() => this.onSortBy("asc")}
                                            >
                                                {i18n('global.ascending')}
                                        </Button>
                                        </Layout.Col>
                                        <Layout.Col span="12">
                                            <Button
                                                className="btn-block"
                                                type={(currentSortData
                                                    && currentSortData.column === sortColumn
                                                    && currentSortData.value === 'desc'
                                                ) ? 'primary' : null}
                                                icon="arrow-up"
                                                size="mini"
                                                onClick={() => this.onSortBy("desc")}
                                            >
                                                {i18n('global.descending')}
                                        </Button>
                                        </Layout.Col>
                                    </Layout.Row>
                                    : null}
                                {!this.props.onlyOne && filtersData[1] && !this.isDate(filtersData[1].name) && <Checkbox
                                    checked={this.state.checkAll || this.state.checkAllFiltered}
                                    indeterminate={this.state.isIndeterminate}
                                    onChange={this.state.filterText ? this.handleCheckAllFilteredChange.bind(this) : this.handleCheckAllChange.bind(this)}>
	                                {i18n('global.selectAll')}
                                </Checkbox>}
                                <div className="mb-sm"/>
                                <div className="el-input">
                                    <i className="el-input__icon el-icon-search"/>
                                    <input
                                      placeholder="Search"
                                      type="text"
                                      className="el-input__inner"
                                      autoComplete="off"
                                      onChange={this.onFilter}
                                      value={this.state.filterText}/>
                                </div>
                                <div className="checkboxes-list">
                                    {filtersData[1] && !this.isDate(filtersData[1].name) ?
                                        filtersData.map((item, index) =>
                                        this.isVisible(item[label]) &&
                                        <Checkbox
                                            key={index}
                                            checked={item.checked}
                                            label={item[label]}
                                            onChange={(value) => this.handleFiltersChange(value, index)} />)
                                        :
                                        <DateFilterGroup
                                            filtersData={filtersData}
                                            label={label}
                                            filterByYear={this.handleFilterAllByYear}
                                            filterByMonth={this.handleFilterAllByMonth}
                                            handleFilterChange={this.handleDayChange}
                                            handleFilterNull={this.handleFilterNull}
                                        />
                                    }
                                </div>
                            </div>
                        </Loading>
                        <div className="el-table-filter__bottom" key="bottom">
                            <button onClick={this.onConfirm}>
	                            {i18n('global.confirm')}
                            </button>
                            <button className="pull-right" onClick={() => this.handleCheckAllChange(false, () => this.onConfirm())}>
	                            {i18n('global.reset')}
                            </button>
                        </div>
                    </PopoverBody>
                </BSPopover>
            </React.Fragment>
        )
    }
}

export default FilterContent;

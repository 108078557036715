import axios from '../config/axios';
import { convertFormData } from '../utils/utility';

export function apiGetCertificationIssuance(params) {
  return axios.get('certification-issuance', { params });
};

export function apiFindCertificationIssuance(id) {
  return axios.get(`certification-issuance/${id}`);
};

export function apiCreateCert(data) {
    return axios.post('certification-issuance/create-cert', convertFormData(data));
};

export function apiAddSignature(data) {
    return axios.post('certification-issuance/add-signature', convertFormData(data));
};

export function apiBatchSign(data) {
    return axios.post(`certification-issuance/batch-sign`, data);
};

export function apiProxyRequest(data) {
    return axios.post(`certification-issuance/proxy-request`, data);
};

export function apiHandInDraft(data) {
    return axios.post('certification-issuance/hand-in-draft', data);
};

export function apiRequestSignature(data) {
    return axios.post('certification-issuance/request-signature', data);
};

export function apiCertificateConfirmation(data) {
    return axios.post('certification-issuance/cert-confirmation', data);
};

export function apiDeclineAssignment(data) {
    return axios.post('certification-issuance/decline', data);
};
export function apiPullback(data) {
    return axios.post('certification-issuance/pullback', data);
};
export function apiRemindMail(data) {
    return axios.post('certification-issuance/reminder', data);
};
export function apiCancelCertification(data) {
    return axios.post('certification-issuance/cancel', data);
};

export function apiUpdateContact(data) {
    return axios.post(`certification-issuance/update-contact`, data);
};

export function apiUpdateComment(id, data) {
    return axios.put(`certification-issuance/${id}/update-comment`, data);
};

export function apiGetCertificationIssuanceConfiguration(params) {
  return axios.get('cert-user-registration', {params})
}
export function apiSaveCertificationIssuanceConfiguration(data) {
  return axios.post('cert-user-registration', data)
}

export function apiGetSelectUsers(params) {
    return axios.get(`cert-user-registration/get-users`, {params});
};

export function apiGetFilters(name, params) {
    return axios.get(`certification-issuance/filters/${name}`, {params});
};

export function apiGetB64Data(id, params) {
    return axios.get(`certification-issuance/get-b64-data/${id}`, {params});
};

export function apiGetCount() {
    return axios.post(`certification-issuance/count`);
};

export function apiResendCertificate(data) {
    return axios.post(`certification-issuance/resend-cert`, data);
};

import * as actionTypes from './auth.actions';
import { updateObject } from '../../utils/utility';

const initialState = {
    access_token: null,
    refresh_token: null,
    user: null,
    role: {},
    permissions: [],
    isAuthenticated: false,
    accessedRoutes: [],
	authCodeSession: null,
	authCodeVerifyFail: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_LOGIN: return auth(state, action);
        case actionTypes.SET_TOKEN: return setToken(state, action);
        case actionTypes.AUTH_PROFILE: return authProfile(state, action);
        case actionTypes.UPDATE_PROFILE: return authUpdateProfile(state, action);
        case actionTypes.UPDATE_AVATAR: return authUpdateAvatar(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.SET_ROUTES: return setRoutes(state, action);
	    case actionTypes.AUTH_CODE_SET_SESSION: return authCodeSetSession(state, action);
	    case actionTypes.AUTH_CODE_VERIFY_FAIL: return authCodeVerifyFail(state, action);
        default: return state;
    }
};

const authCodeSetSession = (state, action) => updateObject(state, {
	authCodeSession: action.session
});

const authCodeVerifyFail = (state, action) => updateObject(state, {
	authCodeVerifyFail: action.isFail
});

const auth = (state, action) => updateObject(state, {
    access_token: action.access_token,
    refresh_token: action.refresh_token,
    isAuthenticated: false,
	authCodeSession: false,
	authCodeVerifyFail: false
});

const setToken = (state, action) => updateObject(state, {
    access_token: action.access_token,
    refresh_token: action.refresh_token
});

const authProfile = (state, action) => updateObject(state, {
    user: action.user,
    permissions: action.user.role_permissions,
    role: action.user.role,
    isAuthenticated: true
});

const authUpdateAvatar = (state, action) => updateObject(state, { user: { ...state.user, image: action.image } });

const authUpdateProfile = (state, action) => updateObject(state, { user: action.user });

const setRoutes = (state, action) => updateObject(state, { accessedRoutes: action.accessedRoutes });

const authLogout = (state) => updateObject(state, {
    access_token: null,
    refresh_token: null,
    user: null,
    isAuthenticated: false,
});

export default reducer;

import React, { Component } from "react";
import * as actions from "../../store/actions/actions";
import { connect } from "react-redux";

class Footer extends Component {
    render() {
        const year = new Date().getFullYear()
        return (
            <footer className="footer-container">
                <span>&copy; {year} - {this.props.name}</span>
            </footer>
        );
    }
}

const mapStateToProps = state => ({
    name: state.context.name,
});

const mapDispatchToProps = dispatch => ({
    fetchContext: () => dispatch(actions.fetchContext())
})

export default connect(mapStateToProps, mapDispatchToProps)(Footer);

import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import { FixedTable, Select } from '../../Common';
import { apiGetFilters } from '../../../api/factory-evaluation';
import permission from '../../../utils/permission';
import { getEvaluationResultRowStyle } from '../../../utils/status';
import { FilterHierarchy } from '../../../utils/FilterHierarchy';
import { filterEvaluationResult } from '../../../utils/filterEvaluationResult';
import { filterTotalStatuses } from '../../../utils/filterTotalStatuses';
import File from '../../Common/File/File';
import { Checkbox } from 'element-react';
import moment from 'moment';
import AuditFile from '../../../utils/AuditFile';
import BusinessOther from '../../../utils/BusinessOther';
import NumberPopup from '../../Common/NumberPopup';
import OtherBusinessModal from '../../Common/OtherBusinessModal';
import { Link } from 'react-router-dom';
import EvaluationFee from '../../Common/EvaluationFee';

const tableColumnWd = 100;

class FactoryEvaluationListDefault extends Component {

    shouldComponentUpdate(prevProps) {
        return prevProps.isRender !== this.props.isRender;
    }

    isVisible = (column) => {
        let visible;
        if (this.props.preferences) {
            this.props.preferences.map((item) => {
                if (item.id === column) {
                      visible = item.is_visible;
                }
                return visible
            })
        }
        return visible;
    }

    resolveFy2020(original) {
        if (original.vendor_factory) {
            const { t } = this.props;
            let value = original.vendor_factory.fy2020;
            if (true === value) {
                return t('vendorFactory.yes')
            }
            if (false === value) {
                return t('vendorFactory.no');
            }
        }
        return '';
    }

    breakEmails(emails) {
        return emails.replace(';', ' ');
    }

    columnDecorator(preferences, columns) {;
        let left = 27;
        return preferences && preferences.map(preference => {
            columns.map(item => {
            if (true !== item.skip && item.id === preference.id && (true === preference.is_visible || 1 === preference.is_visible) && item.fixed) {
                item.left = left;
                left += (item.width + 14);
            }
            return item;
        });
        return null
        });
    }

    evaluationResultFilter(evaluationResults, auditYear, original) {
	    if (auditYear && 2021 > auditYear) {
		    return evaluationResults.filter(item => 2021 > item.audit_year);
	    }
    	if (auditYear && 2021 === auditYear) {
		    return evaluationResults.filter(item => 2021 === item.audit_year);
	    }
      if (auditYear && 2022 === auditYear) {
		    return evaluationResults.filter(item => filterEvaluationResult(item, original));
	    }
    	return [];
    }

    totalStatusFilter(totalStatuses, auditYear, original) {
        if (totalStatuses && auditYear && 2022 === auditYear) {
		        return totalStatuses.filter(item => filterTotalStatuses(item, original));
        }
        return [];
    }

    createOtherBusinessModal(names, id, t, lang) {
    	return (
    		<div>
			    {names.map((item) => {
			    	return (
					    item.isRoot ? <OtherBusinessModal id={item.id} t={t} lng={lang} evaluationId={id} key={id}>
					        <span className="el-table__column-filter-trigger" style={{color: 'blue'}} id={'testPopover-' + item.id}>
					            {ReactHtmlParser(item.label)}{names.length > 1 ? ',' : ''}
					        </span>
					    </OtherBusinessModal> : ' ' + item.label
				    )
			    })}
		    </div>
	    );
    }

    render() {
        const config = {
            width: {
                en: {
                    certificate_mail_date: 80,
                    certificate_mail_receipt: 80,
                    total_status: 462,
                    nc: 40,
                    no_of_evidences: 80,
                    audit_scap_file: 180,
                    audit_evid_file: 180,
                    audit_cert_file: 180,
                    audit_related_file: 180,
                    deadline: 144,
                    audit_category: 215,
                    audit_type: 215,
                    certification_issuance: 280,
                    exception_flag: 326,
                    evaluation_status: 498,
                    evaluation_result: 320,
                    judgement_confirm: 180,
                    post_audit_status: 474,
                    fy2020: 50,
                    audit_year: 50,
                    latest_evaluation_score: 250,
                    previous_evaluation_score: 70,
                    status_changed_date: 70,
                    audit_date: 70,
                    preferred_audit_date: 70,
                    effective_date: 70,
                    expiration_date: 70,
                    scap_deadline_date: 80,
                    scap_received_date: 70,
                    judgement_due_date: 90,
                    re_re_audit_date: 100,
                    exam_evidence_eval_sheet: 180,
                    exam_evidence_rep_and_rem: 180,
                    mfg_total_status: 300,
                    evidence_cert: 180,
                    mfg_institution: 240,
                    mfg_evidence_sheet: 180,
                    mfg_evidence_rep_and_rem: 180,
                    mfg_comment_hd_bus: 300,
                    mfg_memo: 300,
                    evaluation_result_previous: 240,
                },
                jp: {
                    certificate_mail_date: 70,
                    certificate_mail_receipt: 60,
                    total_status: 346,
                    nc: 40,
                    no_of_evidences: 80,
                    audit_scap_file: 180,
                    audit_evid_file: 180,
                    audit_cert_file: 180,
                    audit_related_file: 180,
                    deadline: 146,
                    audit_category: 156,
                    audit_type: 156,
                    certification_issuance: 196,
                    exception_flag: 248,
                    evaluation_status: 318,
                    evaluation_result: 505,
                    judgement_confirm: 180,
                    post_audit_status: 308,
                    fy2020: 80,
                    audit_year: 50,
                    latest_evaluation_score: 238,
                    previous_evaluation_score: 50,
                    status_changed_date: 70,
                    audit_date: 70,
                    preferred_audit_date: 70,
                    issuance_date: 70,
                    expiration_date: 70,
                    scap_deadline_date: 70,
                    scap_received_date: 70,
                    judgement_due_date: 70,
                    re_re_audit_date: 80,
                    exam_evidence_eval_sheet: 180,
                    exam_evidence_rep_and_rem: 180,
                    mfg_total_status: 300,
                    evidence_cert: 180,
                    mfg_institution: 240,
                    mfg_evidence_sheet: 180,
                    mfg_evidence_rep_and_rem: 180,
                    mfg_comment_hd_bus: 300,
                    mfg_memo: 300,
                    evaluation_result_previous: 240,
                }
            }
        };
        const {
            factoryEvaluations,
            auditTypes,
            auditCategories,
            exceptionFlags,
            postAuditStatuses,
            deadlines,
            certificationIssuances,
            totalStatuses,
            evaluationResults,
            latestEvaluationScores,
            judgementRanks,
            mfgTotalStatuses,
            mfgInstitutions,
            statuses,
            onChangeHandler,
            language,
            filterData,
            freezePaneIndex,
            is7p,
            isHldgs,
            preferences,
            t
        } = this.props;

        const h = new FilterHierarchy(filterData);
        h.addHierarchy('factory_evaluations_$table.business_company_id');
        h.addHierarchy('factory_evaluations_$table.product_category_id');
        h.addHierarchy('product_sub_category_id');
        h.addHierarchy('audit_year');
        h.addHierarchy('evaluation_result_id');

        return (
            <FixedTable
                data={factoryEvaluations}
                preferences={this.props.preferences}
                filterData={filterData}
                columnDecorator={this.columnDecorator}
                i18n={t}
                sortByHandler={this.props.onSortByWithFilter}
                getTrProps={item => {
                    return {
                        className: getEvaluationResultRowStyle(item)
                    }
                }}
                columns={[
                    {
                        id: 'business_company',
                        type: 'common',
                        fixed: preferences && freezePaneIndex > preferences.map(e => e.id).indexOf('business_company'),
                        Header: t('factoryEvaluation.businessCo'),
                        width: 110,
                        Cell: ({ original }) => ReactHtmlParser(BusinessOther.getBusinessCompanyName(original)),
                        isVisible: this.isVisible('business_company'),
	                    filters: [
		                    {
			                    sortColumn: 'business_company',
			                    filterOptions: {
				                    id: 'id',
				                    label: 'name',
			                    },
			                    filteredValue: filterData['factory_evaluations_$table.business_company_id'],
			                    loadDataFn: () => apiGetFilters('business_company'),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'factory_evaluations_$table.business_company_id');
			                    }
		                    }
	                    ]
                    },
                    {
                        id: 'product_category',
                        type: 'common',
                        fixed: preferences && freezePaneIndex > preferences.map(e => e.id).indexOf('product_category'),
                        Header: t('factoryEvaluation.category'),
                        width: 90,
                        Cell: ({ original }) => (
                            original.product_category ? original.product_category.name : ''
                        ),
	                    isVisible: this.isVisible('product_category'),
	                    filters: [
		                    {
			                    sortColumn: 'product_category',
			                    filterOptions: {
				                    id: 'id',
				                    label: 'name',
			                    },
			                    filteredValue: filterData['factory_evaluations_$table.product_category_id'],
			                    loadDataFn: () => apiGetFilters('product_category', h.getQuery('factory_evaluations_$table.product_category_id')),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'factory_evaluations_$table.product_category_id');
			                    }
		                    }
	                    ]
                    },
                    {
                        id: 'business_company_other',
                        fixed: preferences && freezePaneIndex > preferences.map(e => e.id).indexOf('business_company_other'),
                        Header: t('factoryEvaluation.businessCoOther'),
                        type: 'common',
                        accessor: 'id',
						className: 'comment-column',
						isExpandable: true,
                        width: 235,
	                    Cell: ({ original }) => (
		                    [2020, 2021, 2022].includes(original.audit_year) ?
		                    this.createOtherBusinessModal(BusinessOther.resolveNames(original), original.id, t, this.props.language) :
		                    ReactHtmlParser(BusinessOther.resolveNames(original).map(item => item.label).join(', '))
	                    ),
                        isVisible: this.isVisible('business_company_other'),
	                    filters: [
		                    {
			                    filterOptions: {
				                    id: 'id',
				                    label: 'name',
			                    },
			                    filteredValue: filterData['business_company_other'],
			                    loadDataFn: () => apiGetFilters('business_company_other', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'business_company_other');
			                    }
		                    }
						]
                    },
                    {
                        id: 'product_sub_category',
                        type: 'common',
                        fixed: preferences && freezePaneIndex > preferences.map(e => e.id).indexOf('product_sub_category'),
                        Header: t('factoryEvaluation.subCategory'),
                        accessor: 'product_sub_category_id',
                        skip: !is7p,
                        width: 150,
                        className: 'has-select',
                        Cell: ({ original }) => (
                            original.product_sub_category && original.product_sub_category[`name_${language}`]
                        ),
                        sortColumn: `product_sub_category_${language}`,
                        isVisible: this.isVisible('product_sub_category'),
	                    filters: [
		                    {
			                    filterOptions: {
				                    id: 'id',
				                    label: `name_${language}`,
			                    },
			                    filteredValue: filterData['product_sub_category_id'],
			                    loadDataFn: () => apiGetFilters('product_sub_category', h.getQuery('product_sub_category_id')),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'product_sub_category_id');
			                    }
		                    }
						]
                    },
                    {
                        id: 'working_group',
                        type: 'common',
                        fixed: preferences && freezePaneIndex > preferences.map(e => e.id).indexOf('working_group'),
                        Header: t('factoryEvaluation.workingGroup'),
                        Desc: t('factoryEvaluation.workingGroupDesc'),
                        accessor: 'working_group_id',
                        skip: !is7p,
                        width: 150,
                        className: 'has-select',
                        Cell: ({ original }) => (
                            original.working_group && original.working_group[`name_${language}`]
                        ),
                        isVisible: this.isVisible('working_group'),
	                    filters: [
		                    {
			                    sortColumn: `working_group_${language}`,
			                    filterOptions: {
				                    id: 'id',
				                    label: `name_${language}`,
			                    },
			                    filteredValue: filterData['working_group_id'],
			                    loadDataFn: () => apiGetFilters('working_group', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'working_group_id');
			                    }
		                    }
	                    ]
                    },
                    {
                        id: 'product',
                        type: 'common',
                        fixed: preferences && freezePaneIndex > preferences.map(e => e.id).indexOf('product'),
                        Header: t('factoryEvaluation.productItem'),
                        Desc: t('factoryEvaluation.productItemDesc'),
                        accessor: 'product_id',
                        skip: !is7p,
                        width: 161,
                        className: 'has-select',
                        Cell: ({ original }) => (
                            original.product && original.product.name
                        ),
                        isVisible: this.isVisible('product'),
	                    filters: [
		                    {
			                    sortColumn: 'product',
			                    filterOptions: {
				                    id: 'id',
				                    label: 'name',
			                    },
			                    filteredValue: filterData['product_id'],
			                    loadDataFn: () => apiGetFilters('product', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'product_id');
			                    }
		                    }
						]
                    },
                    {
                        id: 'vendor_factory',
                        type: 'common',
                        fixed: preferences && freezePaneIndex > preferences.map(e => e.id).indexOf('vendor_factory'),
                        Header: t('factoryEvaluation.factory_asia'),
                        accessor: 'vendor_factory.name_asia',
                        width: 130,
                        Cell: ({ original, value }) => (
                          value && permission('factory-evaluation:update') ?
                              <Link to={{ pathname: `/factory-evaluation/${original.id}` }} title={value}>
                                  <span>{value}</span>
                              </Link> : value
                        ),
                        isVisible: this.isVisible('vendor_factory'),
	                    filters: [
		                    {
			                    sortColumn: 'vendor_factory',
			                    filterOptions: {
				                    id: 'id',
				                    label: 'name',
			                    },
			                    filteredValue: filterData['vendor_factory_name'],
			                    loadDataFn: () => apiGetFilters('vendor_factory_name', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'vendor_factory_name');
			                    }
		                    }
	                    ]
                    },
                    {
                        id: 'vendor_factory_en',
                        type: 'common',
                        fixed: preferences && freezePaneIndex > preferences.map(e => e.id).indexOf('vendor_factory_en'),
                        Header: t('factoryEvaluation.factory_en'),
                        accessor: 'vendor_factory.name_en',
                        width: 130,
                        Cell: ({ original, value }) => (
                          value && permission('factory-evaluation:update') ?
                              <Link to={{ pathname: `/factory-evaluation/${original.id}` }} title={value}>
                                  <span>{value}</span>
                              </Link> : value
                        ),
                        isVisible: this.isVisible('vendor_factory_en'),
	                    filters: [
		                    {
			                    sortColumn: 'vendor_factory_en',
			                    filterOptions: {
				                    id: 'name_en',
				                    label: 'name_en',
			                    },
			                    filteredValue: filterData['vendor_factory_name_en'],
			                    loadDataFn: () => apiGetFilters('vendor_factory', {'field': 'name_en', ...h.getQuery()}),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'vendor_factory_name_en');
			                    },
		                    }
						]
                    },
                    {
                        id: 'vendor_company',
                        type: 'common',
                        Header: t('factoryEvaluation.company_asia'),
                        accessor: 'vendor_company.name_asia',
                        width: 130,
                        isVisible: this.isVisible('vendor_company'),
	                    filters: [
		                    {
			                    sortColumn: 'vendor_company',
			                    filterOptions: {
				                    id: 'id',
				                    label: 'name',
			                    },
			                    filteredValue: filterData['vendor_company_name'],
			                    loadDataFn: () => apiGetFilters('vendor_company_name', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'vendor_company_name');
			                    }
		                    }
						]
                    },
                    {
                        id: 'vendor_company_en',
                        type: 'common',
                        Header: t('factoryEvaluation.company_en'),
                        accessor: 'vendor_company.name_en',
                        width: 130,
                        isVisible: this.isVisible('vendor_company_en'),
	                    filters: [
		                    {
			                    sortColumn: 'vendor_company_en',
			                    filterOptions: {
				                    id: 'name_en',
				                    label: 'name_en',
			                    },
			                    filteredValue: filterData['vendor_company_name_en'],
			                    loadDataFn: () => apiGetFilters('vendor_company', {'field': 'name_en', ...h.getQuery()}),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'vendor_company_name_en');
			                    }
		                    }
	                    ]
                    },
                    {
                        id: 'audit_year',
                        type: 'common',
                        Header: t('factoryEvaluation.auditYear'),
                        accessor: 'audit_year',
                        width: config.width[language].audit_year,
                        isVisible: this.isVisible('audit_year'),
	                    filters: [
		                    {
			                    sortColumn: 'audit_year',
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData.audit_year,
			                    loadDataFn: () => apiGetFilters('audit_year',  h.getQuery('audit_year')),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'audit_year');
			                    }
		                    }
						]
                    },
                    {
                        id: 'location',
                        type: 'common',
                        Header: t('factoryEvaluation.location'),
                        accessor: 'vendor_factory.country.name',
                        width: tableColumnWd,
                        isVisible: this.isVisible('location'),
	                    filters: [
		                    {
			                    sortColumn: 'country',
			                    filterOptions: {
				                    id: 'id',
				                    label: 'name'
			                    },
			                    filteredValue: filterData['country'],
			                    loadDataFn: () => apiGetFilters('country_name', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'country');
			                    }
		                    }
						]
                    },
                    {
                        id: 'md_name',
                        type: 'common',
                        Header: t('factoryEvaluation.mdName'),
                        accessor: 'md_name',
                        isVisible: this.isVisible('md_name'),
	                    filters: [
		                    {
			                    sortColumn: 'md_name',
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData['md_name'],
			                    loadDataFn: () => apiGetFilters('md_name', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'md_name');
			                    }
		                    }
						]
                    },
                    {
                        id: 'factory_code',
                        type: 'common',
                        Header: t('factoryEvaluation.factoryCode'),
                        accessor: 'factory_code',
                        isVisible: this.isVisible('factory_code'),
	                    filters: [
		                    {
			                    sortColumn: 'factory_code',
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData['factory_code'],
			                    loadDataFn: () => apiGetFilters('factory_code', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'factory_code');
			                    }
		                    }
						]
                    },
                    {
                        id: 'jan_code',
                        type: 'common',
                        Header: t('factoryEvaluation.janCode'),
                        accessor: 'jan_code',
                        isVisible: this.isVisible('jan_code'),
	                    filters: [
		                    {
			                    sortColumn: 'jan_code',
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData['jan_code'],
			                    loadDataFn: () => apiGetFilters('jan_code', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'jan_code');
			                    }
		                    }
						]
                    },
                    {
                        id: 'division_number',
                        type: 'common',
                        Header: t('factoryEvaluation.divisionNumber'),
                        accessor: 'division_number',
                        isVisible: this.isVisible('division_number'),
	                    filters: [
		                    {
			                    sortColumn: 'division_number',
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData['division_number'],
			                    loadDataFn: () => apiGetFilters('division_number', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'division_number');
			                    }
		                    }
						]
                    },
                    {
                        id: 'organization_name',
                        type: 'common',
                        Header: t('factoryEvaluation.organizationName'),
                        accessor: 'organization_name',
                        isVisible: this.isVisible('organization_name'),
	                    filters: [
		                    {
			                    sortColumn: 'organization_name',
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData['organization_name'],
			                    loadDataFn: () => apiGetFilters('organization_name', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'organization_name');
			                    }
		                    }
						]
                    },
                    {
                        id: 'audit_number',
                        type: 'csr',
                        fixed: preferences && freezePaneIndex > preferences.map(e => e.id).indexOf('audit_number'),
                        Header: t('factoryEvaluation.auditNumber'),
                        accessor: 'audit_number',
                        width: 130,
                        isVisible: this.isVisible('audit_number'),
	                    filters: [
		                    {
			                    sortColumn: 'audit_number',
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData.audit_number,
			                    loadDataFn: () => apiGetFilters('audit_number', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'audit_number');
			                    }
		                    }
	                    ]
                    },
	                {
		                id: 'is_new',
		                type: 'csr',
		                Header: t('factoryEvaluation.isNew'),
		                isVisible: this.isVisible('is_new'),
		                width: 130,
		                Cell: props => (props.original.is_new ? t('factoryEvaluation.new') : t('factoryEvaluation.continuing')),
		                filters: [
			                {
				                sortColumn: 'is_new',
				                filterOptions: {
					                id: 'id',
					                label: `name_${language}`,
				                },
				                filteredValue: filterData.is_new,
				                loadDataFn: () => apiGetFilters('is_new', h.getQuery()),
				                onFilterChange: (data) => {
					                this.props.onChangeWithFilter(data, 'is_new');
				                }
			                }
						]
	                },
                    {
                        id: 'total_status',
                        type: 'csr',
                        Header: t('factoryEvaluation.totalStatus'),
                        accessor: 'total_status_id',
                        skip: !permission('total-status:view'),
                        width: config.width[language].total_status,
                        className: 'has-select',
                        Cell: props => (
                          <Select
                              value={props.value}
                              isFixed
                              width="auto"
                              onChange={(value) => onChangeHandler(value, 'total_status_id', props.original.id)}
                              options={this.totalStatusFilter(totalStatuses, props.original.audit_year, props.original)}
                              label={`name_${language}`}
                              isDisabled={!permission('evaluation-list:update') || !permission('total-status:update')}
                          />
                        ),
                        isVisible: this.isVisible('total_status'),
	                    filters: [
		                    {
			                    sortColumn: 'total_status_id',
			                    filterOptions: {
				                    id: 'id',
				                    label: `name_${language}`,
			                    },
			                    filteredValue: filterData.total_status_id,
			                    loadDataFn: () => apiGetFilters('total_status', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'total_status_id');
			                    }
		                    }
						]
                    },
                    {
                        id: 'certification_issuance',
                        type: 'csr',
                        Header: t('factoryEvaluation.certificationIssuance'),
                        accessor: "certification_issuance_id",
                        skip: !permission('certification-issuance:view'),
                        width: config.width[language].certification_issuance,
                        className: "has-select",
                        Cell: props => (
                          <Select
                              value={props.value}
                              isFixed
                              width="auto"
                              onChange={(value) => onChangeHandler(value, 'certification_issuance_id', props.original.id)}
                              options={certificationIssuances}
                              label={`name_${language}`}
                              isDisabled={!permission('evaluation-list:update') || !permission('certification-issuance:update')}
                          />
                        ),
                        isVisible: this.isVisible('certification_issuance'),
	                    filters: [
		                    {
			                    sortColumn: "certification_issuance_id",
			                    filterOptions: {
				                    id: "id",
				                    label: `name_${language}`,
			                    },
			                    filteredValue: filterData.certification_issuance_id,
			                    loadDataFn: () => apiGetFilters('certification_issuance', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, "certification_issuance_id");
			                    }
		                    }
						]
                    },
                    {
                        id: 'certificate_mail_date',
                        type: 'csr',
                        fixed: preferences && freezePaneIndex > preferences.map(e => e.id).indexOf('certificate_mail_date'),
                        Header: t('factoryEvaluation.certificateMailDate'),
                        accessor: 'certificate_mail_date',
                        width: config.width[language].certificate_mail_date,
                        skip: !permission('certificate-mail-date:view'),
                        isVisible: this.isVisible('certificate_mail_date'),
	                    filters: [
		                    {
			                    sortColumn: 'certificate_mail_date',
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData['certificate_mail_date'],
			                    loadDataFn: () => apiGetFilters('certificate_mail_date', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'certificate_mail_date');
			                    }
		                    }
						]
                    },
                    {
                        id: 'certificate_mail_receipt',
                        type: 'csr',
                        fixed: preferences && freezePaneIndex > preferences.map(e => e.id).indexOf('certificate_mail_receipt'),
                        Header: t('factoryEvaluation.certificateMailReceipt'),
                        width: config.width[language].certificate_mail_receipt,
                        skip: !permission('certificate-mail-receipt:view'),
                        Cell: props => (
                          <Checkbox
                              checked={props.original.certificate_mail_receipt === 1}
                              onChange={(value) => onChangeHandler(value, 'certificate_mail_receipt', props.original.id)}
                              disabled={!permission('certificate-mail-receipt:update')}
                              name="type"
                          />
                        ),
                        isVisible: this.isVisible('certificate_mail_receipt'),
	                    filters: [
		                    {
			                    sortColumn: 'certificate_mail_receipt',
			                    filterOptions: {
				                    id: 'id',
				                    label: `name_${language}`,
			                    },
			                    filteredValue: filterData.certificate_mail_receipt,
			                    loadDataFn: () => apiGetFilters('certificate_mail_receipt', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'certificate_mail_receipt');
			                    }
		                    }
						]
                    },
                    {
                        id: 'effective_date',
                        type: 'csr',
                        fixed: preferences && freezePaneIndex > preferences.map(e => e.id).indexOf('effective_date'),
                        Header: t('factoryEvaluation.effectiveDate'),
                        accessor: "effective_date",
                        width: config.width[language].effective_date,
                        Cell: ({ original }) => (
                            original.effective_date ? original.effective_date : ''
                        ),
                        isVisible: this.isVisible('effective_date'),
	                    filters: [
		                    {
			                    sortColumn: 'effective_date',
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData['effective_date'],
			                    loadDataFn: () => apiGetFilters('effective_date', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'effective_date');
			                    }
		                    }
	                    ]
                    },
                    {
                        id: 'expiration_date',
                        type: 'csr',
                        fixed: preferences && freezePaneIndex > preferences.map(e => e.id).indexOf('expiration_date'),
                        Header: t('factoryEvaluation.expirationDate'),
                        accessor: 'expiration_date',
                        width: config.width[language].expiration_date,
                        Cell: ({ original }) => (
                            original.expiration_date ? original.expiration_date : ''
                        ),
                        isVisible: this.isVisible('expiration_date'),
	                    filters: [
		                    {
			                    sortColumn: 'expiration_date',
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData['expiration_date'],
			                    loadDataFn: () => apiGetFilters('expiration_date', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'expiration_date');
			                    }
		                    }
	                    ]
                    },
                    {
                        id: 'fy2020',
                        type: 'csr',
                        Header: t('vendorFactory.fy2020Audit'),
                        accessor: 'vendor_factory.fy2020',
                        width: config.width[language].fy2020,
                        Cell: ({ original }) => (
                            this.resolveFy2020(original)
                        ),
                        isVisible: this.isVisible('fy2020'),
	                    filters: [
		                    {
			                    sortColumn: 'fy2020',
			                    filterOptions: {
				                    id: 'id',
				                    label: `name_${language}`,
			                    },
			                    filteredValue: filterData.fy2020,
			                    loadDataFn: () => apiGetFilters('vendor_factory_fy2020', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'fy2020');
			                    }
		                    }
	                    ]
                    },
                    {
                        id: 'audit_category',
                        type: 'csr',
                        Header: t('factoryEvaluation.auditCategory'),
                        accessor: 'audit_category_id',
                        skip: !permission('audit-category:view'),
                        width: config.width[language].audit_category,
                        className: 'has-select',
                        Cell: props => (
                          <Select
                            value={props.value}
                            isFixed
                            width="auto"
                            onChange={(value) => onChangeHandler(value, 'audit_category_id', props.original.id)}
                            options={auditCategories}
                            label={`name_${language}`}
                            isDisabled={!permission('evaluation-list:update') || !permission('audit-category:update')}
                          />
                        ),
                        isVisible: this.isVisible('audit_category'),
	                    filters: [
		                    {
			                    sortColumn: "audit_category_id",
			                    filterOptions: {
				                    id: "id",
				                    label: `name_${language}`,
			                    },
			                    filteredValue: filterData.audit_category_id,
			                    loadDataFn: () => apiGetFilters('audit_category', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, "audit_category_id");
			                    }
		                    }
	                    ]
                    },
                    {
                        id: 'audit_type',
                        type: 'csr',
                        Header: t('factoryEvaluation.auditType'),
                        accessor: 'audit_type_id',
                        skip: !permission('audit-type:view'),
                        width: config.width[language].audit_type,
                        className: 'has-select',
                        Cell: props => (
                          <Select
                            value={props.value}
                            isFixed
                            width="auto"
                            onChange={(value) => onChangeHandler(value, 'audit_type_id', props.original.id)}
                            options={auditTypes}
                            label={`name_${language}`}
                            isDisabled={!permission('evaluation-list:update') || !permission('audit-type:update')}
                          />
                        ),
                        isVisible: this.isVisible('audit_type'),
	                    filters: [
		                    {
			                    sortColumn: "audit_type_id",
			                    filterOptions: {
				                    id: "id",
				                    label: `name_${language}`,
			                    },
			                    filteredValue: filterData.audit_type_id,
			                    loadDataFn: () => apiGetFilters('audit_type', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, "audit_type_id");
			                    }
		                    }
	                    ]
                    },
                    {
                        id: 'preferred_audit_month',
                        type: 'csr',
                        Header: t('factoryEvaluation.preferredAuditMonth'),
                        accessor: 'preferred_audit_date',
                        skip: !permission('preferred-audit-date:view'),
                        width: config.width[language].preferred_audit_date,
                        Cell: ({ original }) => (
                          original.preferred_audit_date ? moment(original.preferred_audit_date).format('YYYY-MM') : ''
                        ),
                        isVisible: this.isVisible('preferred_audit_month'),
	                    filters: [
		                    {
			                    sortColumn: 'preferred_audit_date',
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData['preferred_audit_date'],
			                    loadDataFn: () => apiGetFilters('preferred_audit_date', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'preferred_audit_date');
			                    }
		                    }
	                    ]
                    },
                    {
                        id: 'audit_date',
                        type: 'csr',
                        Header: t('factoryEvaluation.auditDate'),
                        accessor: 'audit_date',
                        width: config.width[language].audit_date,
                        Cell: ({ original }) => (
                          original.audit_date_required ? original.audit_date : '実施不要'
                        ),
                        isVisible: this.isVisible('audit_date'),
	                    filters: [
		                    {
			                    sortColumn: 'audit_date',
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData['audit_date'],
			                    loadDataFn: () => apiGetFilters('audit_date', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'audit_date');
			                    }
		                    }
	                    ]
                    },
	                {
	                	id: 'audit_fee',
		                type: 'csr',
		                Header: t('factoryEvaluation.auditFee'),
		                accessor: 'audit_fee',
		                isVisible: this.isVisible('audit_fee'),
		                Cell: props => <EvaluationFee data={props.original.audit_fee}/>,
		                filters: [
			                {
				                sortColumn: 'audit_fee',
				                filterOptions: {
					                id: 'name',
					                label: 'name',
				                },
				                filteredValue: filterData['audit_fee'],
				                loadDataFn: () => apiGetFilters('audit_fee', h.getQuery()),
				                onFilterChange: (data) => {
					                this.props.onChangeWithFilter(data, 'audit_fee');
				                }
			                },
			                {
				                sortColumn: 'audit_date_fee',
				                filterOptions: {
					                id: 'name',
					                label: 'name',
				                },
				                filteredValue: filterData['audit_date_fee'],
				                loadDataFn: () => apiGetFilters('audit_date_fee', h.getQuery()),
				                onFilterChange: (data) => {
					                this.props.onChangeWithFilter(data, 'audit_date_fee');
				                }
			                }
		                ]
	                },
	                {
		                id: 'care_fee',
		                type: 'csr',
		                Header: t('factoryEvaluation.careFee'),
		                accessor: 'care_fee',
		                isVisible: this.isVisible('care_fee'),
		                onDoubleClick: (original) => this.props.openFeeDetailsModal(original.care_fee, 'care', original.id),
		                isExpandable: true,
		                Cell: props => <EvaluationFee data={props.original.care_fee}/>,
		                filters: [
			                {
				                sortColumn: 'care_fee',
				                loadDataFn: () => apiGetFilters('care_fee', h.getQuery()),
				                filterOptions: {
					                id: 'name',
					                label: 'name',
				                },
				                filteredValue: filterData['care_fee'],
				                onFilterChange: (data) => {
					                this.props.onChangeWithFilter(data, 'care_fee');
				                }
			                },
			                {
				                sortColumn: 'care_date_fee',
				                loadDataFn: () => apiGetFilters('care_date_fee', h.getQuery()),
				                filterOptions: {
					                id: 'name',
					                label: 'name',
				                },
				                filteredValue: filterData['care_date_fee'],
				                onFilterChange: (data) => {
					                this.props.onChangeWithFilter(data, 'care_date_fee');
				                }
			                }
		                ]
	                },
	                {
		                id: 'misc_fee',
		                type: 'csr',
		                Header: t('factoryEvaluation.miscFee'),
		                accessor: 'misc_fee',
		                className: 'comment-column',
		                isVisible: this.isVisible('misc_fee'),
		                onDoubleClick: (original) => this.props.openFeeDetailsModal(original.misc_fee, 'misc', original.id),
		                isExpandable: true,
		                Cell: props => <EvaluationFee data={props.original.misc_fee}/>,
		                filters: [
			                {
				                sortColumn: 'misc_fee',
				                filterOptions: {
					                id: 'name',
					                label: 'name',
				                },
				                filteredValue: filterData['misc_fee'],
				                loadDataFn: () => apiGetFilters('misc_fee', h.getQuery()),
				                onFilterChange: (data) => {
					                this.props.onChangeWithFilter(data, 'misc_fee');
				                }
			                },
			                {
				                sortColumn: 'misc_date_fee',
				                filterOptions: {
					                id: 'name',
					                label: 'name',
				                },
				                filteredValue: filterData['misc_date_fee'],
				                loadDataFn: () => apiGetFilters('misc_date_fee', h.getQuery()),
				                onFilterChange: (data) => {
					                this.props.onChangeWithFilter(data, 'misc_date_fee');
				                }
			                }
						]
	                },
	                {
		                id: 'cancel_fee',
		                type: 'csr',
		                Header: t('factoryEvaluation.cancelFee'),
		                accessor: 'cancel_fee',
		                className: 'comment-column',
		                isVisible: this.isVisible('cancel_fee'),
		                onDoubleClick: (original) => this.props.openCancelFeeModal(original.cancel_fee),
		                isExpandable: true,
		                Cell: props => <EvaluationFee data={props.original.cancel_fee}/>,
		                filters: [
			                {
				                sortColumn: 'cancel_fee',
				                filterOptions: {
					                id: 'name',
					                label: 'name',
				                },
				                filteredValue: filterData['cancel_fee'],
				                loadDataFn: () => apiGetFilters('cancel_fee', h.getQuery()),
				                onFilterChange: (data) => {
					                this.props.onChangeWithFilter(data, 'cancel_fee');
				                }
			                },
			                {
				                sortColumn: 'cancel_date_fee',
				                filterOptions: {
					                id: 'name',
					                label: 'name',
				                },
				                filteredValue: filterData['cancel_date_fee'],
				                loadDataFn: () => apiGetFilters('cancel_date_fee', h.getQuery()),
				                onFilterChange: (data) => {
					                this.props.onChangeWithFilter(data, 'cancel_date_fee');
				                }
			                }
						]
	                },
	                {
		                id: 'certificate_fee',
		                type: 'csr',
		                Header: t('factoryEvaluation.certificateFee'),
		                accessor: 'certificate_fee',
		                isVisible: this.isVisible('certificate_fee'),
		                Cell: props => <EvaluationFee data={props.original.certificate_fee}/>,
		                filters: [
			                {
				                sortColumn: 'certificate_fee',
				                filterOptions: {
					                id: 'name',
					                label: 'name',
				                },
				                filteredValue: filterData['certificate_fee'],
				                loadDataFn: () => apiGetFilters('certificate_fee', h.getQuery()),
				                onFilterChange: (data) => {
					                this.props.onChangeWithFilter(data, 'certificate_fee');
				                }
			                },
			                {
				                sortColumn: 'certificate_date_fee',
				                filterOptions: {
					                id: 'name',
					                label: 'name',
				                },
				                filteredValue: filterData['certificate_date_fee'],
				                loadDataFn: () => apiGetFilters('certificate_date_fee', h.getQuery()),
				                onFilterChange: (data) => {
					                this.props.onChangeWithFilter(data, 'certificate_date_fee');
				                }
			                }
						]
	                },
	                {
		                id: 'reaudit_fee',
		                type: 'csr',
		                Header: t('factoryEvaluation.reauditFee'),
		                accessor: 'reaudit_fee',
		                isVisible: this.isVisible('reaudit_fee'),
		                Cell: props => <EvaluationFee data={props.original.reaudit_fee}/>,
		                filters: [
			                {
				                sortColumn: 'reaudit_fee',
				                filterOptions: {
					                id: 'name',
					                label: 'name',
				                },
				                filteredValue: filterData['reaudit_fee'],
				                loadDataFn: () => apiGetFilters('reaudit_fee', h.getQuery()),
				                onFilterChange: (data) => {
					                this.props.onChangeWithFilter(data, 'reaudit_fee');
				                }
			                },
			                {
				                sortColumn: 'reaudit_date_fee',
				                filterOptions: {
					                id: 'name',
					                label: 'name',
				                },
				                filteredValue: filterData['reaudit_date_fee'],
				                loadDataFn: () => apiGetFilters('reaudit_date_fee', h.getQuery()),
				                onFilterChange: (data) => {
					                this.props.onChangeWithFilter(data, 'reaudit_date_fee');
				                }
			                }
						]
	                },
	                {
		                id: 'comment_fee',
		                type: 'csr',
		                Header: t('factoryEvaluation.commentFee'),
		                accessor: 'comment_fee',
		                className: 'comment-column',
		                isVisible: this.isVisible('comment_fee'),
		                width: 280,
		                isExpandable: true,
		                onDoubleClick: (original) => {
			                this.props.openEditor(
				                original.id,
				                original,
				                'comment_fee',
				                t('evaluation.editComment')
			                );
		                },
		                Cell: ({ original }) => (
			                <React.Fragment>
				                {ReactHtmlParser(original.comment_fee)}
			                </React.Fragment>
		                ),
	                },
                    {
                        id: 'auditor_in_charge',
                        type: 'csr',
                        Header: t('factoryEvaluation.auditorInCharge'),
                        accessor: 'auditor_in_charge',
                        isVisible: this.isVisible('auditor_in_charge'),
	                    filters: [
		                    {
			                    sortColumn: 'auditor_in_charge',
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData['auditor_in_charge'],
			                    loadDataFn: () => apiGetFilters('auditor_in_charge', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'auditor_in_charge');
			                    }
		                    }
						]
                    },
                    {
                        id: 'exception_flag',
                        type: 'csr',
                        Header: t('factoryEvaluation.exceptionFlag'),
                        accessor: "exception_flag_id",
                        skip: !permission('exception-flag:view'),
                        width: config.width[language].exception_flag,
                        className: "has-select",
	                    isVisible: this.isVisible('exception_flag'),
                        Cell: props => (
                          <React.Fragment>
                              <Select
                                value={props.value}
                                isFixed
                                width="auto"
                                onChange={(value) => onChangeHandler(value, 'exception_flag_id', props.original.id)}
                                options={exceptionFlags}
                                label={`name_${language}`}
                                isDisabled={!permission('evaluation-list:update') || !permission('exception-flag:update')}
                              />
                          </React.Fragment>
                        ),
	                    filters: [
		                    {
			                    sortColumn: "exception_flag_id",
			                    filterOptions: {
				                    id: "id",
				                    label: `name_${language}`
			                    },
			                    filteredValue: filterData.exception_flag_id,
			                    loadDataFn: () => apiGetFilters('exception_flag', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, "exception_flag_id");
			                    }
		                    }
						]
                    },
                    {
                      id: 'evaluation_status',
                      type: 'csr',
                      Header: t('factoryEvaluation.evaluationStatus'),
                      accessor: "evaluation_status_id",
                      skip: !permission('evaluation-status:view'),
                      width: config.width[language].evaluation_status,
                      className: "has-select",
                      Cell: props => (
                        <React.Fragment>
                          <Select
                            value={props.value}
                            isFixed
                            width="auto"
                            onChange={(value) => onChangeHandler(value, 'evaluation_status_id', props.original.id)}
                            options={statuses}
                            label={`name_${language}`}
                            isDisabled={!permission('evaluation-list:update') || !permission('evaluation-status:update')}
                          />
                        </React.Fragment>
                      ),
                      isVisible: this.isVisible('evaluation_status'),
                      filters: [
						{
							sortColumn: "evaluation_status_id",
							filterOptions: {
								id: "id",
								label: `name_${language}`
							},
							filteredValue: filterData.evaluation_status_id,
							loadDataFn: () => apiGetFilters('evaluation_status', h.getQuery()),
							onFilterChange: (data) => {
								this.props.onChangeWithFilter(data, "evaluation_status_id");
							}
						}
                      ]
                    },
                    {
                        id: 'deadline',
                        type: 'csr',
                        Header: t('factoryEvaluation.deadline'),
                        accessor: "deadline_id",
                        skip: !permission('deadline:view'),
                        width: config.width[language].deadline,
                        className: "has-select",
                        Cell: props => (
                          <React.Fragment>
                              <Select
                                value={props.value}
                                isFixed
                                width="auto"
                                onChange={(value) => onChangeHandler(value, 'deadline_id', props.original.id)}
                                options={deadlines}
                                label={`name`}
                                isDisabled={!permission('evaluation-list:update') || !permission('deadline:update')}
                              />
                          </React.Fragment>
                        ),
                        isVisible: this.isVisible('deadline'),
	                    filters: [
		                    {
			                    sortColumn: "deadline_id",
			                    filterOptions: {
				                    id: "id",
				                    label: `name`,
			                    },
			                    filteredValue: filterData.deadline_id,
			                    loadDataFn: () => apiGetFilters('deadline', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, "deadline_id");
			                    }
		                    }
						]
                    },
                    {
                        id: 'status_changed_date',
                        type: 'csr',
                        Header: t('factoryEvaluation.lastUpdate'),
                        accessor: "status_changed_date",
                        width: config.width[language].status_changed_date,
                        isVisible: this.isVisible('status_changed_date'),
	                    filters: [
		                    {
			                    sortColumn: 'status_changed_date',
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData.status_changed_date,
			                    loadDataFn: () => apiGetFilters('status_changed_date', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'status_changed_date');
			                    }
		                    }
						]
                    },
                    {
                        id: 'previous_evaluation_score',
                        type: 'csr',
                        className: 'text-right',
                        Header: t('factoryEvaluation.previousEvaluation'),
                        accessor: 'previous_evaluation_score',
                        width: config.width[language].previous_evaluation_score,
                        isVisible: this.isVisible('previous_evaluation_score'),
	                    filters: [
		                    {
			                    sortColumn: 'previous_evaluation_score',
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData.previous_evaluation_score,
			                    loadDataFn: () => apiGetFilters('previous_evaluation_score', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'previous_evaluation_score');
			                    },
		                    }
						]
                    },
                    {
                        id: 'latest_evaluation_score',
                        type: 'csr',
                        Header: t('factoryEvaluation.latestEvaluation'),
                        accessor: 'latest_evaluation_score_id',
                        skip: !permission('latest-evaluation-score:view'),
                        width: config.width[language].latest_evaluation_score,
                        className: 'has-select',
                        Cell: props => (
                        <React.Fragment>
                          <Select
                            value={props.value}
                            isFixed
                            width="auto"
                            onChange={(value) => onChangeHandler(value, 'latest_evaluation_score_id', props.original.id)}
                            options={latestEvaluationScores}
                            label={`name_${language}`}
                            isDisabled={!permission('latest-evaluation-score:update') || !permission('latest-evaluation-score:update')}
                          />
                        </React.Fragment>
                        ),
                        isVisible: this.isVisible('latest_evaluation_score'),
	                    filters: [
		                    {
			                    sortColumn: 'latest_evaluation_score_id',
			                    filterOptions: {
				                    id: 'id',
				                    label: `name_${language}`,
			                    },
			                    emptyOnEnd: false,
			                    filteredValue: filterData.latest_evaluation_score_id,
			                    loadDataFn: () => apiGetFilters('latest_evaluation_score', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'latest_evaluation_score_id');
			                    },
		                    }
						]
                    },
                    {
                        id: 'evaluation_result_previous',
                        type: 'csr',
                        Header: t('factoryEvaluation.evaluationResultPrevious'),
                        accessor: 'evaluation_result_previous',
                        width: config.width[language].evaluation_result_previous,
                        isVisible: this.isVisible('evaluation_result_previous'),
	                    filters: [
		                    {
			                    sortColumn: 'evaluation_result_previous',
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData.evaluation_result_previous,
			                    loadDataFn: () => apiGetFilters('evaluation_result_previous', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'evaluation_result_previous');
			                    }
		                    }
						]
                    },
                    {
                        id: 'scap',
                        type: 'csr',
                        Header: t('factoryEvaluation.cpaScap'),
                        accessor: 'audit_files',
                        width: config.width[language].audit_scap_file,
                        isVisible: this.isVisible('scap'),
                        isExpandable: true,
                        className: 'audit-files',
	                    filters: [
		                    {
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData.audit_scap_file,
			                    loadDataFn: () => apiGetFilters('audit_scap_file'),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'audit_scap_file');
			                    },
		                    }
						],
                        Cell: props => (
                          props.value ? AuditFile.filter(props.value, 'is_scap').map(file => (
                            <div key={file.id} className="evidence-link">
                                <File name={file.name} link={file.path} t={t} />
                            </div>
                          )) : null
                        )
                    },
                    {
                        id: 'comment_tuv_hd',
                        type: 'csr',
                        Header: t('factoryEvaluation.commentTuvHd'),
                        skip: !permission('comment-tuv-hd:view'),
                        isVisible: this.isVisible('comment_tuv_hd'),
                        isExpandable: true,
                        accessor: 'comment_tuv_hd',
                        className: 'comment-column',
	                    filters: [
		                    {
			                    onlyOne: true,
			                    filteredValue: filterData.comment_tuv_hd,
			                    filterOptions: {
				                    id: 'name',
				                    label: `name_${language}`
			                    },
			                    loadDataFn: () => apiGetFilters('comment_tuv_hd'),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'comment_tuv_hd');
			                    }
		                    }
						],
                        width: 280,
                        onDoubleClick: (original) => {
                            if (permission('comment-tuv-hd:update')) {
                                this.props.openEditor(
                                    original.id,
                                    original,
                                    'comment_tuv_hd',
                                    t('evaluation.editComment')
                                );
                            }
                        },
                        Cell: ({ original }) => (
                          <React.Fragment>
                              {ReactHtmlParser(original.comment_tuv_hd)}
                          </React.Fragment>
                        ),
                    },
                    {
                        id: 'evaluation_result',
                        type: 'csr',
                        Header: t('factoryEvaluation.evaluationResult'),
                        accessor: "evaluation_result_id",
                        skip: !permission('evaluation-result:view'),
                        width: config.width[language].evaluation_result,
                        className: "has-select",
                        Cell: props => (
                            <React.Fragment>
                                <Select
                                    value={props.value}
                                    isFixed
                                    width="auto"
                                    onChange={(value) => this.props.handleEvalResultChange(value, 'evaluation_result_id', props.original.id)}
                                    options={props.original.is_judgement_confirmed ? evaluationResults : this.evaluationResultFilter(evaluationResults, props.original.audit_year, props.original)}
                                    label={`name_${language}`}
                                    isDisabled={!permission('evaluation-list:update') || !permission('evaluation-result:update') || props.original.is_judgement_confirmed}
                                />
                            </React.Fragment>
                        ),
                        isVisible: this.isVisible('evaluation_result'),
	                    filters: [
		                    {
			                    sortColumn: "evaluation_result_id",
			                    filterOptions: {
				                    id: "id",
				                    label: `name_${language}`,
			                    },
			                    filteredValue: filterData.evaluation_result_id,
			                    loadDataFn: () => apiGetFilters('evaluation_result', h.getQuery('evaluation_result_id')),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, "evaluation_result_id");
			                    },
		                    }
						]
                    },
                    {
                        id: 'judgement_decision',
                        type: 'csr',
                        Header: t('factoryEvaluation.judgementDecision'),
                        skip: !permission('judgement-confirm:view'),
                        width: config.width[language].judgement_confirm,
                        isVisible: this.isVisible('judgement_decision'),
                        className: 'has-buttons',
                        Cell: props => (
                          <React.Fragment>
                                <button
                                    className={props.original.is_judgement_confirmed ? 'custom-button update-button' : 'custom-button confirm-button'}
                                    onClick={props.original.is_judgement_confirmed ? () => this.props.updateJudgement(props.original.id) : () => this.props.confirmJudgement(props.original.id)}
                                    disabled={!props.original.evaluation_result_id}>
                                    {props.original.is_judgement_confirmed ? t('factoryEvaluation.judgementConfirmed') : t('factoryEvaluation.judgementConfirm')}
                                </button>
                                <button
                                    className="custom-button cancel-button"
                                    onClick={() => this.props.cancelEdit(props.original.id, 'evaluation_result_id')}
                                    disabled={!props.original.evaluation_result_id || props.original.is_judgement_confirmed}>
                                    {t('global.cancel')}
                                </button>
                          </React.Fragment>
                        ),
	                    filters: [
		                    {
			                    filterOptions: {
				                    id: `name`,
				                    label: `name_${language}`,
			                    },
			                    filteredValue: filterData.judgement_decision,
			                    loadDataFn: () => apiGetFilters('judgement_decision', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, "judgement_decision");
			                    }
		                    }
						]
                    },
                    {
                        id: 'decision_history',
                        type: 'csr',
                        Header: t('factoryEvaluation.decisionHistory'),
                        accessor: 'decision_history',
                        width: '300px',
                        Cell: ({ original }) => (
                            <React.Fragment>
                                {ReactHtmlParser(original.decision_history)}
                            </React.Fragment>
                        ),
                        isVisible: this.isVisible('decision_history'),
                        isExpandable: true,
                    },
                    {
                        id: 'judgement_due_date',
                        type: 'csr',
                        Header: t('factoryEvaluation.judgementDueDate'),
                        accessor: 'judgement_due_date',
                        width: config.width[language].judgement_due_date,
                        Cell: ({ original }) => (
                          original.judgement_due_date ? original.judgement_due_date : ''
                        ),
                        isVisible: this.isVisible('judgement_due_date'),
	                    filters: [
		                    {
			                    sortColumn: 'judgement_due_date',
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData['judgement_due_date'],
			                    loadDataFn: () => apiGetFilters('judgement_due_date', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'judgement_due_date');
			                    }
		                    }
						]
                    },
                    {
                        id: 're_audit_planned_date',
                        type: 'csr',
                        Header: t('factoryEvaluation.reAuditPlannedDate'),
                        accessor: 're_audit_planned_date',
                        width: config.width[language].re_audit_planned_date,
                        Cell: ({ original }) => (
                          original.re_audit_planned_date ? original.re_audit_planned_date : ''
                        ),
                        isVisible: this.isVisible('re_audit_planned_date'),
	                    filters: [
		                    {
			                    sortColumn: 're_audit_planned_date',
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData['re_audit_planned_date'],
			                    loadDataFn: () => apiGetFilters('re_audit_planned_date', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 're_audit_planned_date');
			                    }
		                    }
						]
                    },
                    {
                        id: 'post_audit_status',
                        type: 'csr',
                        Header: t('factoryEvaluation.postAuditStatus'),
                        accessor: "post_audit_status_id",
                        skip: !permission('post-audit-status:view'),
                        width: config.width[language].post_audit_status,
                        className: "has-select",
                        Cell: props => (
                            <Select
                                value={props.value}
                                isFixed
                                width="auto"
                                onChange={(value) => onChangeHandler(value, 'post_audit_status_id', props.original.id)}
                                options={postAuditStatuses}
                                label={`name_${language}`}
                                isDisabled={!permission('evaluation-list:update') || !permission('post-audit-status:update')}
                            />
                        ),
                        isVisible: this.isVisible('post_audit_status'),
	                    filters: [
		                    {
			                    sortColumn: "post_audit_status_id",
			                    filterOptions: {
				                    id: "id",
				                    label: `name_${language}`
			                    },
			                    filteredValue: filterData.post_audit_status_id,
			                    loadDataFn: () => apiGetFilters('post_audit_status', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, "post_audit_status_id");
			                    }
		                    }
						]
                    },
                    {
                        id: 'nc',
                        type: 'csr',
                        Header: t('factoryEvaluation.nbrofNC'),
                        accessor: 'nc',
                        width: config.width[language].nc,
	                    classNameFunc: (item) => {
		                    if (item.evidence_file_warning) {
			                    return 1 === item.evidence_file_warning ? 'evidence-warning-1' : 'evidence-warning-2';
		                    }
		                    return '';
	                    },
	                    isVisible: this.isVisible('nc'),
	                    filters: [
		                    {
			                    sortColumn: 'nc',
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData['nc'],
			                    loadDataFn: () => apiGetFilters('nc', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'nc');
			                    }
		                    },
		                    {
			                    sortColumn: 'evidence_file_warning',
			                    filterOptions: {
				                    id: 'id',
				                    label: `name_${language}`,
			                    },
			                    filteredValue: filterData['evidence_file_warning'],
			                    loadDataFn: () => apiGetFilters('evidence_file_warning', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'evidence_file_warning');
			                    }
		                    }
						]
                    },
                    {
                        id: 'no_of_evidence',
                        type: 'csr',
                        Header: t('factoryEvaluation.noEvidences'),
                        accessor: 'no_of_evidences',
                        width: config.width[language].no_of_evidences,
	                    classNameFunc: (item) => {
		                    if (item.evidence_file_warning) {
			                    return 1 === item.evidence_file_warning ? 'evidence-warning-1' : 'evidence-warning-2';
		                    }
		                    return '';
	                    },
	                    isVisible: this.isVisible('no_of_evidence'),
	                    filters: [
		                    {
			                    sortColumn: 'no_of_evidences',
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData['no_of_evidences'],
			                    loadDataFn: () => apiGetFilters('no_of_evidences', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'no_of_evidences');
			                    }
		                    },
		                    {
			                    sortColumn: 'evidence_file_warning',
			                    filterOptions: {
				                    id: 'id',
				                    label: `name_${language}`,
			                    },
			                    filteredValue: filterData['evidence_file_warning'],
			                    loadDataFn: () => apiGetFilters('evidence_file_warning', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'evidence_file_warning');
			                    }
		                    }
						]
                    },
                    {
                        id: 'scap_deadline_date',
                        type: 'csr',
                        Header: t('factoryEvaluation.scapDeadline'),
                        accessor: 'scap_deadline_date',
	                    classNameFunc: (item) => {
		                    if (item.scap_file_warning) {
			                    return 1 === item.scap_file_warning ? 'scap-warning-1' : 'scap-warning-2';
		                    }
		                    return '';
	                    },
                        width: config.width[language].scap_deadline_date,
                        isVisible: this.isVisible('scap_deadline_date'),
	                    filters: [
		                    {
			                    sortColumn: 'scap_deadline_date',
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData['scap_deadline_date'],
			                    loadDataFn: () => apiGetFilters('scap_deadline_date', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'scap_deadline_date');
			                    }
		                    },
		                    {
			                    sortColumn: 'scap_file_warning',
			                    filterOptions: {
				                    id: 'id',
				                    label: `name_${language}`,
			                    },
			                    filteredValue: filterData['scap_file_warning'],
			                    loadDataFn: () => apiGetFilters('scap_file_warning', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'scap_file_warning');
			                    }
		                    }
						]
                    },
                    {
                        id: 'scap_received_date',
                        type: 'csr',
                        Header: t('factoryEvaluation.scapReceivedDate'),
                        accessor: 'scap_received_date',
	                    classNameFunc: (item) => {
		                    if (item.scap_file_warning) {
			                    return 1 === item.scap_file_warning ? 'scap-warning-1' : 'scap-warning-2';
		                    }
		                    return '';
	                    },
                        width: config.width[language].scap_received_date,
                        isVisible: this.isVisible('scap_received_date'),
	                    filters: [
		                    {
			                    sortColumn: 'scap_received_date',
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData['scap_received_date'],
			                    loadDataFn: () => apiGetFilters('scap_received_date', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'scap_received_date');
			                    }
		                    },
		                    {
			                    sortColumn: 'scap_file_warning',
			                    filterOptions: {
				                    id: 'id',
				                    label: `name_${language}`,
			                    },
			                    filteredValue: filterData['scap_file_warning'],
			                    loadDataFn: () => apiGetFilters('scap_file_warning', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'scap_file_warning');
			                    }
		                    }
						]
                    },
                    {
                        id: 'certificate',
                        type: 'csr',
                        Header: t('factoryEvaluation.certificate'),
                        skip: !permission('certificate-file:view'),
                        accessor: 'audit_files',
                        width: config.width[language].audit_cert_file,
                        isVisible: this.isVisible('certificate'),
                        isExpandable: true,
                        className: 'audit-files',
	                    filters: [
		                    {
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData.audit_cert_file,
			                    loadDataFn: () => apiGetFilters('audit_cert_file'),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'audit_cert_file');
			                    }
		                    }
						],
                        onDoubleClick: (original) => {
                            if(permission('certificate-file:update')) {
                                this.props.openUploadFileModal(original.id, 'audit_cert_files', t('factoryEvaluation.uploadCertificate'));
                            }
                        },
                        Cell: props => (
                          props.value ? AuditFile.filter(props.value, 'is_certificate').map(file => (
                            <div key={file.id} className="evidence-link">
                                <File name={file.name} link={file.path} t={t} />
                            </div>
                          )) : null
                        )
                    },
                    {
                        id: 'related_materials',
                        type: 'csr',
                        Header: t('factoryEvaluation.relatedMaterials'),
                        skip: !isHldgs,
                        accessor: 'audit_files',
                        width: config.width[language].audit_related_file,
                        isVisible: this.isVisible('related_materials'),
                        isExpandable: true,
                        className: 'audit-files',
	                    filters: [
		                    {
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData.audit_related_file,
			                    loadDataFn: () => apiGetFilters('audit_related_file'),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'audit_related_file');
			                    }
		                    }
						],
                        onDoubleClick: (original) => {
                            this.props.openUploadFileModal(original.id, 'audit_related_files', t('factoryEvaluation.uploadMaterials'));
                        },
                        Cell: props => (
                          props.value ? AuditFile.filter(props.value, 'is_related_material').map(file => (
                            <div key={file.id} className="evidence-link">
                                <File name={file.name} link={file.path} t={t} />
                            </div>
                          )) : null
                        )
                    },
                    {
                        id: 'saq',
                        type: 'csr',
                        Header: t('factoryEvaluation.saq'),
                        accessor: 'audit_files',
                        width: config.width[language].audit_saq_file,
                        isVisible: this.isVisible('saq'),
                        isExpandable: true,
                        className: 'audit-files',
	                    filters: [
		                    {
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData.audit_saq_file,
			                    loadDataFn: () => apiGetFilters('audit_saq_file'),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'audit_saq_file');
			                    }
		                    }
						],
                        Cell: props => (
                          props.value ? AuditFile.filter(props.value, 'is_saq').map(file => (
                            <div key={file.id} className="evidence-link">
                              <File name={file.name} link={file.path} t={t} />
                            </div>
                          )) : null
                        )
                    },
                    {
                        id: 'no_of_evidences',
                        type: 'csr',
                        Header: t('factoryEvaluation.no_of_evidences'),
                        accessor: 'audit_files',
                        width: config.width[language].audit_evid_file,
                        isVisible: this.isVisible('no_of_evidences'),
                        isExpandable: true,
                        className: 'audit-files',
	                    filters: [
		                    {
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData.audit_evidence_file,
			                    loadDataFn: () => apiGetFilters('audit_evidence_file'),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'audit_evidence_file');
			                    }
		                    }
						],
                        Cell: props => (
                          props.value ? AuditFile.filter(props.value, 'is_evidence').map(file => (
                            <div key={file.id} className="evidence-link">
                              <File name={file.name} link={file.path} t={t} />
                            </div>
                          )) : null
                        )
                    },
                    {
                        id: 'no_of_major',
                        type: 'csr',
                        className: "text-right",
                        Header: t('factoryEvaluation.major'),
                        accessor: "no_of_major",
                        width: language === "jp" ? 60 : 45,
                        sortColumn: "no_of_major",
                        isVisible: this.isVisible('no_of_major'),
	                    filters: [
		                    {
			                    filterOptions: {
				                    id: "name",
				                    label: "name",
			                    },
			                    filteredValue: filterData.no_of_major,
			                    loadDataFn: () => apiGetFilters("no_of_major"),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, "no_of_major");
			                    }
		                    }
						]
                    },
                    {
                        id: 'no_of_minor',
                        type: 'csr',
                        className: "text-right",
                        Header: t('factoryEvaluation.minor'),
                        accessor: "no_of_minor",
                        width: language === "jp" ? 60 : 45,
                        sortColumn: "no_of_minor",
                        isVisible: this.isVisible('no_of_minor'),
	                    filters: [
		                    {
			                    filterOptions: {
				                    id: "name",
				                    label: "name",
			                    },
			                    filteredValue: filterData.no_of_minor,
			                    loadDataFn: () => apiGetFilters("no_of_minor"),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, "no_of_minor");
			                    }
		                    }
						]
                    },
                    {
                        id: 'no_of_quality',
                        type: 'csr',
                        className: "text-right",
                        Header: t('factoryEvaluation.quality'),
                        accessor: "no_of_quality",
                        width: language === "jp" ? 60 : 55,
                        isVisible: this.isVisible('no_of_quality'),
	                    filters: [
		                    {
			                    sortColumn: "no_of_quality",
			                    filterOptions: {
				                    id: "name",
				                    label: "name",
			                    },
			                    filteredValue: filterData.no_of_quality,
			                    loadDataFn: () => apiGetFilters("no_of_quality"),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, "no_of_quality");
			                    }
		                    }
						]
                    },
                    {
                        id: 'no_of_c1',
                        type: 'csr',
                        className: "text-right",
                        Header: t('factoryEvaluation.c1'),
                        accessor: "no_of_c1",
                        width: 55,
                        isVisible: this.isVisible('no_of_c1'),
	                    filters: [
		                    {
			                    sortColumn: "no_of_c1",
			                    filterOptions: {
				                    id: "name",
				                    label: "name",
			                    },
			                    filteredValue: filterData.no_of_c1,
			                    loadDataFn: () => apiGetFilters("no_of_c1"),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, "no_of_c1");
			                    }
		                    }
                        ]
                    },
                    {
                        id: 'no_of_c2',
                        type: 'csr',
                        className: "text-right",
                        Header: t('factoryEvaluation.c2'),
                        accessor: "no_of_c2",
                        isVisible: this.isVisible('no_of_c2'),
                        width: 55,
	                    filters: [
		                    {
			                    sortColumn: "no_of_c2",
			                    filterOptions: {
				                    id: "name",
				                    label: "name",
			                    },
			                    filteredValue: filterData.no_of_c2,
			                    loadDataFn: () => apiGetFilters("no_of_c2"),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, "no_of_c2");
			                    }
		                    }
						]
                    },
                    {
                        id: 'comment_hd_co',
                        type: 'csr',
                        Header: t('factoryEvaluation.commentHdCo'),
                        skip: !permission('comment-hd-co:view'),
                        isVisible: this.isVisible('comment_hd_co'),
                        isExpandable: true,
                        accessor: 'comment_hd_co',
                        className: 'comment-column',
	                    filters: [
		                    {
			                    onlyOne: true,
			                    filteredValue: filterData.comment_hd_co,
			                    filterOptions: {
				                    id: 'name',
				                    label: `name_${language}`
			                    },
			                    loadDataFn: () => apiGetFilters('comment_hd_co'),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'comment_hd_co');
			                    }
		                    }
						],
                        width: 280,
                        onDoubleClick: (original) => {
                            if (permission('comment-hd-co:update')) {
                                this.props.openEditor(
                                    original.id,
                                    original,
                                    'comment_hd_co',
                                    t('evaluation.editComment')
                                );
                            }
                        },
                        Cell: ({ original }) => (
                            <React.Fragment>
                                {ReactHtmlParser(original.comment_hd_co)}
                            </React.Fragment>
                        ),
                    },
                    {
                        id: 'comment_co_tuv',
                        type: 'csr',
                        Header: t('factoryEvaluation.commentCoTuv'),
                        skip: !permission('comment-co-tuv:view'),
                        isVisible: this.isVisible('comment_co_tuv'),
                        isExpandable: true,
                        accessor: 'comment_co_tuv',
                        className: 'comment-column',
	                    filters: [
		                    {
			                    onlyOne: true,
			                    filteredValue: filterData.comment_co_tuv,
			                    filterOptions: {
				                    id: 'name',
				                    label: `name_${language}`
			                    },
			                    loadDataFn: () => apiGetFilters('comment_co_tuv'),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'comment_co_tuv');
			                    }
		                    }
						],
                        width: 280,
                        onDoubleClick: (original) => {
                            if (permission('comment-co-tuv:update')) {
                                this.props.openEditor(
                                    original.id,
                                    original,
                                    'comment_co_tuv',
                                    t('evaluation.editComment')
                                );
                            }
                        },
                        Cell: ({ original }) => (
                          <React.Fragment>
                              {ReactHtmlParser(original.comment_co_tuv)}
                          </React.Fragment>
                        )
                    },
                    {
                        id: 'vendor_company_contact_1',
                        type: 'csr',
                        Header: t('factoryEvaluation.contact1'),
                        accessor: 'contact1',
                        width: tableColumnWd,
                        isVisible: this.isVisible('vendor_company_contact_1'),
                        Cell: ({ original }) => (
                            (original.vendor_company && original.vendor_company.contacts.length)
                                ? original.vendor_company.contacts[0].name : ''
                        )
                    },
                    {
                        id: 'vendor_company_contact_2',
                        type: 'csr',
                        Header: t('factoryEvaluation.contact2'),
                        accessor: 'contact2',
                        width: tableColumnWd,
                        isVisible: this.isVisible('vendor_company_contact_2'),
                        Cell: ({ original }) => (
                            (original.vendor_company && original.vendor_company.contacts.length > 1)
                                ? original.vendor_company.contacts[1].name : ''
                        )
                    },
                    {
                        id: 'vendor_company_email',
                        type: 'csr',
                        Header: t('factoryEvaluation.email'),
                        accessor: 'email',
                        width: 250,
                        isVisible: this.isVisible('vendor_company_email'),
                        isExpandable: true,
                        Cell: ({ original }) => (
                            (original.vendor_company && original.vendor_company.contacts.length) ?
                                this.breakEmails(original.vendor_company.contacts.map(contact => contact.email).join('\n')) : ''
                        )
                    },
                    {
                        id: 'vendor_company_phone_number',
                        type: 'csr',
                        Header: t('factoryEvaluation.tel'),
                        accessor: 'tel',
                        width: tableColumnWd,
                        isVisible: this.isVisible('vendor_company_phone_number'),
                        isExpandable: true,
                        Cell: ({ original }) => (
                            (original.vendor_company && original.vendor_company.contacts.length)
                                ? original.vendor_company.contacts.map(contact => contact.phone_number).join('\n') : ''
                        )
                    },
                    {
                        id: 'vendor_company_mobile_number',
                        type: 'csr',
                        Header: t('factoryEvaluation.mob'),
                        accessor: 'mob',
                        width: tableColumnWd,
                        isVisible: this.isVisible('vendor_company_mobile_number'),
                        isExpandable: true,
                        Cell: ({ original }) => (
                          (original.vendor_company && original.vendor_company.contacts.length)
                            ? original.vendor_company.contacts.map(contact => contact.mobile_number).join('\n') : ''
                        )
                    },
                    {
                        id: 'memo',
                        type: 'csr',
                        Header: t('factoryEvaluation.memo'),
                        skip: !permission('memo:view'),
                        isVisible: this.isVisible('memo'),
                        isExpandable: true,
                        accessor: 'memo',
                        className: 'comment-column',
                        width: 280,
                        onDoubleClick: (original) => {
                            if (permission('memo:update')) {
                                this.props.openEditor(
                                    original.id,
                                    original,
                                    'memo',
                                    t('evaluation.editMemo')
                                );
                            }
                        },
                        Cell: ({ original }) => (
                          <React.Fragment>
                              {ReactHtmlParser(original.memo)}
                          </React.Fragment>
                        ),
                    },
                    {
                        id: 'exam_factory_examination_date',
                        type: 'factory-examination',
                        Header: t('factoryEvaluation.facExamDate'),
                        accessor: 'exam_factory_examination_date',
                        width: tableColumnWd,
                        isVisible: this.isVisible('exam_factory_examination_date'),
	                    filters: [
		                    {
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData.exam_factory_examination_date,
			                    loadDataFn: () => apiGetFilters('exam_factory_examination_date', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'exam_factory_examination_date');
			                    }
		                    }
						]
                    },
                    {
                        id: 'exam_mfg_process_auditor',
                        type: 'factory-examination',
                        Header: t('factoryEvaluation.mfgProcessAuditor'),
                        accessor: 'exam_mfg_process_auditor',
                        width: tableColumnWd,
	                    isVisible: this.isVisible('exam_mfg_process_auditor'),
	                    filters: [
		                    {
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData.exam_mfg_process_auditor,
			                    loadDataFn: () => apiGetFilters('exam_mfg_process_auditor', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'exam_mfg_process_auditor');
			                    }
		                    }
						]
                    },
                    {
                        id: 'exam_total_evaluation',
                        type: 'factory-examination',
                        Header: t('factoryEvaluation.totalEvaluation'),
                        accessor: 'exam_total_evaluation',
                        skip: !permission('total-evaluation:view'),
	                    isVisible: this.isVisible('exam_total_evaluation'),
                        width: tableColumnWd,
                        Cell: ({ original, value }) => (
                          value ? <NumberPopup
                              id={original.id}
                              value={value}
                              onConfirm={(value) => onChangeHandler(value, 'exam_total_evaluation', original.id)}
                              t={t}
                            >
                              {permission('total-evaluation:update') ? <span className="el-table__column-filter-trigger" style={{color: 'blue'}} id={'numberPopover-' + original.id}>
                                 {`${value}% `}
                              </span> : `${value}% `}
                          </NumberPopup>  :
                          <NumberPopup
                              id={original.id}
                              value={0}
                              onConfirm={(value) => onChangeHandler(value, 'exam_total_evaluation', original.id)}
                              t={t}
                            >
                              {permission('total-evaluation:update') ? <span className="el-table__column-filter-trigger" style={{color: 'blue'}} id={'numberPopover-' + original.id}>
                                 {t('factoryEvaluation.noInput')}
                              </span> : t('factoryEvaluation.noInput')}
                          </NumberPopup>
                        ),
	                    filters: [
		                    {
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData.exam_total_evaluation,
			                    loadDataFn: () => apiGetFilters('exam_total_evaluation', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'exam_total_evaluation');
			                    }
		                    }
						]
                    },
                    {
                        id: 'exam_judgement_rank',
                        type: 'factory-examination',
                        Header: t('factoryEvaluation.judgementRank'),
                        accessor: 'exam_judgement_rank_id',
                        skip: !permission('judgement-rank:view'),
                        width: tableColumnWd,
                        className: "has-select",
	                    isVisible: this.isVisible('exam_judgement_rank'),
                        Cell: props => (
                          <React.Fragment>
                              <Select
                                value={props.value}
                                isFixed
                                width="auto"
                                onChange={(value) => onChangeHandler(value, 'exam_judgement_rank_id', props.original.id)}
                                options={judgementRanks}
                                label={`name`}
                                isDisabled={!permission('judgement-rank:update')}
                              />
                          </React.Fragment>
                        ),
	                    filters: [
		                    {
			                    filterOptions: {
				                    id: "id",
				                    label: `name`,
			                    },
			                    filteredValue: filterData.exam_judgement_rank_id,
			                    loadDataFn: () => apiGetFilters('exam_judgement_rank', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, "exam_judgement_rank_id");
			                    },
		                    }
                        ]
                    },
                    {
                        id: 'exam_evidence_eval_sheet',
                        type: 'factory-examination',
                        Header: t('factoryEvaluation.examEvidenceEvalSheet'),
                        accessor: 'audit_files',
                        width: config.width[language].exam_evidence_eval_sheet,
                        isVisible: this.isVisible('exam_evidence_eval_sheet'),
                        isExpandable: true,
	                    filters: [
		                    {
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData.audit_exam_evid_sheet_file,
			                    loadDataFn: () => apiGetFilters('audit_exam_evid_sheet_file'),
		                    }
						],
                        onFilterChange: (data) => {
                            this.props.onChangeWithFilter(data, 'audit_exam_evid_sheet_file');
                        },
                        Cell: props => (
                          props.value ? AuditFile.filter(props.value, 'is_exam_evidence_sheet').map(file => (
                            <div key={file.id} className="evidence-link">
                                <File name={file.name} link={file.path} t={t} />
                            </div>
                          )) : null
                        )
                    },
                    {
                        id: 'exam_evidence_rep_and_rem',
                        type: 'factory-examination',
                        Header: t('factoryEvaluation.examEvidenceRepAndRem'),
                        accessor: 'audit_files',
                        width: config.width[language].exam_evidence_rep_and_rem,
                        isVisible: this.isVisible('exam_evidence_rep_and_rem'),
                        isExpandable: true,
	                    filters: [
		                    {
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData.audit_exam_evid_rep_file,
			                    loadDataFn: () => apiGetFilters('audit_exam_evid_rep_file'),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'audit_exam_evid_rep_file');
			                    }
		                    }
						],
                        Cell: props => (
                          props.value ? AuditFile.filter(props.value, 'is_exam_evidence_report').map(file => (
                            <div key={file.id} className="evidence-link">
                                <File name={file.name} link={file.path} t={t} />
                            </div>
                          )) : null
                        )
                    },
                    {
                        id: 'num_of_cert',
                        type: 'mfg-process',
                        Header: t('factoryEvaluation.numOfCert'),
                        accessor: 'num_of_cert',
                        width: tableColumnWd,
	                    isVisible: this.isVisible('num_of_cert'),
	                    filters: [
		                    {
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData.num_of_cert,
			                    loadDataFn: () => apiGetFilters('num_of_cert', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'num_of_cert');
			                    }
		                    }
						]
                    },
                    {
                        id: 'mfg_total_status',
                        type: 'mfg-process',
                        Header: t('factoryEvaluation.mfgTotalStatus'),
                        accessor: 'mfg_total_status_id',
                        skip: !permission('mfg-total-status:view'),
                        width: config.width[language].mfg_total_status,
	                    isVisible: this.isVisible('mfg_total_status'),
                        Cell: props => (
                          <React.Fragment>
                              <Select
                                value={props.value}
                                isFixed
                                width="auto"
                                onChange={(value) => onChangeHandler(value, 'mfg_total_status_id', props.original.id)}
                                options={mfgTotalStatuses}
                                label={`name_${language}`}
                              />
                          </React.Fragment>
                        ),
	                    filters: [
		                    {
			                    filterOptions: {
				                    id: "id",
				                    label: `name_${language}`,
			                    },
			                    filteredValue: filterData.mfg_total_status_id,
			                    loadDataFn: () => apiGetFilters('mfg_total_status', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, "mfg_total_status_id");
			                    }
		                    }
						]
                    },
                    {
                        id: 'cert_issuance_date',
                        type: 'mfg-process',
                        Header: t('factoryEvaluation.certIssuanceDate'),
                        accessor: 'cert_issuance_date',
                        width: tableColumnWd,
                        isVisible: this.isVisible('cert_issuance_date'),
	                    filters: [
		                    {
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData['cert_issuance_date'],
			                    loadDataFn: () => apiGetFilters('cert_issuance_date', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'cert_issuance_date');
			                    }
		                    }
						]
                    },
                    {
                        id: 'evidence_cert',
                        type: 'mfg-process',
                        Header: t('factoryEvaluation.evidenceCert'),
                        accessor: 'audit_files',
                        width: config.width[language].evidence_cert,
                        isVisible: this.isVisible('evidence_cert'),
                        isExpandable: true,
                        Cell: props => (
                          props.value ? AuditFile.filter(props.value, 'is_evidence_certificate').map(file => (
                            <div key={file.id} className="evidence-link">
                                <File name={file.name} link={file.path} t={t} />
                            </div>
                          )) : null
                        )
                    },
                    {
                        id: 'cert_effective_date',
                        type: 'mfg-process',
                        Header: t('factoryEvaluation.certEffectiveDate'),
                        accessor: 'cert_effective_date',
                        width: tableColumnWd,
	                    isVisible: this.isVisible('cert_effective_date'),
	                    filters: [
		                    {
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData['cert_effective_date'],
			                    loadDataFn: () => apiGetFilters('cert_effective_date', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'cert_effective_date');
			                    },
		                    }
						]
                    },
                    {
                        id: 'cert_expiration_date',
                        type: 'mfg-process',
                        Header: t('factoryEvaluation.certExpirationDate'),
                        accessor: 'cert_expiration_date',
                        width: tableColumnWd,
	                    isVisible: this.isVisible('cert_expiration_date'),
	                    filters: [
		                    {
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData['cert_expiration_date'],
			                    loadDataFn: () => apiGetFilters('cert_expiration_date', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'cert_expiration_date');
			                    }
		                    }
						]
                    },
                    {
                        id: 'mfg_audit_year',
                        type: 'mfg-process',
                        Header: t('factoryEvaluation.mfgAuditYear'),
                        accessor: 'mfg_audit_year',
                        width: tableColumnWd,
	                    isVisible: this.isVisible('mfg_audit_year'),
	                    filters: [
		                    {
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData['mfg_audit_year'],
			                    loadDataFn: () => apiGetFilters('mfg_audit_year', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'mfg_audit_year');
			                    }
		                    }
						]
                    },
                    {
                        id: 'mfg_institution',
                        type: 'mfg-process',
                        Header: t('factoryEvaluation.mfgInstitution'),
                        accessor: 'mfg_institution_id',
                        skip: !permission('mfg-institution:view'),
                        width: config.width[language].mfg_institution,
	                    isVisible: this.isVisible('mfg_institution'),
                        Cell: props => (
                          <React.Fragment>
                              <Select
                                value={props.value}
                                isFixed
                                width="auto"
                                onChange={(value) => onChangeHandler(value, 'mfg_institution_id', props.original.id)}
                                options={mfgInstitutions}
                                label={`name_${language}`}
                              />
                          </React.Fragment>
                        ),
	                    filters: [
		                    {
			                    filterOptions: {
				                    id: "id",
				                    label: `name_${language}`,
			                    },
			                    filteredValue: filterData.mfg_institution_id,
			                    loadDataFn: () => apiGetFilters('mfg_institution', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, "mfg_institution_id");
			                    }
		                    }
						]
                    },
                    {
                        id: 'mfg_planned_month',
                        type: 'mfg-process',
                        Header: t('factoryEvaluation.mfgPlannedMonth'),
                        accessor: 'mfg_planned_month',
	                    isVisible: this.isVisible('mfg_planned_month'),
                        width: tableColumnWd,
                        Cell: ({ original }) => (
                          original.mfg_planned_month ? moment(original.mfg_planned_month).format('YYYY-MM') : ''
                        ),
	                    filters: [
		                    {
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData['mfg_planned_month'],
			                    loadDataFn: () => apiGetFilters('mfg_planned_month', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'mfg_planned_month');
			                    }
		                    }
						]
                    },
                    {
                        id: 'mfg_audit_date',
                        type: 'mfg-process',
                        Header: t('factoryEvaluation.mfgAuditDate'),
                        accessor: 'mfg_audit_date',
                        width: tableColumnWd,
                        isVisible: this.isVisible('mfg_audit_date'),
	                    filters: [
		                    {
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData['mfg_audit_date'],
			                    loadDataFn: () => apiGetFilters('mfg_audit_date', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'mfg_audit_date');
			                    }
		                    }
						]
                    },
                    {
                        id: 'curr_year_total_eval',
                        type: 'mfg-process',
                        Header: t('factoryEvaluation.currYearTotalEval'),
                        accessor: 'curr_year_total_eval',
                        skip: !permission('current-total-evaluation:view'),
                        width: tableColumnWd,
                        Cell: ({ original, value }) => (
                          value ? <NumberPopup
                              id={`${original.id}-curr`}
                              value={value}
                              onConfirm={(value) => onChangeHandler(value, 'curr_year_total_eval', original.id)}
                              t={t}
                            >
                              {permission('current-total-evaluation:update') ? <span className="el-table__column-filter-trigger" style={{color: 'blue'}} id={'numberPopover-' + original.id + '-curr'}>
                                 {`${value}% `}
                              </span> : `${value}% `}
                          </NumberPopup> :
                          <NumberPopup
                              id={`${original.id}-curr`}
                              value={0}
                              onConfirm={(value) => onChangeHandler(value, 'curr_year_total_eval', original.id)}
                              t={t}
                            >
                              {permission('current-total-evaluation:update') ? <span className="el-table__column-filter-trigger" style={{color: 'blue'}} id={'numberPopover-' + original.id + '-curr'}>
                                 {t('factoryEvaluation.noInput')}
                              </span> : t('factoryEvaluation.noInput')}
                          </NumberPopup>
                        ),
	                    isVisible: this.isVisible('curr_year_total_eval'),
	                    filters: [
		                    {
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData['curr_year_total_eval'],
			                    loadDataFn: () => apiGetFilters('curr_year_total_eval', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'curr_year_total_eval');
			                    }
		                    }
						]
                    },
                    {
                        id: 'curr_year_judgement_rank',
                        type: 'mfg-process',
                        Header: t('factoryEvaluation.currYearJudgementRank'),
                        accessor: 'curr_year_judgement_rank_id',
                        width: tableColumnWd,
                        Cell: props => (
                          <React.Fragment>
                              <Select
                                value={props.value}
                                isFixed
                                width="auto"
                                onChange={(value) => onChangeHandler(value, 'curr_year_judgement_rank_id', props.original.id)}
                                options={judgementRanks}
                                label={`name`}
                                isDisabled={true}
                              />
                          </React.Fragment>
                        ),
	                    filters: [
		                    {
			                    filterOptions: {
				                    id: 'id',
				                    label: 'name',
			                    },
			                    filteredValue: filterData.curr_year_judgement_rank_id,
			                    loadDataFn: () => apiGetFilters('curr_year_judgement_rank', h.getQuery()),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'curr_year_judgement_rank_id');
			                    }
		                    }
						],
                        isVisible: this.isVisible('curr_year_judgement_rank')
                    },
                    {
                        id: 'mfg_evidence_sheet',
                        type: 'mfg-process',
                        Header: t('factoryEvaluation.mfgEvidenceSheet'),
                        accessor: 'audit_files',
                        width: config.width[language].mfg_evidence_sheet,
                        isVisible: this.isVisible('mfg_evidence_sheet'),
                        isExpandable: true,
	                    filters: [
		                    {
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData.audit_mfg_evid_sheet_file,
			                    loadDataFn: () => apiGetFilters('audit_mfg_evid_sheet_file'),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'audit_mfg_evid_sheet_file');
			                    }
		                    }
						],
                        Cell: props => (
                          props.value ? AuditFile.filter(props.value, 'is_mfg_evidence_sheet').map(file => (
                            <div key={file.id} className="evidence-link">
                                <File name={file.name} link={file.path} t={t} />
                            </div>
                          )) : null
                        ),
                    },
                    {
                        id: 'mfg_evidence_rep_and_rem',
                        type: 'mfg-process',
                        Header: t('factoryEvaluation.mfgEvidenceRepAndRem'),
                        accessor: 'audit_files',
                        width: config.width[language].mfg_evidence_rep_and_rem,
                        isVisible: this.isVisible('mfg_evidence_rep_and_rem'),
                        isExpandable: true,
	                    filters: [
		                    {
			                    filterOptions: {
				                    id: 'name',
				                    label: 'name',
			                    },
			                    filteredValue: filterData.audit_mfg_evid_rep_file,
			                    loadDataFn: () => apiGetFilters('audit_mfg_evid_rep_file'),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'audit_mfg_evid_rep_file');
			                    }
		                    }
						],
                        Cell: props => (
                          props.value ? AuditFile.filter(props.value, 'is_mfg_evidence_report').map(file => (
                            <div key={file.id} className="evidence-link">
                                <File name={file.name} link={file.path} t={t} />
                            </div>
                          )) : null
                        ),
                    },
                    {
                        id: 'mfg_comment_hd_bus',
                        type: 'mfg-process',
                        Header: t('factoryEvaluation.mfgCommentHdBus'),
                        accessor: 'mfg_comment_hd_bus',
                        skip: !permission('mfg-comment-hd-bus:view'),
                        width: config.width[language].mfg_comment_hd_bus,
                        isVisible: this.isVisible('mfg_comment_hd_bus'),
                        isExpandable: true,
                        onDoubleClick: (original) => {
                            if (permission('comment-hd-co:update')) {
                                this.props.openEditor(
                                    original.id,
                                    original,
                                    'mfg_comment_hd_bus',
                                    t('evaluation.editComment')
                                );
                            }
                        },
	                    filters: [
		                    {
			                    filteredValue: filterData.mfg_comment_hd_bus,
			                    filterOptions: {
				                    id: 'name',
				                    label: `name_${language}`
			                    },
			                    loadDataFn: () => apiGetFilters('mfg_comment_hd_bus'),
			                    onFilterChange: (data) => {
				                    this.props.onChangeWithFilter(data, 'mfg_comment_hd_bus');
			                    }
		                    }
						],
                        Cell: ({ original }) => (
                            <React.Fragment>
                                {ReactHtmlParser(original.mfg_comment_hd_bus)}
                            </React.Fragment>
                        ),
                    },
                    {
                        id: 'mfg_memo',
                        type: 'mfg-process',
                        Header: t('factoryEvaluation.mfgMemo'),
                        accessor: 'mfg_memo',
                        skip: !permission('mfg-memo:view'),
                        width: config.width[language].mfg_memo,
                        isVisible: this.isVisible('mfg_memo'),
                        isExpandable: true,
                        onDoubleClick: (original) => {
                            if (permission('comment-hd-co:update')) {
                                this.props.openEditor(
                                    original.id,
                                    original,
                                    'mfg_memo',
                                    t('evaluation.editComment')
                                );
                            }
                        },
                        Cell: ({ original }) => (
                            <React.Fragment>
                                {ReactHtmlParser(original.mfg_memo)}
                            </React.Fragment>
                        ),
                    },
                    {
                        id: 'mfg_last_update',
                        type: 'mfg-process',
                        Header: t('factoryEvaluation.mfgLastUpdate'),
                        accessor: 'mfg_last_update',
                        width: tableColumnWd,
                        isVisible: this.isVisible('mfg_last_update'),
                    },
                ]}
            />
        )
    }
}

export default withNamespaces()(FactoryEvaluationListDefault);

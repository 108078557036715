import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import permission from '../../utils/permission';
import ConfigModal from './ConfigModal'

class ConfigModalWrapper extends Component {

    shouldComponentUpdate(prevProps) {
        return prevProps.isRender !== this.props.isRender;
    }

    isVisible = (column) => {
        let visible;
        if (this.props.data) {
                this.props.data.map((item) => {
                if (item.id === column) {
                    visible = item.is_visible;
                }
                return visible;
            })
        }
    }

    render() {
        const {
            is7p,
            isHldgs,
            data,
            t
        } = this.props;
            return (
            <ConfigModal
                isOpen={this.props.isOpen}
                close={this.props.close}
                preferences={data}
                columns={[
                    {
                        id: 'business_company',
                        type: 'common',
                        Header: t('factoryEvaluation.businessCo'),
                        checked: this.isVisible('business_company')
                    },
                    {
                        id: 'product_category',
                        type: 'common',
                        Header: t('factoryEvaluation.category'),
                        checked: this.isVisible('product_category')
                    },
	                {
		                id: 'business_company_other',
                        type: 'common',
		                Header: t('factoryEvaluation.businessCoOther'),
		                checked: this.isVisible('business_company_other')
	                },
                    {
                        id: 'product_sub_category',
                        type: 'common',
                        Header: t('factoryEvaluation.subCategory'),
                        checked: this.isVisible('product_sub_category'),
                        skip: !is7p,
                    },
                    {
                        id: 'working_group',
                        type: 'common',
                        Header: t('factoryEvaluation.workingGroup'),
                        checked: this.isVisible('working_group'),
                        skip: !is7p,
                    },
                    {
                        id: 'product',
                        type: 'common',
                        Header: t('factoryEvaluation.productItem'),
                        checked: this.isVisible('product'),
                        skip: !is7p,
                    },
                    {
                        id: 'vendor_factory',
                        type: 'common',
                        Header: t('factoryEvaluation.factory_asia'),
                        checked: this.isVisible('vendor_factory')
                    },
                    {
                        id: 'vendor_factory_en',
                        type: 'common',
                        Header: t('factoryEvaluation.factory_en'),
                        checked: this.isVisible('vendor_factory_en')
                    },
                    {
                        id: 'vendor_company',
                        type: 'common',
                        Header: t('factoryEvaluation.company_jp'),
                        checked: this.isVisible('vendor_company')
                    },
                    {
                        id: 'vendor_company_en',
                        type: 'common',
                        Header: t('factoryEvaluation.company_en'),
                        checked: this.isVisible('vendor_company_en')
                    },
                    {
                        id: 'audit_year',
                        type: 'common',
                        Header: t('factoryEvaluation.auditYear'),
                        checked: this.isVisible('audit_year')
                    },
                    {
                        id: 'location',
                        type: 'common',
                        Header: t('factoryEvaluation.location'),
                        checked: this.isVisible('location')
                    },
                    {
                        id: 'md_name',
                        type: 'common',
                        Header: t('factoryEvaluation.mdName'),
                        checked: this.isVisible('md_name')
                    },
                    {
                        id: 'factory_code',
                        type: 'common',
                        Header: t('factoryEvaluation.factoryCode'),
                        checked: this.isVisible('factory_code')
                    },
                    {
                        id: 'jan_code',
                        type: 'common',
                        Header: t('factoryEvaluation.janCode'),
                        checked: this.isVisible('jan_code')
                    },
                    {
                        id: 'division_number',
                        type: 'common',
                        Header: t('factoryEvaluation.divisionNumber'),
                        checked: this.isVisible('division_number')
                    },
                    {
                        id: 'organization_name',
                        type: 'common',
                        Header: t('factoryEvaluation.organizationName'),
                        checked: this.isVisible('organization_name')
                    },
                    {
                        id: 'audit_number',
                        type: 'csr',
                        Header: t('factoryEvaluation.auditNumber'),
                        checked: this.isVisible('audit_number')
                    },
                    {
                        id: 'total_status',
                        type: 'csr',
                        Header: t('factoryEvaluation.totalStatus'),
                        skip: !permission('total-status:view'),
                        checked: this.isVisible('total_status'),
                    },
                    {
                        id: 'certification_issuance',
                        type: 'csr',
                        Header: t('factoryEvaluation.certificationIssuance'),
                        skip: !permission('certification-issuance:view'),
                        checked: this.isVisible('certification_issuance')
                    },
                    {
                        id: 'certificate_mail_date',
                        type: 'csr',
                        Header: t('factoryEvaluation.certificateMailDate'),
                        skip: !permission('certificate-mail-date:view'),
                        checked: this.isVisible('certificate_mail_date')
                    },
                    {
                        id: 'certificate_mail_receipt',
                        type: 'csr',
                        Header: t('factoryEvaluation.certificateMailReceipt'),
                        skip: !permission('certificate-mail-receipt:view'),
                        checked: this.isVisible('certificate_mail_receipt')
                    },
                    {
                        id: 'effective_date',
                        type: 'csr',
                        Header: t('factoryEvaluation.effectiveDate'),
                        checked: this.isVisible('effective_date')
                    },
                    {
                        id: 'expiration_date',
                        type: 'csr',
                        Header: t('factoryEvaluation.expirationDate'),
                        checked: this.isVisible('expiration_date')
                    },
                    {
                        id: 'fy2020',
                        type: 'csr',
                        Header: t('vendorFactory.fy2020Audit'),
                        checked: this.isVisible('fy2020')
                    },
                    {
                        id: 'audit_category',
                        type: 'csr',
                        Header: t('factoryEvaluation.auditCategory'),
                        skip: !permission('audit-category:view'),
                        checked: this.isVisible('audit_category')
                    },
                    {
                        id: 'audit_type',
                        type: 'csr',
                        Header: t('factoryEvaluation.auditType'),
                        skip: !permission('audit-type:view'),
                        checked: this.isVisible('audit_type')
                    },
                    {
                        id: 'preferred_audit_month',
                        type: 'csr',
                        Header: t('factoryEvaluation.preferredAuditMonth'),
                        skip: !permission('preferred-audit-date:view'),
                        checked: this.isVisible('preferred_audit_month')
                    },
                    {
                        id: 'audit_date',
                        type: 'csr',
                        Header: t('factoryEvaluation.auditDate'),
                        checked: this.isVisible('audit_date')
                    },

	                {
		                id: 'audit_fee',
		                type: 'csr',
		                Header: t('factoryEvaluation.auditFee'),
		                checked: this.isVisible('audit_fee')
	                },
	                {
		                id: 'care_fee',
		                type: 'csr',
		                Header: t('factoryEvaluation.careFee'),
		                checked: this.isVisible('care_fee')
	                },
	                {
		                id: 'misc_fee',
		                type: 'csr',
		                Header: t('factoryEvaluation.miscFee'),
		                checked: this.isVisible('misc_fee')
	                },
	                {
		                id: 'cancel_fee',
		                type: 'csr',
		                Header: t('factoryEvaluation.cancelFee'),
		                checked: this.isVisible('cancel_fee')
	                },
	                {
		                id: 'certificate_fee',
		                type: 'csr',
		                Header: t('factoryEvaluation.certificateFee'),
		                checked: this.isVisible('certificate_fee')
	                },
	                {
		                id: 'reaudit_fee',
		                type: 'csr',
		                Header: t('factoryEvaluation.reauditFee'),
		                checked: this.isVisible('reaudit_fee')
	                },
	                {
		                id: 'comment_fee',
		                type: 'csr',
		                Header: t('factoryEvaluation.commentFee'),
		                checked: this.isVisible('comment_fee')
	                },
	                {
		                id: 'is_new',
		                type: 'csr',
		                Header: t('factoryEvaluation.isNew'),
		                checked: this.isVisible('is_new')
	                },
                    {
                        id: 'auditor_in_charge',
                        type: 'csr',
                        Header: t('factoryEvaluation.auditorInCharge'),
                        checked: this.isVisible('auditor_in_charge')
                    },
                    {
                        id: 'exception_flag',
                        type: 'csr',
                        Header: t('factoryEvaluation.exceptionFlag'),
                        skip: !permission('exception-flag:view'),
                        checked: this.isVisible('exception_flag')
                    },
                    {
                        id: 'evaluation_status',
                        type: 'csr',
                        Header: t('factoryEvaluation.evaluationStatus'),
                        skip: !permission('evaluation-status:view'),
                        checked: this.isVisible('evaluation_status')
                    },
                    {
                        id: 'deadline',
                        type: 'csr',
                        Header: t('factoryEvaluation.deadline'),
                        skip: !permission('deadline:view'),
                        checked: this.isVisible('deadline')
                    },
                    {
                        id: 'status_changed_date',
                        type: 'csr',
                        Header: t('factoryEvaluation.lastUpdate'),
                        checked: this.isVisible('status_changed_date')
                    },
                    {
                        id: 'previous_evaluation_score',
                        type: 'csr',
                        Header: t('factoryEvaluation.previousEvaluation'),
                        checked: this.isVisible('previous_evaluation_score')
                    },
                    {
                        id: 'latest_evaluation_score',
                        type: 'csr',
                        Header: t('factoryEvaluation.latestEvaluation'),
                        skip: !permission('latest-evaluation-score:view'),
                        checked: this.isVisible('latest_evaluation_score')
                    },
	                {
		                id: 'evaluation_result_previous',
		                type: 'csr',
		                Header: t('factoryEvaluation.evaluationResultPrevious'),
		                checked: this.isVisible('evaluation_result_previous')
	                },
                    {
                        id: 'scap',
                        type: 'csr',
                        Header: t('factoryEvaluation.cpaScap'),
                        checked: this.isVisible('scap')
                    },
                    {
                        id: 'comment_tuv_hd',
                        type: 'csr',
                        Header: t('factoryEvaluation.commentTuvHd'),
                        skip: !permission('comment-tuv-hd:view'),
                        checked: this.isVisible('comment_tuv_hd')
                    },
                    {
                        id: 'evaluation_result',
                        type: 'csr',
                        Header: t('factoryEvaluation.evaluationResult'),
                        skip: !permission('evaluation-result:view'),
                        checked: this.isVisible('evaluation_result')
                    },
                    {
                        id: 'judgement_decision',
                        type: 'csr',
                        Header: t('factoryEvaluation.judgementDecision'),
                        skip: !permission('judgement-confirm:view'),
                        checked: this.isVisible('judgement_decision')
                    },
                    {
                        id: 'decision_history',
                        type: 'csr',
                        Header: t('factoryEvaluation.decisionHistory'),
                        skip: !permission('judgement-confirm:view'),
                        checked: this.isVisible('decisionHistory')
                    },
                    {
                        id: 'judgement_due_date',
                        type: 'csr',
                        Header: t('factoryEvaluation.judgementDueDate'),
                        checked: this.isVisible('judgement_due_date')
                    },
                    {
                        id: 're_audit_planned_date',
                        type: 'csr',
                        Header: t('factoryEvaluation.reAuditPlannedDate'),
                        checked: this.isVisible('re_audit_planned_date')
                    },
                    {
                        id: 'post_audit_status',
                        type: 'csr',
                        Header: t('factoryEvaluation.postAuditStatus'),
                        skip: !permission('post-audit-status:view'),
                        checked: this.isVisible('post_audit_status')
                    },
                    {
                        id: 'nc',
                        type: 'csr',
                        Header: t('factoryEvaluation.nbrofNC'),
                        checked: this.isVisible('nc')
                    },
                    {
                        id: 'no_of_evidence',
                        type: 'csr',
                        Header: t('factoryEvaluation.noEvidences'),
                        checked: this.isVisible('no_of_evidence')
                    },
                    {
                        id: 'scap_deadline_date',
                        type: 'csr',
                        Header: t('factoryEvaluation.scapDeadline'),
                        checked: this.isVisible('scap_deadline_date')
                    },
                    {
                        id: 'scap_received_date',
                        type: 'csr',
                        Header: t('factoryEvaluation.scapReceivedDate'),
                        checked: this.isVisible('scap_received_date')
                    },
                    {
                        id: 'certificate',
                        type: 'csr',
                        Header: t('factoryEvaluation.certificate'),
                        checked: this.isVisible('certificate')
                    },
                    {
                        id: 'related_materials',
                        type: 'csr',
                        Header: t('factoryEvaluation.relatedMaterials'),
                        checked: this.isVisible('related_materials'),
                        skip: !isHldgs
                    },
                    {
                        id: 'saq',
                        type: 'csr',
                        Header: t('factoryEvaluation.saq'),
                        checked: this.isVisible('saq')
                    },
                    {
                        id: 'no_of_evidences',
                        type: 'csr',
                        Header: t('factoryEvaluation.no_of_evidences'),
                        checked: this.isVisible('no_of_evidences')
                    },
                    {
                        id: 'no_of_major',
                        type: 'csr',
                        Header: t('factoryEvaluation.major'),
                        checked: this.isVisible('no_of_major')
                    },
                    {
                        id: 'no_of_minor',
                        type: 'csr',
                        Header: t('factoryEvaluation.minor'),
                        checked: this.isVisible('no_of_minor')
                    },
                    {
                        id: 'no_of_quality',
                        type: 'csr',
                        Header: t('factoryEvaluation.quality'),
                        checked: this.isVisible('no_of_quality')
                    },
                    {
                        id: 'no_of_c1',
                        type: 'csr',
                        Header: t('factoryEvaluation.c1'),
                        checked: this.isVisible('no_of_c1')
                    },
                    {
                        id: 'no_of_c2',
                        type: 'csr',
                        Header: t('factoryEvaluation.c2'),
                        checked: this.isVisible('no_of_c2')
                    },
                    {
                        id: 'comment_hd_co',
                        type: 'csr',
                        Header: t('factoryEvaluation.commentHdCo'),
                        skip: !permission('comment-hd-co:view'),
                        checked: this.isVisible('comment_hd_co')
                    },
                    {
                        id: 'comment_co_tuv',
                        type: 'csr',
                        Header: t('factoryEvaluation.commentCoTuv'),
                        skip: !permission('comment-co-tuv:view'),
                        checked: this.isVisible('comment_co_tuv')
                    },
                    {
                        id: 'vendor_company_contact_1',
                        type: 'csr',
                        Header: t('factoryEvaluation.contact1'),
                        checked: this.isVisible('vendor_company_contact_1')
                    },
                    {
                        id: 'vendor_company_contact_2',
                        type: 'csr',
                        Header: t('factoryEvaluation.contact2'),
                        checked: this.isVisible('vendor_company_contact_2')
                    },
                    {
                        id: 'vendor_company_email',
                        type: 'csr',
                        Header: t('factoryEvaluation.email'),
                        checked: this.isVisible('vendor_company_email')

                    },
                    {
                        id: 'vendor_company_phone_number',
                        type: 'csr',
                        Header: t('factoryEvaluation.tel'),
                        checked: this.isVisible('vendor_company_phone_number')
                    },
                    {
                        id: 'vendor_company_mobile_number',
                        type: 'csr',
                        Header: t('factoryEvaluation.mob'),
                        checked: this.isVisible('vendor_company_mobile_number')
                    },
                    {
                        id: 'memo',
                        type: 'csr',
                        Header: t('factoryEvaluation.memo'),
                        skip: !permission('memo:view'),
                        checked: this.isVisible('memo')
                    },
                    {
                        Number: 59,
                        id: 'exam_factory_examination_date',
                        type: 'factory-examination',
                        Header: t('factoryEvaluation.facExamDate'),
                        checked: this.isVisible('exam_factory_examination_date')
                    },
										{
                        Number: 60,
                        id: 'exam_mfg_process_auditor',
                        type: 'factory-examination',
                        Header: t('factoryEvaluation.mfgProcessAuditor'),
                        checked: this.isVisible('exam_mfg_process_auditor')
                    },
										{
                        Number: 61,
                        id: 'exam_total_evaluation',
                        type: 'factory-examination',
                        Header: t('factoryEvaluation.totalEvaluation'),
                        skip: !permission('total-evaluation:view'),
                        checked: this.isVisible('exam_total_evaluation')
                    },
										{
                        Number: 62,
                        id: 'exam_judgement_rank',
                        type: 'factory-examination',
                        Header: t('factoryEvaluation.judgementRank'),
                        skip: !permission('judgement-rank:view'),
                        checked: this.isVisible('exam_judgement_rank')
                    },
										{
                        Number: 63,
                        id: 'exam_evidence_eval_sheet',
                        type: 'factory-examination',
                        Header: t('factoryEvaluation.examEvidenceEvalSheet'),
                        checked: this.isVisible('exam_evidence_eval_sheet')
                    },
										{
                        Number: 64,
                        id: 'exam_evidence_rep_and_rem',
                        type: 'factory-examination',
                        Header: t('factoryEvaluation.examEvidenceRepAndRem'),
                        checked: this.isVisible('exam_evidence_rep_and_rem')
                    },
										{
                        Number: 65,
                        id: 'num_of_cert',
                        type: 'mfg-process',
                        Header: t('factoryEvaluation.numOfCert'),
                        checked: this.isVisible('num_of_cert')
                    },
										{
                        Number: 66,
                        id: 'mfg_total_status',
                        type: 'mfg-process',
                        Header: t('factoryEvaluation.mfgTotalStatus'),
                        skip: !permission('total-status:view'),
                        checked: this.isVisible('mfg_total_status')
                    },
										{
                        Number: 67,
                        id: 'cert_issuance_date',
                        type: 'mfg-process',
                        Header: t('factoryEvaluation.certIssuanceDate'),
                        checked: this.isVisible('cert_issuance_date')
                    },
										{
                        Number: 68,
                        id: 'evidence_cert',
                        type: 'mfg-process',
                        Header: t('factoryEvaluation.evidenceCert'),
                        checked: this.isVisible('evidence_cert')
                    },
										{
                        Number: 69,
                        id: 'cert_effective_date',
                        type: 'mfg-process',
                        Header: t('factoryEvaluation.certEffectiveDate'),
                        checked: this.isVisible('cert_effective_date')
                    },
										{
                        Number: 70,
                        id: 'cert_expiration_date',
                        type: 'mfg-process',
                        Header: t('factoryEvaluation.certExpirationDate'),
                        checked: this.isVisible('cert_expiration_date')
                    },
										{
                        Number: 71,
                        id: 'mfg_audit_year',
                        type: 'mfg-process',
                        Header: t('factoryEvaluation.mfgAuditYear'),
                        checked: this.isVisible('mfg_audit_year')
                    },
										{
                        Number: 72,
                        id: 'mfg_institution',
                        type: 'mfg-process',
                        Header: t('factoryEvaluation.mfgInstitution'),
                        skip: !permission('mfg-institution:view'),
                        checked: this.isVisible('mfg_institution')
                    },
										{
                        Number: 73,
                        id: 'mfg_planned_month',
                        type: 'mfg-process',
                        Header: t('factoryEvaluation.mfgPlannedMonth'),
                        checked: this.isVisible('mfg_planned_month')
                    },
										{
                        Number: 74,
                        id: 'mfg_audit_date',
                        type: 'mfg-process',
                        Header: t('factoryEvaluation.mfgAuditDate'),
                        checked: this.isVisible('mfg_audit_date')
                    },
										{
                        Number: 75,
                        id: 'curr_year_total_eval',
                        type: 'mfg-process',
                        Header: t('factoryEvaluation.currYearTotalEval'),
                        skip: !permission('current-total-evaluation:view'),
                        checked: this.isVisible('curr_year_total_eval')
                    },
										{
                        Number: 76,
                        id: 'curr_year_judgement_rank',
                        type: 'mfg-process',
                        Header: t('factoryEvaluation.currYearJudgementRank'),
                        checked: this.isVisible('curr_year_judgement_rank')
                    },
										{
                        Number: 77,
                        id: 'mfg_evidence_sheet',
                        type: 'mfg-process',
                        Header: t('factoryEvaluation.mfgEvidenceSheet'),
                        checked: this.isVisible('mfg_evidence_sheet')
                    },
										{
                        Number: 78,
                        id: 'mfg_evidence_rep_and_rem',
                        type: 'mfg-process',
                        Header: t('factoryEvaluation.mfgEvidenceRepAndRem'),
                        checked: this.isVisible('mfg_evidence_rep_and_rem')
                    },
										{
                        Number: 79,
                        id: 'mfg_comment_hd_bus',
                        type: 'mfg-process',
                        Header: t('factoryEvaluation.mfgCommentHdBus'),
                        skip: !permission('mfg-comment-hd-bus:view'),
                        checked: this.isVisible('mfg_comment_hd_bus')
                    },
										{
                        Number: 80,
                        id: 'mfg_memo',
                        type: 'mfg-process',
                        Header: t('factoryEvaluation.mfgMemo'),
                        skip: !permission('mfg-memo:view'),
                        checked: this.isVisible('mfg_memo')
                    },
										{
                        Number: 81,
                        id: 'mfg_last_update',
                        type: 'mfg-process',
                        Header: t('factoryEvaluation.mfgLastUpdate'),
                        checked: this.isVisible('mfg_last_update')
                    },
                ]}
            />
        )
    }
}

export default withNamespaces()(ConfigModalWrapper);

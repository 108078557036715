import { isEuropeOrUs, isCertificateValid, isDomestic } from "./utility";

export function filterEvaluationResult(item, original) {
    if (item.audit_year === 2022) {
        switch (true) {
            case (isEuropeOrUs(original.vendor_factory.country && original.vendor_factory.country.code)):
                return [original.evaluation_result_id, 201].indexOf(item.id) >= 0 ? true : false;
            case (isCertificateValid(original.expiration_date)):
                return [original.evaluation_result_id, 202].indexOf(item.id) >= 0 ? true : false;
            case (original.exception_flag_id === 10):
                return [original.evaluation_result_id, 203].indexOf(item.id) >= 0 ? true : false;
            /***** Base on total status *****/
            case (23 === original.total_status_id):
                return [original.evaluation_result_id,  235, 236, 237, 238, 239].indexOf(item.id) >= 0 ? true : false;
            /*****Audit Category: Regular Audit (First time) && Regular Audit (Renewal)******/
            case (([1, 2].includes(original.audit_category_id)) && (1 === original.audit_type_id) && ([10, 11].includes(original.latest_evaluation_score_id))):
                return [original.evaluation_result_id, 218, 219, 220, 221, 222, 223, 224, 225].indexOf(item.id) >= 0 ? true : false;
            case (([1, 2].includes(original.audit_category_id)) && (1 === original.audit_type_id) && ([8, 9, 12, 13, 14, 15].includes(original.latest_evaluation_score_id)) && !isDomestic(original)):
                return [original.evaluation_result_id, 218, 219, 220, 221, 222, 223, 224, 225].indexOf(item.id) >= 0 ? true : false;
            case (([1, 2].includes(original.audit_category_id)) && (1 === original.audit_type_id) && ([8, 9, 12, 13, 14, 15].includes(original.latest_evaluation_score_id)) && (14 === original.total_status_id) && isDomestic(original)):
                return [original.evaluation_result_id, 211, 212, 213, 214, 215, 216, 217, 232, 233].indexOf(item.id) >= 0 ? true : false;
            case (([1, 2].includes(original.audit_category_id)) && ([1, 3].includes(original.audit_type_id)) && ([2, 3, 4, 5, 6, 7].includes(original.total_status_id))):
                return [original.evaluation_result_id, 204, 205, 206, 207, 208, 209].indexOf(item.id) >= 0 ? true : false;
            case (([1, 2].includes(original.audit_category_id)) && ([1, 3].includes(original.audit_type_id)) && ([8, 9, 10, 11, 12, 13].includes(original.total_status_id))):
                return [original.evaluation_result_id, 210, 233].indexOf(item.id) >= 0 ? true : false;
            case (([1, 2].includes(original.audit_category_id)) && (1 === original.audit_type_id) && (14 === original.total_status_id)):
                return [original.evaluation_result_id, 211, 212, 213, 214, 215, 216, 217, 232].indexOf(item.id) >= 0 ? true : false;
            /*****Audit Category: Regular Audit && Audit Type: Remote Audit******/
            case (([1, 2].includes(original.audit_category_id)) && (3 === original.audit_type_id) && (14 === original.total_status_id)):
                return [original.evaluation_result_id, 226, 227, 228, 229, 230, 231, 232].indexOf(item.id) >= 0 ? true : false;
            /******Audit Category: Re-audit*****/
            case ((3 === original.audit_category_id) && (1 === original.audit_type_id) && ([8, 9, 10, 11, 12, 13, 14, 15].includes(original.latest_evaluation_score_id))):
                return [original.evaluation_result_id, 246, 247, 248, 249, 250].indexOf(item.id) >= 0 ? true : false;
            case ((3 === original.audit_category_id) && ([1, 3].includes(original.audit_type_id)) && ([22, 24, 25].includes(original.total_status_id))):
                return [original.evaluation_result_id, 234, 235, 236, 237, 238, 239, 240, 241].indexOf(item.id) >= 0 ? true : false;
            case ((3 === original.audit_category_id) && ([1, 3].includes(original.audit_type_id)) && ([26, 27, 28].includes(original.total_status_id))):
                return [original.evaluation_result_id, 242, 256].indexOf(item.id) >= 0 ? true : false;
            case ((3 === original.audit_category_id) && (1 === original.audit_type_id) && (14 === original.total_status_id)):
                return [original.evaluation_result_id, 243, 244, 245, 255].indexOf(item.id) >= 0 ? true : false;
            /*****Audit Category: Re-audit && Audit Type: Remote Audit*****/
            case ((3 === original.audit_category_id) && (3 === original.audit_type_id) && (14 === original.total_status_id)):
                return [original.evaluation_result_id, 251, 252, 253, 254, 255].indexOf(item.id) >= 0 ? true : false;
            /******Audit Category: Re-re-audit*****/
            case ((4 === original.audit_category_id) && ([1, 3].includes(original.audit_type_id)) && ([8, 9, 10, 11, 12, 13, 14, 15].includes(original.latest_evaluation_score_id))):
                return [original.evaluation_result_id, 267, 268, 269, 270].indexOf(item.id) >= 0 ? true : false;
            case ((4 === original.audit_category_id) && ([1, 3].includes(original.audit_type_id)) && ([29, 30, 31, 32].includes(original.total_status_id))):
                return [original.evaluation_result_id, 257, 258, 259, 260, 261, 262].indexOf(item.id) >= 0 ? true : false;
            case ((4 === original.audit_category_id) && ([1, 3].includes(original.audit_type_id)) && ([33, 34, 35].includes(original.total_status_id))):
                return [original.evaluation_result_id, 263, 272].indexOf(item.id) >= 0 ? true : false;
            case ((4 === original.audit_category_id) && (1 === original.audit_type_id) && (14 === original.total_status_id)):
                return [original.evaluation_result_id, 264, 265, 266, 271].indexOf(item.id) >= 0 ? true : false;
            default:
                return;
        }
    }
}

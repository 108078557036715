export function filterTotalStatuses(item, original) {
    switch (true) {
        /*****Audit Category: Regular Audit (First time) && Regular Audit (Renewal)******/
        case (([1, 2].includes(original.audit_category_id)) && ([1, 3].includes(original.audit_type_id)) && !isAuditEnacted(original)):
            return [original.total_status_id, 1, 2, 3, 4, 5, 6, 7, 7].indexOf(item.id) >= 0 ? true : false;
        case (([1, 2].includes(original.audit_category_id)) && ([1, 3].includes(original.audit_type_id) && ![210, 211, 212, 213, 214, 215, 216, 217].includes(original.evlauation_result_id)) && isAuditEnacted(original)):
            return [original.total_status_id, 8, 9, 10, 11, 12, 13, 14].indexOf(item.id) >= 0 ? true : false;
        case (([1, 2].includes(original.audit_category_id)) && ([1, 3].includes(original.audit_type_id) && [210, 211, 212, 213, 214, 215, 216, 217].includes(original.evlauation_result_id)) && isAuditEnacted(original)):
            return [original.total_status_id, 15, 16, 17, 18, 19, 20, 21].indexOf(item.id) >= 0 ? true : false;
        /*****Audit Category: Re-audit *****/
        case ((3 === original.audit_category_id) && ([1, 3].includes(original.audit_type_id)) && !isAuditEnacted(original)):
            return [original.total_status_id, 22, 23, 24, 25].indexOf(item.id) >= 0 ? true : false;
        case ((3 === original.audit_category_id) && ([1, 3].includes(original.audit_type_id) && ![210, 211, 212, 213, 214, 215, 216, 217].includes(original.evlauation_result_id)) && isAuditEnacted(original)):
            return [original.total_status_id, 14, 26, 27, 28].indexOf(item.id) >= 0 ? true : false;
        /*****Audit Category: Re-re-audit *****/
        case ((4 === original.audit_category_id) && 1 === original.audit_type_id && !isAuditEnacted(original)):
            return [original.total_status_id, 29, 30, 31, 32].indexOf(item.id) >= 0 ? true : false;
        case ((4 === original.audit_category_id) && 1 === original.audit_type_id && (![210, 211, 212, 213, 214, 215, 216, 217].includes(original.evlauation_result_id)) && isAuditEnacted(original)):
            return [original.total_status_id, 14, 33, 34, 35].indexOf(item.id) >= 0 ? true : false;
        default:
            return;
    }
}

function isAuditEnacted(original) {
    let hasCap = false;
    let auditDatePassed = original.audit_date && original.audit_date !== null ? new Date(original.audit_date) < new Date() : false;
    original.audit_files.forEach((file) => {
        if (file.is_scap) {
            hasCap = true;
        }
    });

    if (!hasCap || !auditDatePassed) {
        return false;
    } else {
        return true;
    }
}

import React from 'react';
import { Select } from '../Common';
import { Button, Dialog } from 'element-react';
import { withNamespaces } from 'react-i18next';
import UpdateContactPopup from './UpdateContactPopup';
import CertificateIssuanceCell from './CertificateIssuanceCell';
import permission from '../../utils/permission';
import { getStepIds } from '../../utils/certificates';
import FilterContent from '../Common/FixedTable/FilterContent';
import { apiGetFilters } from '../../api/certification-issuance';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

const CertificateIssuanceList = (props) => {
    const { certificateIssuances, t } = props;
    let element = document.querySelector('.cert-scroll');
    let topBar = document.querySelector('.top-scrollbar')
    let table = document.querySelector('.cert-table');
    const ro = new ResizeObserver(entries => {
      if (entries) {
        props.setTopScrollbarWidth();
        props.syncScrollbars();
      }
    });
    if(table) { ro.observe(table) };
    if (element) {
        element.addEventListener('scroll', function () {
            topBar.scrollLeft = element.scrollLeft
        });
    }

    if (topBar) {
        topBar.addEventListener('scroll', () => {
            element.scrollLeft = topBar.scrollLeft
        })
    }
    return (
        <>
        <div className='top-scrollbar' style={{ height: '17px', overflowX: 'scroll '}}><div className='top-scrollbar__content' style={{ height: '20px' }}></div></div>
        <div className='cert-scroll' style={{ maxHeight: '829px', overflow: 'scroll' }}>
        <table className='cert-table table'>
            <thead>
                <tr style={{ backgroundColor: '#b4c6e7' }} >
                    {certificateIssuances && certificateIssuances.length > 0 && <th style={{ backgroundColor: '#fff', boxShadow: '0 2px 0 0 #fff', position: 'sticky', left: 0, zIndex: 15 }}><span style={{width: '38px', height: '5px', position: 'absolute', zIndex: 10, backgroundColor: '#fff', left: '0', top: '-3px'}}></span></th>}
                    <th className="freeze" style={{ backgroundColor: '#b4c6e7', left: certificateIssuances && certificateIssuances.length > 0 ? '38px' : '0px' }}><p style={{width: '150px'}}>{t('certificationIssuance.operatingCompanies')}</p>
                        <FilterContent
                            id={1}
                            emptyOnEnd={false}
                            onlyOne={false}
                            loadDataFn={() => apiGetFilters('business_company_name')}
                            filterOptions={{
                              id: 'id',
                              label: 'name'
                            }}
                            initData={props.filterData['business_company_name']}
                            onFilterChange={(data) => props.onChangeWithFilter(data, 'business_company_name')}
                            i18n={t}
                        >
                        <span className="el-table__column-filter-trigger" id={'Popover-' + 1}>
                            <i className={`fa fa-caret-square-down ${(props.filterData['business_company_name'] && props.filterData['business_company_name'].length) ? "filtered" : ""}`} />
                        </span>
                        </FilterContent>
                    </th>
                    <th className="freeze" style={{ backgroundColor: '#b4c6e7', left: certificateIssuances && certificateIssuances.length > 0 ? '212px' : '174px' }}><p style={{width: '150px'}}>{t('certificationIssuance.auditingNumber')}</p>
                        <FilterContent
                            id={2}
                            emptyOnEnd={false}
                            onlyOne={false}
                            loadDataFn={() => apiGetFilters('audit_number')}
                            filterOptions={{
                              id: 'id',
                              label: 'name'
                            }}
                            initData={props.filterData['audit_number']}
                            onFilterChange={(data) => props.onChangeWithFilter(data, 'audit_number')}
                            i18n={t}
                        >
                        <span className="el-table__column-filter-trigger" id={'Popover-' + 2}>
                            <i className={`fa fa-caret-square-down ${(props.filterData['audit_number'] && props.filterData['audit_number'].length) ? "filtered" : ""}`} />
                        </span>
                        </FilterContent>
                    </th>
                    <th className="freeze" style={{ backgroundColor: '#b4c6e7', left: certificateIssuances && certificateIssuances.length > 0 ? '386px' : '348px' }}><p style={{width: '150px'}}>{t('certificationIssuance.factoryName')}</p>
                        <FilterContent
                            id={3}
                            emptyOnEnd={false}
                            onlyOne={false}
                            loadDataFn={() => apiGetFilters('vendor_factory_name')}
                            filterOptions={{
                              id: 'id',
                              label: 'name'
                            }}
                            initData={props.filterData['vendor_factory_name']}
                            onFilterChange={(data) => props.onChangeWithFilter(data, 'vendor_factory_name')}
                            i18n={t}
                        >
                        <span className="el-table__column-filter-trigger" id={'Popover-' + 3}>
                            <i className={`fa fa-caret-square-down ${(props.filterData['vendor_factory_name'] && props.filterData['vendor_factory_name'].length) ? "filtered" : ""}`} />
                        </span>
                        </FilterContent>
                    </th>
                    <th className="freeze" style={{ backgroundColor: '#b4c6e7', left: certificateIssuances && certificateIssuances.length > 0 ? '560px' : '522px' }}><p style={{width: '150px'}}>{t('global.vendorCompany')}</p>
                        <FilterContent
                            id={16}
                            emptyOnEnd={false}
                            onlyOne={false}
                            loadDataFn={() => apiGetFilters('vendor_company_name')}
                            filterOptions={{
                              id: 'id',
                              label: 'name'
                            }}
                            initData={props.filterData['vendor_company_name']}
                            onFilterChange={(data) => props.onChangeWithFilter(data, 'vendor_company_name')}
                            i18n={t}
                        >
                        <span className="el-table__column-filter-trigger" id={'Popover-' + 16}>
                            <i className={`fa fa-caret-square-down ${(props.filterData['vendor_company_name'] && props.filterData['vendor_company_name'].length) ? "filtered" : ""}`} />
                        </span>
                        </FilterContent>
                    </th>
                    <th style={{ backgroundColor: '#b4c6e7' }}><p style={{width: '150px'}}>{t('certificationIssuance.certRecipientName')}</p>
                        <FilterContent
                            id={4}
                            emptyOnEnd={false}
                            onlyOne={false}
                            loadDataFn={() => apiGetFilters('cert_recipient_name')}
                            filterOptions={{
                              id: 'id',
                              label: 'name'
                            }}
                            initData={props.filterData['cert_recipient_name']}
                            onFilterChange={(data) => props.onChangeWithFilter(data, 'cert_recipient_name')}
                            i18n={t}
                        >
                        <span className="el-table__column-filter-trigger" id={'Popover-' + 4}>
                            <i className={`fa fa-caret-square-down ${(props.filterData['cert_recipient_name'] && props.filterData['cert_recipient_name'].length) ? "filtered" : ""}`} />
                        </span>
                        </FilterContent>
                    </th>
                    <th style={{ backgroundColor: '#b4c6e7' }}><p style={{width: '150px'}}>{t('certificationIssuance.certRecipientEmail')}</p>
                        <FilterContent
                            id={5}
                            emptyOnEnd={false}
                            onlyOne={false}
                            loadDataFn={() => apiGetFilters('cert_recipient_email')}
                            filterOptions={{
                              id: 'id',
                              label: 'name'
                            }}
                            initData={props.filterData['cert_recipient_email']}
                            onFilterChange={(data) => props.onChangeWithFilter(data, 'cert_recipient_email')}
                            i18n={t}
                        >
                        <span className="el-table__column-filter-trigger" id={'Popover-' + 5}>
                            <i className={`fa fa-caret-square-down ${(props.filterData['cert_recipient_email'] && props.filterData['cert_recipient_email'].length) ? "filtered" : ""}`} />
                        </span>
                        </FilterContent>
                    </th>
                    <th style={{ backgroundColor: '#b4c6e7' }}><p style={{width: '200px'}}>{t('certificationIssuance.certIssExpDate')}</p></th>
                    <th style={{ backgroundColor: '#b4c6e7' }}><p style={{width: '150px'}}>{t('certificationIssuance.certPeriod')}</p>
                        <FilterContent
                            id={7}
                            emptyOnEnd={false}
                            onlyOne={false}
                            loadDataFn={() => apiGetFilters('cert_period')}
                            filterOptions={{
                              id: 'id',
                              label: `name_${props.language}`
                            }}
                            initData={props.filterData['cert_period']}
                            onFilterChange={(data) => props.onChangeWithFilter(data, 'cert_period')}
                            i18n={t}
                        >
                        <span className="el-table__column-filter-trigger" id={'Popover-' + 7}>
                            <i className={`fa fa-caret-square-down ${(props.filterData['cert_period'] && props.filterData['cert_period'].length) ? "filtered" : ""}`} />
                        </span>
                        </FilterContent>
                    </th>
                    <th style={{ backgroundColor: '#b4c6e7' }}><p style={{width: '150px'}}>{t('certificationIssuance.authentication')}</p></th>
                    <th style={{ backgroundColor: '#b4c6e7' }}><p style={{width: '150px'}}>{t('certificationIssuance.scap')}</p></th>
                    <th style={{ backgroundColor: '#b4c6e7' }}><p style={{width: '150px'}}>{t('certificationIssuance.evaluation')}</p>
                        <FilterContent
                            id={8}
                            emptyOnEnd={false}
                            onlyOne={false}
                            loadDataFn={() => apiGetFilters('evaluation')}
                            filterOptions={{
                              id: 'id',
                              label: `name_${props.language}`
                            }}
                            initData={props.filterData['evaluation']}
                            onFilterChange={(data) => props.onChangeWithFilter(data, 'evaluation')}
                            i18n={t}
                        >
                        <span className="el-table__column-filter-trigger" id={'Popover-' + 8}>
                            <i className={`fa fa-caret-square-down ${(props.filterData['evaluation'] && props.filterData['evaluation'].length) ? "filtered" : ""}`} />
                        </span>
                        </FilterContent>
                    </th>
                    <th style={props.config && getStepIds(props.config, 'cert_draft_person').includes(props.user.id) ? { backgroundColor: '#4371c3', color: '#fff', textShadow: '0px 0px 2px #fff' } : { backgroundColor: '#b4c6e7', color: '#000' } }><p style={{width: '150px'}}>{t('certificationIssuance.createDraft')}</p>
                        <FilterContent
                            id={9}
                            emptyOnEnd={false}
                            withoutNull={true}
                            onlyOne={false}
                            loadDataFn={() => apiGetFilters('cert_draft')}
                            filterOptions={{
                              id: 'name',
                              label: `name_${props.language}`
                            }}
                            initData={props.filterData['cert_draft']}
                            onFilterChange={(data) => props.onChangeWithFilter(data, 'cert_draft')}
                            i18n={t}
                        >
                        <span className="el-table__column-filter-trigger" id={'Popover-' + 9}>
                            <i className={`fa fa-caret-square-down ${(props.filterData['cert_draft'] && props.filterData['cert_draft'].length) ? "filtered" : ""}`} />
                        </span>
                        </FilterContent>
                    </th>
                    <th style={props.config && getStepIds(props.config, 'signature_one_person').includes(props.user.id) ? { backgroundColor: '#4371c3', color: '#fff', textShadow: '0px 0px 2px #fff' } : { backgroundColor: '#b4c6e7', color: '#000' } }><p style={{width: '150px'}}>{t('certificationIssuance.signatureOne')}</p>
                        <FilterContent
                            id={10}
                            emptyOnEnd={false}
                            onlyOne={false}
                            loadDataFn={() => apiGetFilters('signature_1')}
                            filterOptions={{
                              id: 'name',
                              label: `name_${props.language}`
                            }}
                            initData={props.filterData['signature_1']}
                            onFilterChange={(data) => props.onChangeWithFilter(data, 'signature_1')}
                            i18n={t}
                        >
                        <span className="el-table__column-filter-trigger" id={'Popover-' + 10}>
                            <i className={`fa fa-caret-square-down ${(props.filterData['signature_1'] && props.filterData['signature_1'].length) ? "filtered" : ""}`} />
                        </span>
                        </FilterContent>
                    </th>
                    <th style={props.config && getStepIds(props.config, 'hand_in_draft_person').includes(props.user.id) ? { backgroundColor: '#4371c3', color: '#fff', textShadow: '0px 0px 2px #fff' } : { backgroundColor: '#b4c6e7', color: '#000' } }><p style={{width: '150px'}}>{t('certificationIssuance.handInDraft')}</p>
                        <FilterContent
                            id={11}
                            emptyOnEnd={false}
                            onlyOne={false}
                            loadDataFn={() => apiGetFilters('hand_in_draft')}
                            filterOptions={{
                              id: 'name',
                              label: `name_${props.language}`
                            }}
                            initData={props.filterData['hand_in_draft']}
                            onFilterChange={(data) => props.onChangeWithFilter(data, 'hand_in_draft')}
                            i18n={t}
                        >
                        <span className="el-table__column-filter-trigger" id={'Popover-' + 11}>
                            <i className={`fa fa-caret-square-down ${(props.filterData['hand_in_draft'] && props.filterData['hand_in_draft'].length) ? "filtered" : ""}`} />
                        </span>
                        </FilterContent>
                    </th>
                    <th style={props.config && getStepIds(props.config, 'request_signature_person').includes(props.user.id) ? { backgroundColor: '#4371c3', color: '#fff', textShadow: '0px 0px 2px #fff' } : { backgroundColor: '#b4c6e7', color: '#000' } }><p style={{width: '150px'}}>{t('certificationIssuance.requestSignature')}</p>
                        <FilterContent
                            id={12}
                            emptyOnEnd={false}
                            onlyOne={false}
                            loadDataFn={() => apiGetFilters('signature_2_request')}
                            filterOptions={{
                              id: 'name',
                              label: `name_${props.language}`
                            }}
                            initData={props.filterData['signature_2_request']}
                            onFilterChange={(data) => props.onChangeWithFilter(data, 'signature_2_request')}
                            i18n={t}
                        >
                        <span className="el-table__column-filter-trigger" id={'Popover-' + 12}>
                            <i className={`fa fa-caret-square-down ${(props.filterData['signature_2_request'] && props.filterData['signature_2_request'].length) ? "filtered" : ""}`} />
                        </span>
                        </FilterContent>
                    </th>
                    <th style={props.config && getStepIds(props.config, 'signature_two_person').includes(props.user.id) ? { backgroundColor: '#4371c3', color: '#fff', textShadow: '0px 0px 2px #fff' } : { backgroundColor: '#b4c6e7', color: '#000' } }><p style={{width: '150px'}}>{t('certificationIssuance.signatureTwo')}</p>
                        <FilterContent
                            id={13}
                            emptyOnEnd={false}
                            onlyOne={false}
                            loadDataFn={() => apiGetFilters('signature_2')}
                            filterOptions={{
                              id: 'name',
                              label: `name_${props.language}`
                            }}
                            initData={props.filterData['signature_2']}
                            onFilterChange={(data) => props.onChangeWithFilter(data, 'signature_2')}
                            i18n={t}
                        >
                        <span className="el-table__column-filter-trigger" id={'Popover-' + 13}>
                            <i className={`fa fa-caret-square-down ${(props.filterData['signature_2'] && props.filterData['signature_2'].length) ? "filtered" : ""}`} />
                        </span>
                        </FilterContent>
                    </th>
                    <th style={props.config && getStepIds(props.config, 'confirmation_send_person').includes(props.user.email) ? { backgroundColor: '#4371c3', color: '#fff', textShadow: '0px 0px 2px #fff' } : { backgroundColor: '#b4c6e7', color: '#000' } }><p style={{width: '150px'}}>{t('certificationIssuance.sendConfirmation')}</p>
                        <FilterContent
                            id={14}
                            emptyOnEnd={false}
                            onlyOne={false}
                            loadDataFn={() => apiGetFilters('confirmation')}
                            filterOptions={{
                              id: 'name',
                              label: `name_${props.language}`
                            }}
                            initData={props.filterData['confirmation']}
                            onFilterChange={(data) => props.onChangeWithFilter(data, 'confirmation')}
                            i18n={t}
                        >
                        <span className="el-table__column-filter-trigger" id={'Popover-' + 14}>
                            <i className={`fa fa-caret-square-down ${(props.filterData['confirmation'] && props.filterData['confirmation'].length) ? "filtered" : ""}`} />
                        </span>
                        </FilterContent>
                    </th>
                    <th style={{ backgroundColor: '#b4c6e7' }}><p style={{width: '150px'}}>{t('certificationIssuance.certReceipt')}</p>
                        <FilterContent
                            id={15}
                            emptyOnEnd={false}
                            onlyOne={false}
                            loadDataFn={() => apiGetFilters('cert_receipt')}
                            filterOptions={{
                              id: 'name',
                              label: `name_${props.language}`
                            }}
                            initData={props.filterData['cert_receipt']}
                            onFilterChange={(data) => props.onChangeWithFilter(data, 'cert_receipt')}
                            i18n={t}
                        >
                        <span className="el-table__column-filter-trigger" id={'Popover-' + 15}>
                            <i className={`fa fa-caret-square-down ${(props.filterData['cert_receipt'] && props.filterData['cert_receipt'].length) ? "filtered" : ""}`} />
                        </span>
                        </FilterContent>
                    </th>
                    <th style={{ backgroundColor: '#b4c6e7' }}><p style={{width: '250px'}}>Comments</p></th>
                    <th></th>
                </tr>
            </thead>
            {(certificateIssuances && certificateIssuances.length > 0 ) &&
            <tbody>
                {certificateIssuances && certificateIssuances.map((certIssuance, id) => (
                    <tr key={certIssuance.id} style={{ position: 'relative' }}>
                        <td style={{ position: 'sticky', left: 0, boxShadow: '0 2px 0 0 #fff', verticalAlign: 'middle', backgroundColor: '#fff', zIndex: 9 }}><div style={{ width: '14px' }}>{id+1}</div></td>
                        <td className="freeze" style={{width: '200px', left: '38px'}}>{certIssuance.factory_evaluation.business_company['code']}</td>
                        <td className="freeze" style={{ left: '212px' }}>{certIssuance.factory_evaluation['audit_number']}</td>
                        <td className="freeze" style={{ left: '386px' }}>
                            {permission('factory-evaluation:update') ?
                                <Link to={{ pathname: `/factory-evaluation/${certIssuance.factory_evaluation_id}` }} title={certIssuance.factory_evaluation.vendor_factory['name']}>
                                    <span>{certIssuance.factory_evaluation.vendor_factory['name']}</span>
                                </Link> : certIssuance.factory_evaluation.vendor_factory['name']
                            }
                        </td>
                        <td className="freeze" style={{ left: '560px' }}>{certIssuance.factory_evaluation.vendor_company['name']}</td>
                        <td>{certIssuance.contact_name}</td>
                        <td>
                            {permission('certificate:update') ?
                                <UpdateContactPopup
                                    id={certIssuance.id}
                                    evalId={certIssuance.factory_evaluation_id}
                                    refresh={props.refresh}
                                    t={t}
                                >
                                <span style={{color: '#5d9cec', cursor: 'pointer'}} id={'contactPopover-' + certIssuance.id}>
                                    {certIssuance.contact}
                                </span>
                                </UpdateContactPopup> :
                                <span style={{color: '#5d9cec'}} id={'contactPopover-' + certIssuance.id}>
                                    {certIssuance.contact}
                                </span>
                            }
                        </td>
                        <td>{certIssuance.factory_evaluation['effective_date']} - {certIssuance.factory_evaluation['expiration_date']}</td>
                        <td>
                            <Select
                                value={certIssuance.factory_evaluation['certification_issuance_id']}
                                options={props.certPeriodOpts}
                                label={`name_${props.language}`}
                                isDisabled={true}
                                style={{minWidth: '150px'}}
                            />
                        </td>
                        <td onClick={certIssuance.certificate_file  ? (e) => props.openPreview(e, certIssuance, false) : null}>
                            <p style={{ color: certIssuance.certificate_file ? '#5d9cec' : '#000'}}>認証</p>
                        </td>
                        <td onClick={props.getScapFile(certIssuance) !== undefined ? () => props.openScapPreview(certIssuance) : null}>
                            <p style={{ color: props.getScapFile(certIssuance) !== undefined ? '#5d9cec' : '#000'}}>SCAP</p>
                        </td>
                        <td>
                            <Select
                                value={certIssuance.factory_evaluation['latest_evaluation_score_id']}
                                options={props.evalScoreOpts}
                                label={`name_${props.language}`}
                                isDisabled={true}
                            />
                        </td>
                        {permission('certificate:update') && props.config && getStepIds(props.config, 'cert_draft_person').includes(props.user.id) ?
                        <CertificateIssuanceCell
                            isDraft={true}
                            openDraft={props.openDraft}
                            status={certIssuance.cert_draft_status}
                            column={certIssuance.cert_draft}
                            activeMsg="実行する"
                            awaitingMsg="実行済み"
                            preview={props.openPreview}
                            toggle={props.addToAwaitingCertificates}
                            isChecked={props.isChecked}
                            certIssuance={certIssuance}
                            step='cert_draft'
                            assignee={certIssuance.cert_draft_assignee ? certIssuance.cert_draft_assignee : ''}
                        /> :
                        <td style={{ backgroundColor: certIssuance.cert_draft ? '#ccc' : '#fff'}}>{certIssuance.cert_draft_status === 1 ? '実行する' : certIssuance.cert_draft_status === 2 ? '実行済み' : certIssuance.cert_draft_status === 3 ? `${certIssuance.cert_draft} ${certIssuance.cert_draft_assignee ? certIssuance.cert_draft_assignee : ''}` : ''}</td>
                        }
                        {permission('certificate:update') && props.config && (getStepIds(props.config, 'signature_one_person').includes(props.user.id) || getStepIds(props.config, 'cert_draft_person').includes(props.user.id)) ?
                        <CertificateIssuanceCell
                            isDraft={false}
                            isSignature={true}
                            openDraft={props.openDraft}
                            status={certIssuance.signature_1_status}
                            column={certIssuance.signature_1}
                            activeMsg="署名する"
                            awaitingMsg="署名済み"
                            preview={props.openPreview}
                            toggle={props.addToAwaitingCertificates}
                            isChecked={props.isChecked}
                            certIssuance={certIssuance}
                            step='signature_1'
                            assignee={certIssuance.signature_1_assignee ? certIssuance.signature_1_assignee : ''}
                            isPullback={getStepIds(props.config, 'cert_draft_person').includes(props.user.id)}
                        /> :
                        <td style={{ backgroundColor: certIssuance.signature_1 ? '#ccc' : '#fff'}}>{certIssuance.signature_1_status === 1 ? '署名する' : certIssuance.signature_1_status === 2 ? '署名済み' : certIssuance.signature_1_status === 3 ? `${certIssuance.signature_1} ${certIssuance.signature_1_assignee ? certIssuance.signature_1_assignee : ''}` : ''}</td>
                        }
                        {permission('certificate:update') && props.config && (getStepIds(props.config, 'hand_in_draft_person').includes(props.user.id) || getStepIds(props.config, 'signature_one_person').includes(props.user.id)) ?
                        <CertificateIssuanceCell
                            isDraft={false}
                            openDraft={props.openDraft}
                            status={certIssuance.hand_in_draft_status}
                            column={certIssuance.hand_in_draft}
                            activeMsg="実行する"
                            awaitingMsg="実行済み"
                            preview={props.openPreview}
                            toggle={props.addToAwaitingCertificates}
                            isChecked={props.isChecked}
                            certIssuance={certIssuance}
                            step='hand_in_draft'
                            assignee={certIssuance.hand_in_draft_assignee ? certIssuance.hand_in_draft_assignee : ''}
                        /> :
                        <td style={{ backgroundColor: certIssuance.hand_in_draft ? '#ccc' : '#fff'}}>{certIssuance.hand_in_draft_status === 1 ? '実行する' : certIssuance.hand_in_draft_status === 2 ? '実行済み' : certIssuance.hand_in_draft_status === 3 ? `${certIssuance.hand_in_draft} ${certIssuance.hand_in_draft_assignee ? certIssuance.hand_in_draft_assignee : ''}` : ''}</td>
                        }
                        {permission('certificate:update') && props.config && (getStepIds(props.config, 'request_signature_person').includes(props.user.id) || getStepIds(props.config, 'hand_in_draft_person').includes(props.user.id)) ?
                        <CertificateIssuanceCell
                            isDraft={false}
                            openDraft={props.openDraft}
                            status={certIssuance.request_signature_status}
                            column={certIssuance.signature_2_request}
                            activeMsg="実行する"
                            awaitingMsg="実行済み"
                            preview={props.openPreview}
                            toggle={props.addToAwaitingCertificates}
                            isChecked={props.isChecked}
                            certIssuance={certIssuance}
                            step='signature_2_request'
                            assignee={certIssuance.request_signature_assignee ? certIssuance.request_signature_assignee : ''}
                            isPullback={getStepIds(props.config, 'hand_in_draft_person').includes(props.user.id)}
                        /> :
                        <td style={{ backgroundColor: certIssuance.signature_2_request ? '#ccc' : '#fff'}}>{certIssuance.request_signature_status === 1 ? '実行する' : certIssuance.request_signature_status === 2 ? '実行済み' : certIssuance.request_signature_status === 3 ? `${certIssuance.signature_2_request} ${certIssuance.request_signature_assignee ? certIssuance.request_signature_assignee : ''}` : ''}</td>
                        }
                        {permission('certificate:update') && props.config && (getStepIds(props.config, 'signature_two_person').includes(props.user.id) || getStepIds(props.config, 'request_signature_person').includes(props.user.id)) ?
                        <CertificateIssuanceCell
                            isDraft={false}
                            isSignature={true}
                            openDraft={props.openDraft}
                            status={certIssuance.signature_2_status}
                            column={certIssuance.signature_2}
                            activeMsg="署名する"
                            awaitingMsg="署名済み"
                            preview={props.openPreview}
                            toggle={props.addToAwaitingCertificates}
                            isChecked={props.isChecked}
                            certIssuance={certIssuance}
                            step='signature_2'
                            assignee={certIssuance.signature_2_assignee ? certIssuance.signature_2_assignee : ''}
                            isPullback={getStepIds(props.config, 'request_signature_person').includes(props.user.id)}
                        /> :
                        <td style={{ backgroundColor: certIssuance.signature_2 ? '#ccc' : '#fff'}}>{certIssuance.signature_2_status === 1 ? '署名する' : certIssuance.signature_2_status === 2 ? '署名済み' : certIssuance.signature_2_status === 3 ? `${certIssuance.signature_2} ${certIssuance.signature_2_assignee ? certIssuance.signature_2_assignee : ''}` : ''}</td>
                        }
                        {permission('certificate:update') && props.config && getStepIds(props.config, 'confirmation_send_person').includes(props.user.id) ?
                        <CertificateIssuanceCell
                            isDraft={false}
                            isSignature={false}
                            openDraft={props.openDraft}
                            status={!certIssuance.signature_2 ? null : certIssuance.confirmation ? 3 : 1}
                            column={certIssuance.confirmation}
                            activeMsg="実行する"
                            awaitingMsg=""
                            preview={props.openPreview}
                            toggle={props.addToAwaitingCertificates}
                            isChecked={props.isChecked}
                            certIssuance={certIssuance}
                            step='confirmation'
                            assignee={certIssuance.confirmation_assignee ? certIssuance.confirmation_assignee : ''}
                        /> :
                        <td style={{ backgroundColor: certIssuance.confirmation ? '#ccc' : '#fff'}}>{certIssuance.signature_2 && !certIssuance.confirmation ? '実行する' : certIssuance.signature_2 && certIssuance.confirmation ? `${certIssuance.confirmation} ${certIssuance.confirmation_assignee ? certIssuance.confirmation_assignee : ''}` : ''}</td>
                        }
                        {permission('certificate:update') && props.config && getStepIds(props.config, 'confirmation_send_person').includes(props.user.id) ?
                        <CertificateIssuanceCell
                            isDraft={false}
                            isSignature={false}
                            openDraft={props.openDraft}
                            status={certIssuance.confirmation && !certIssuance.cert_receipt ? 1 : certIssuance.confirmation && certIssuance.cert_receipt ? 3 : null}
                            column={certIssuance.cert_receipt}
                            activeMsg="ダウンロード待ち"
                            awaitingMsg=""
                            preview={props.openPreview}
                            toggle={props.addToAwaitingCertificates}
                            isChecked={props.isChecked}
                            certIssuance={certIssuance}
                            step='cert_receipt'
                        /> :
                        <td
                            style={{
                                backgroundColor: certIssuance.confirmation && !certIssuance.cert_receipt ? '#99ff99' : certIssuance.confirmation && certIssuance.cert_receipt ? '#ccc' : '#fff',
                            }}>
                            {certIssuance.confirmation && !certIssuance.cert_receipt ? 'ダウンロード待ち' : certIssuance.confirmation && certIssuance.cert_receipt ? 'ダウンロード済み' : ''}
                        </td>
                        }
                        <td
                            style={{
                                cursor: 'default',
                                backgroundColor: '#fff',
                            }}>
                            {certIssuance.comments && ReactHtmlParser(certIssuance.comments)}
                        </td>
                        <td>
                        <Button
                            onClick={() => certIssuance.confirmation && props.config && getStepIds(props.config, 'confirmation_send_person').includes(props.user.id) ?
                                props.toggleResendDialog(certIssuance.id, certIssuance.contact, true) :
                                {}}
                            disabled={!certIssuance.cert_receipt && certIssuance.confirmation && props.config && getStepIds(props.config, 'confirmation_send_person').includes(props.user.id) ? false : true}>
                            {t('certificationIssuance.resend')}
                        </Button>
                        </td>
                    </tr>
                ))
                }
                {!certificateIssuances.length &&
                    <tr>
                        <td colSpan="16" align="center">
                            {t('global.notMatchingRecordsFound')}
                        </td>
                    </tr>
                }
            </tbody>
            }
        </table>
        </div>
        {props.resend && props.config && getStepIds(props.config, 'confirmation_send_person').includes(props.user.id) &&
            <Dialog customClass="resend-confirmation" visible={props.resend.visible} title={t('certificationIssuance.resendConfirmation')} onCancel={() => props.toggleResendDialog(null, null, false)}>
                <div>
                    <p>{t('certificationIssuance.resendContent', {email: props.resend.contact})}</p>
                </div>
                <Button onClick={() => props.resendCertificate(props.resend.id)}>{props.resend.loading ? <em className="fa fa-circle-notch fa-spin"></em> : t('global.yes')}</Button>
            </Dialog>
        }
        </>
    )
}

export default withNamespaces()(CertificateIssuanceList);

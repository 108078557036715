import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Button, Layout } from 'element-react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Input, Form, AsyncSelect, Contacts } from '../Common';
import { validateLength, likeFilter } from '../../utils/utility';
import { apiSearchCountry } from '../../api/remote-search';

export class BusinessCompanyForm extends Component {
    state = {
        rules: {
            code: [
                { required: true, message: this.props.t('businessCompany.pleaseInputCode'), trigger: 'change' },
                { validator: (_, value, callback) => validateLength(value, callback, 3, this.props.t('businessCompany.validMaxCodeLength')) }
            ],
            name: [
                { required: true, message: this.props.t('businessCompany.pleaseInputName'), trigger: 'change' },
                { validator: (_, value, callback) => validateLength(value, callback, 40, this.props.t('businessCompany.validMaxNameLength')) }
            ]
        },
        searching: false
    }

    componentDidUpdate(prevProps) {
        if (this.props.visible && !prevProps.visible) {
            const countries = this.props.data.country ? [this.props.data.country] : [];
            this.setState({ countries });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.refs.form.validate((valid) => {
            if (valid) {
                this.props.onSubmit();
            }
        });
    }

    onSearch = async (query, searchFn) => {
        return new Promise(async (resolve) => {
            const { data } = await searchFn({ ...likeFilter('name', query) });
            resolve(data);
        });
    }

    render() {
        const { t, data } = this.props;
        if (!data) return null;

        return (
            <Modal backdrop="static" size="lg" isOpen={this.props.visible} toggle={this.props.onCancel}>
                <Form onSubmit={this.handleSubmit} model={data} ref="form" rules={this.state.rules} labelWidth="160">
                    <ModalHeader toggle={this.props.onCancel}>
	                    {data.id ? t('businessCompany.edit') : t('businessCompany.add')}
                    </ModalHeader>
                    <ModalBody className="scrollable-modal">
                        <Layout.Row>
                            <Layout.Col span="22">
                                <Form.Item label={t('global.code')} prop="code">
                                    <Input
                                        value={data.code}
                                        onChange={(value) => this.props.onChangeHandler(value, 'code')}
                                    />
                                </Form.Item>
                                <Form.Item label={t('global.name')} prop="name">
                                    <Input
                                        value={data.name}
                                        onChange={(value) => this.props.onChangeHandler(value, 'name')}
                                    />
                                </Form.Item>
                                <Layout.Row>
                                    <Layout.Col md="24">
                                        <Form.Item label={t('global.address')} prop="address">
                                            <Input
                                                value={data.address}
                                                onChange={(value) => this.props.onChangeHandler(value, 'address')}
                                            />
                                        </Form.Item>
                                    </Layout.Col>
                                </Layout.Row>
                                <Layout.Row>
                                    <Layout.Col md="24">
                                        <Form.Item label={t('company.country')}>
                                            <AsyncSelect
                                                selected={data.country}
                                                value={data.country_id}
                                                remoteMethod={(value) => this.onSearch(value, apiSearchCountry)}
                                                onChange={(value) => this.props.onChangeHandler(value, 'country_id')}
                                                subLabel="code"
                                            />
                                        </Form.Item>
                                    </Layout.Col>
                                </Layout.Row>
	                            <Contacts
		                            contacts={data.contacts}
		                            onAdd={this.props.onAddContact}
		                            onRemove={this.props.onRemoveContact}
		                            onChange={this.props.onChangeContactHandler}
	                            />
                            </Layout.Col>
                        </Layout.Row>
                    </ModalBody>
                    <ModalFooter>
                        <Form.Item>
                            <Button onClick={this.props.onCancel}>
                                {t('global.cancel')}
                            </Button>
                            <Button type="primary" nativeType="submit">
                                {t('global.submit')}
                            </Button>
                        </Form.Item>
                    </ModalFooter>
                </Form>
            </Modal>
        );
    }
}

export default withNamespaces()(BusinessCompanyForm);

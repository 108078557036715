import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button } from 'element-react';
import { withNamespaces } from 'react-i18next';
import { Form } from '../Common';
import {apiSavePreference } from '../../api/preferences.js';

class ConfigOrderModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            type: 'common',
            order: null
        }
    }
    componentDidMount() {
        this.initData();
    }

    initData = async () => {
        const  preferences  = this.props.data
        let order = preferences.filter(item => ((item.checked === true || item.checked === 1) && !item.skip))
        this.setState({ data: preferences, order: order })
    }

    convertData = () => {
        let newData = [];
        this.state.data.map((item, idx) => {
            if(!item.skip) {
                return newData[idx] = {id: item.id, is_visible: item.checked}
            } else {
                return newData[idx] = {id: item.id, is_visible: !item.skip}
            }
        })
        return newData
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        let newData = this.convertData();
        await apiSavePreference(newData, `evaluation_listing`);
        this.props.close()
        window.location.reload();
    }

// ------------------ To fix -------------------------------------------
    moveUp = (e, item) => {
            let current = this.state.order.indexOf(item)
            let previous = this.state.order[current-1]
            let curr = this.state.data.indexOf(item)
            let prev = this.state.data.indexOf(previous)
            let data = this.state.data
            let order = this.state.order
            // Change original array
            if (data[prev]) {
                data[curr] = data[prev]
                data[prev] = item
            }
            // Change filtered array to see results immiediately
            if (order[current-1]) {
                order[current] = order[current-1]
                order[current-1] = item
            }
            this.setState({data: data, order: order})
    }
    moveDown = (e, item) => {
            let current = this.state.order.indexOf(item)
            let next = this.state.order[current+1]
            let curr = this.state.data.indexOf(item)
            let nxt = this.state.data.indexOf(next)
            let data = this.state.data
            let order = this.state.order
            // Change original array
            if (data[nxt]) {
                data[curr] = data[nxt]
                data[nxt] = item
            }
            // Change filtered array to see results immiediately
            if (order[current+1]) {
                order[current] = order[current+1]
                order[current+1] = item
            }
            this.setState({data: data, order: order})
    }
// -----------------------------------------------------------------------

    onSectionSelect = (section) => {
        this.setState({ type: section });
    }

    render() {
        const { t, close } = this.props;
        return (
            <React.Fragment>
                <Modal backdrop="static" size="xl" isOpen={this.props.nextStep}>
                    <Form onSubmit={this.handleSubmit} ref="form" labelWidth="150">
                        <ModalHeader>
                            {t('configuration.columnsOrderTitle')}
                            <div style={{ position: 'absolute', top: '20px', right: '20px'}}>
                            <Button onClick={() => this.onSectionSelect('common')}>{t('configuration.common')}</Button>
                            <Button onClick={() => this.onSectionSelect('csr')}>{t('configuration.csr')}</Button>
                            <Button onClick={() => this.onSectionSelect('factory-examination')}>{t('configuration.factoryExam')}</Button>
                            <Button onClick={() => this.onSectionSelect('mfg-process')}>{t('configuration.mfgProcess')}</Button>
                            </div>
                        </ModalHeader>
                        <ModalBody className="user-container scrollable-modal">
                            {this.state.data ?
                                <table style={{width: '100%'}}>
                                    <tbody className='config-body config-order'>
                                    {this.state.order.map((item, value) => (
                                        item && item.id && item.type === this.state.type &&
                                        <React.Fragment key={value}>
                                        <tr className="config-row">
                                            <td className="config-data-num">{item.Number}</td>
                                            <td className="config-data-name">{item.Header}</td>
                                            <td className='arrow-container'>
                                                <div className='arrows arrow-up' onClick={(e) => this.moveUp(e,item)}></div>
                                            </td>
                                            <td className='arrow-container'>
                                                <div className='arrows arrow-down' onClick={(e) => this.moveDown(e,item)}></div>
                                            </td>
                                        </tr>
                                        </React.Fragment>
                                        ))
                                    }
                                    </tbody>
                                </table>
                                :
                                <p  style={{textAlign: 'center'}}><i className="el-icon-loading"/></p>
                            }
                        </ModalBody>
                        <ModalFooter>
                        <Button onClick={() => close()}>
                            {t('global.cancel')}
                        </Button>
                        <Button onClick={this.props.goBack}>
                            {t('configuration.previousScreen')}
                        </Button>
                        <Button type="primary" nativeType="submit">
                            {t('global.submit')}
                        </Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            </React.Fragment>
        )
    }
}

export default withNamespaces()(ConfigOrderModal);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter, Link } from 'react-router-dom';
import { withNamespaces, Trans } from 'react-i18next';
import compose from 'recompose/compose'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import localeEN from 'element-react/src/locale/lang/en';
import localeJA from 'element-react/src/locale/lang/ja';
import { i18n } from 'element-react';
import permission from '../../utils/permission';
import {
    Nav,
    NavItem,
    Dropdown,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    UncontrolledDropdown,
    ListGroup,
    ListGroupItem,
    Navbar,
    Collapse,
    NavbarToggler
} from 'reactstrap';

import * as actions from '../../store/actions/actions';
import { filePath } from '../../utils/variables';
import ToggleFullscreen from '../Common/ToggleFullscreen';
import Logo from '../Common/Logo';
import HeaderRun from './Header.run';


/** Normal items for the sidebar */
const SidebarItem = ({ item, isActive }) => (
    <NavItem>
        <Link className={`nav-link ${isActive ? 'active' : ''}`} to={{ pathname: item.path, state: { section: item.section ? item.section : null } }} title={item.name}>
            <span><Trans i18nKey={item.translate}>{item.name}</Trans></span>
        </Link>
    </NavItem>
)

const SidebarListingItem = ({ item, goTo }) => (
    <NavItem>
        {window.location.pathname !== item.path ?
        <Link className={`nav-link`} to={{ pathname: item.path, state: { section: item.section ? item.section : null } }} title={item.name}>
            <span><Trans i18nKey={item.translate}>{item.name}</Trans></span>
        </Link> :
        <span style={{ cursor: 'pointer' }} onClick={goTo} className={`nav-link`}> <Trans i18nKey={item.translate}>{item.name}</Trans></span>}
    </NavItem>
)

/** Build a sub menu with items inside and attach collapse behavior */
const SidebarSubItem = ({ item, isActive, handler, children, isOpen }) => (
    <Dropdown nav isOpen={isOpen} toggle={handler} className={`${isActive ? 'active' : ''}`}>
        <DropdownToggle nav caret>
            <Trans i18nKey={item.translate}>{item.name}</Trans>
        </DropdownToggle>
        <DropdownMenu>
            {children}
        </DropdownMenu>
    </Dropdown>
)


class Header extends Component {
    closeNavbar;
    state = {
        redirect: false,
        dropdownOpen: false,
        collapse: {},
        navbarCollapse: true
    }
    componentDidMount() {
        HeaderRun();
        this.props.fetchContext()
    }

    changeLanguageHandler = (e, lng) => {
        e.preventDefault();
        localStorage.setItem('7andi-csr-lng', lng);
        this.props.i18n.changeLanguage(lng);
        this.props.changeLanguage(lng);
        if (lng === 'jp') {
            i18n.use(localeJA);
        } else {
            i18n.use(localeEN);
        }
    }

    signOutHandler = (e) => {
        e.preventDefault();
        this.props.signOut();
        this.setState({ redirect: true });
    }

    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to='/login' />
        }
    }

    /** prepare initial state of collapse menus. Doesnt allow same route names */
    buildCollapseList = () => {
        let collapse = {};
        this.props.accessedRoutes
            .filter(({ heading }) => !heading)
            .forEach(({ name, path, submenu }) => {
                collapse[name] = this.routeActive(submenu ? submenu.map(({ path }) => path) : path)
            })
        this.setState({ collapse });
    }

    toggleUserblock = e => {
        e.preventDefault();
        this.props.actions.toggleSetting('showUserBlock');
    }

    toggleOffsidebar = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('offsidebarOpen');
    }

    toggleCollapsed = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('isCollapsed');
        this.resize()
    }

    toggleAside = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('asideToggled');
    }

    resize = () => {
        // all IE friendly dispatchEvent
        var evt = document.createEvent('UIEvents');
        evt.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(evt);
        // modern dispatchEvent way
        // window.dispatchEvent(new Event('resize'));
    }

    improveSectionMargin = async() => {
        let nav = await document.querySelector('nav');
        let section = await document.querySelector('section');
        if (section && nav) {
            section.style.marginTop = nav.offsetHeight + 'px';
        }
    }

    goToSection = async (mainLocation, section) => {
      if (window.location.pathname !== mainLocation) {
          await this.routeChange(mainLocation);
      }
      this.props.location.state = { section: section }
      let grid = document.getElementsByClassName('scroll-grid')[0];
      let item = section === 'qc' ? document.getElementsByClassName(`factory-examination-item`)[0] : document.getElementsByClassName(`csr-item`)[0];
      item && grid.scroll(item.offsetLeft - 150, 0);
    }

    toggleItemCollapse(stateName) {
        for (let c in this.state.collapse) {
            if (this.state.collapse[c] === true && c !== stateName)
                this.setState({
                    collapse: {
                        [c]: false
                    }
                });
        }
        this.setState({
            collapse: {
                [stateName]: !this.state.collapse[stateName]
            }
        });
    }

    getSubRoutes = item => item.submenu.map(({ path }) => path)

    routeActive(paths) {
        paths = Array.isArray(paths) ? paths : [paths];
        return paths.some(p => this.props.location.pathname.indexOf(p) > -1)
    }

    formActive(section, path) {
        return this.props.location.pathname === path && this.props.location.state && this.props.location.state.section === section
    }

    /** map menu config to string to determine which element to render */
    itemType = item => {
        if (item.heading) return 'heading';
        if (!item.submenu) return 'menu';
        if (item.submenu) return 'submenu';
    }

    resize() {
        // all IE friendly dispatchEvent
        var evt = document.createEvent('UIEvents');
        evt.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(evt);
        // modern dispatchEvent way
        // window.dispatchEvent(new Event('resize'));
    }

    routeChange = (path,view) => {
        this.props.history.push({
            pathname: path,
            state: { view: view }
      });
    }

    toggleNavbar = () => {
        const leftNav = document.getElementsByClassName('navbar-collapse')[0];
        const rightNav = document.getElementsByClassName('right-nav')[0];
        this.setState({ navbarCollapse: !this.state.navbarCollapse })
        if (!this.state.navbarCollapse && leftNav && rightNav) {
            this.closeNavbar = setTimeout(() => {
                leftNav.style.order = 2;
                rightNav.style.order = 3;
            }, 400);
        }
        if (this.state.navbarCollapse && leftNav && rightNav) {
            clearTimeout(this.closeNavbar)
            leftNav.style.order = 3;
            rightNav.style.order = 2;
        }
    }

    isAdditionalMenuVisible = () => {
        return permission('activity:view') || permission('user:view') || permission('role:view') ? true : false;
    }

    render() {
        const { t, user, accessedRoutes } = this.props;
        return (
            <header className="topnavbar-wrapper">
                {this.renderRedirect()}
                { /* START Top Navbar */}
                <Navbar light className="navbar topnavbar" style={{background: this.props.navBgColor}}>
                    { /* START navbar header */}
                    <div className="navbar-header">
                        <a className="navbar-brand" href="#/" onClick={(e) => e.preventDefault()}>
                            <div className="brand-logo">
                                <Logo height={40} path={'img/logo_nav.png'}/>
                            </div>
                            <div className="brand-logo-collapsed">
                                <Logo height={40} path={'img/logo_nav.png'}/>
                            </div>
                        </a>
                    </div>
                    { /* END navbar header */}

                    { /* START Left navbar */}
                    <Nav pills className="left-nav mr-auto">
                        {
                            accessedRoutes.map((item, i) => {
                                if(!item.additional) {
                                if (this.itemType(item) === 'menu') {
                                    if (item.path === '/factory-evaluation') {
                                      return (
                                          !item.hidden && <SidebarItem isActive={this.formActive(item.section, item.path)} item={item} key={i} />
                                      )
                                    }
                                    if (item.path === '/evaluation-list') {
                                      return (
                                          !item.hidden && <SidebarListingItem item={item} goTo={() => this.goToSection(item.path, item.section)} key={i} />
                                      )
                                    }
                                    return (
                                        !item.hidden && <SidebarItem isActive={this.routeActive(item.path)} item={item} key={i} />
                                    )
                                }
                                if (this.itemType(item) === 'submenu' && item.submenu.findIndex(i => !i.hidden) !== -1)
                                    return [
                                        <SidebarSubItem item={item} isOpen={this.state.collapse[item.name]} handler={() => this.toggleItemCollapse(item.name)} isActive={this.routeActive(this.getSubRoutes(item))} key={i}>
                                            {/* <SidebarSubHeader item={item} key={i} /> */}
                                            {
                                                item.submenu.map((subitem, i) =>
                                                    !subitem.hidden && subitem.component &&
                                                    <DropdownItem key={i} onClick={() => this.routeChange(subitem.path)}>
                                                        <Link className={`nav-link ${this.routeActive(subitem.path) ? 'active' : ''}`} to={subitem.path} title={subitem.name}>
                                                            <span><Trans i18nKey={subitem.translate}>{subitem.name}</Trans></span>
                                                        </Link>
                                                    </DropdownItem>
                                                )
                                            }
                                        </SidebarSubItem>
                                    ]
                                }
                                return null;
                            })
                        }
                    </Nav>
                    { /* END Left navbar */}
                    { /* START additional menu */}
                    {this.isAdditionalMenuVisible() &&
                    <Collapse isOpen={!this.state.navbarCollapse} navbar>
                        <Nav navbar expand='xl' pills className="left-nav mr-auto">
                            {
                                accessedRoutes.map((item, i) => {
                                    if(item.additional) {
                                    if (this.itemType(item) === 'menu') {
                                        if (item.path === '/factory-evaluation') {
                                          return (
                                              !item.hidden && <SidebarItem isActive={this.formActive(item.section, item.path)} item={item} key={i} />
                                          )
                                        }
                                        if (item.path === '/evaluation-list') {
                                          return (
                                              !item.hidden && <SidebarListingItem item={item} goTo={() => this.goToSection(item.path, item.section)} key={i} />
                                          )
                                        }
                                        return (
                                            !item.hidden && <SidebarItem isActive={this.routeActive(item.path)} item={item} key={i} />
                                        )
                                    }
                                    if (this.itemType(item) === 'submenu' && item.submenu.findIndex(i => !i.hidden) !== -1)
                                        return [
                                            <SidebarSubItem item={item} isOpen={this.state.collapse[item.name]} handler={() => this.toggleItemCollapse(item.name)} isActive={this.routeActive(this.getSubRoutes(item))} key={i}>
                                                {/* <SidebarSubHeader item={item} key={i} /> */}
                                                {
                                                    item.submenu.map((subitem, i) =>
                                                        !subitem.hidden && subitem.component &&
                                                        <DropdownItem key={i} onClick={() => this.routeChange(subitem.path)}>
                                                            <Link className={`nav-link ${this.routeActive(subitem.path) ? 'active' : ''}`} to={subitem.path} title={subitem.name}>
                                                                <span><Trans i18nKey={subitem.translate}>{subitem.name}</Trans></span>
                                                            </Link>
                                                        </DropdownItem>
                                                    )
                                                }
                                            </SidebarSubItem>
                                        ]
                                    }
	                                return null;
                                })
                            }
                        </Nav>
                    </Collapse>
                    }
                    { /* END additional menu */}
                    { /* START Right Navbar */}
                    <ul className="navbar-nav flex-row right-nav">
                        { /* Fullscreen (only desktops) */}
                    {this.isAdditionalMenuVisible() && <NavbarToggler onClick={this.toggleNavbar} className='custom-toggler'/>}
                        <li className="nav-item d-none d-md-block">
                            <ToggleFullscreen className="nav-link" />
                        </li>
                        { /* END Alert menu */}
                        <UncontrolledDropdown nav inNavbar className="dropdown-list">
                            <DropdownToggle nav className="dropdown-toggle-nocaret">
                                {this.props.language === 'jp' ?
                                    <img className="flag" src="img/flag/japan.png" alt="japan" />
                                    :
                                    <img className="flag" src="img/flag/en.png" alt="en" />
                                }
                            </DropdownToggle>
                            { /* START Dropdown menu */}
                            <DropdownMenu right className="dropdown-menu-right animated flipInX">
                                <DropdownItem tag="a">
                                    { /* START list group */}
                                    <ListGroup>
                                        <ListGroupItem action tag="button" href="" onClick={(e) => this.changeLanguageHandler(e, 'en')}>
                                            <div className="media">
                                                <div className="align-self-start mr-2">
                                                    <img className="flag" src="img/flag/en.png" alt="en" />
                                                </div>
                                                <div className="media-body">
                                                    <p className="m-0">English</p>
                                                </div>
                                            </div>
                                        </ListGroupItem>
                                        <ListGroupItem action tag="button" href="" onClick={(e) => this.changeLanguageHandler(e, 'jp')}>
                                            <div className="media">
                                                <div className="align-self-start mr-2">
                                                    <img className="flag japan" src="img/flag/japan.png" alt="japan" />
                                                </div>
                                                <div className="media-body">
                                                    <p className="m-0">日本語</p>
                                                </div>
                                            </div>
                                        </ListGroupItem>
                                    </ListGroup>
                                    { /* END list group */}
                                </DropdownItem>
                            </DropdownMenu>
                            { /* END Dropdown menu */}
                        </UncontrolledDropdown>
                        { /* END Offsidebar menu */}
                        { /* START Alert menu */}
                        <UncontrolledDropdown nav inNavbar className="dropdown-list">
                            <DropdownToggle nav className="dropdown-toggle-nocaret avatar-toggle">
                                {/* <em className="icon-user"></em> */}
                                {user.image ?
                                    <div className="avatar-wrapper">
                                        <img src={`${filePath}/${user.image}`} className="user-avatar" alt="" />
                                    </div>
                                    :
                                    <div className="avatar-wrapper">
                                        <em className="fa fa-user-circle" />
                                    </div>
                                }
                            </DropdownToggle>
                            { /* START Dropdown menu */}
                            <DropdownMenu right className="dropdown-menu-right animated flipInX">
                                <DropdownItem tag="a">
                                    { /* START list group */}
                                    <ListGroup>
                                        <ListGroupItem action tag="button" onClick={() => this.routeChange('/profile')}>
                                            <span className="d-flex align-items-center">
                                                <span className="text-sm">{t('topbar.profile')}</span>
                                            </span>
                                        </ListGroupItem>
                                        <ListGroupItem action tag="button" onClick={this.signOutHandler}>
                                            <span className="d-flex align-items-center">
                                                <span className="text-sm">{t('topbar.signOut')}</span>
                                            </span>
                                        </ListGroupItem>
                                    </ListGroup>
                                    { /* END list group */}
                                </DropdownItem>
                            </DropdownMenu>
                            { /* END Dropdown menu */}
                        </UncontrolledDropdown>
                    </ul>
                    { /* END Right Navbar */}

                    { /* START Search form */}
                    <form className="navbar-form" role="search" action="search.html">
                        <div className="form-group">
                            <input className="form-control" type="text" placeholder="Type and hit enter ..." />
                            <div className="fa fa-times navbar-form-close" data-search-dismiss="" />
                        </div>
                        <button className="d-none" type="submit">Submit</button>
                    </form>
                    { /* END Search form */}
                </Navbar>
                { /* END Top Navbar */}
            </header>
        );
    }
}

Header.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object
};

const mapStateToProps = state => ({
    settings: state.settings,
    language: state.settings.language,
    token: state.auth.access_token,
    accessedRoutes: state.auth.accessedRoutes,
    user: state.auth.user,
    navBgColor: state.context.color,
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
    fetchContext: () => dispatch(actions.fetchContext()),
    signOut: () => dispatch(actions.signOut()),
    changeLanguage: (lng) => dispatch(actions.changeLanguage(lng))
})

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps)
)(withNamespaces('translations')(Header)))

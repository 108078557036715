import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Button, Layout, Radio, Switch } from 'element-react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Dropzone, Form, Input, Select } from '../Common';
import { filePath } from '../../utils/variables';
import { whereInFilter, whereFilter } from '../../utils/utility';
import { apiGetVendorFactory } from '../../api/vendor-factory';
import { apiGetVendorCompany } from '../../api/vendor-company';
import { apiGetProductCategory } from '../../api/product-category';
import { apiSearchRole, apiSearchBusinessCompany } from '../../api/remote-search';

export class UserForm extends Component {

    state = {
        rules: {},
        roles: [],
        changePass: false,
	    isIgnoreUserable: 0,
        businessCompanies: [],
        assignedRoles: [],
	    productCategories: []
    }

    componentDidUpdate(prevProps) {
        if (this.props.visible && !prevProps.visible) {
	        apiGetProductCategory({limit: 'unlimited'}).then(({data}) => {
		        this.setState({productCategories: data});
	        });
        	const state = {
		        isIgnoreUserable: this.props.data.ignore_userable,
		        assignedRoles: JSON.parse(JSON.stringify(this.props.data.assigned_roles))
	        };

            this.setState(state, () => {
                this.props.data.assigned_roles.forEach((_, index) => {
                    this.fetchData(apiGetProductCategory, "productCategories", index);
                    this.fetchData(apiGetVendorCompany, "vendorCompanies", index);
                    this.fetchData(apiGetVendorFactory, "vendorFactories", index);
                });
            });
            this.initRules();
            this.getRole();
            if (!this.state.businessCompanies.length) {
                this.fetchData(apiSearchBusinessCompany, "businessCompanies");
            }
        }
    }

    fetchData = async (searchFn, stateKey, roleIndex) => {
        const selected = this.props.data.assigned_roles[roleIndex];
        if (roleIndex !== undefined) {
            const assignedRoles = [...this.state.assignedRoles];
            assignedRoles[roleIndex][`${stateKey}Loading`] = true;
            this.setState({ assignedRoles });
        }
        let optionFilter = {};
        if (stateKey === 'productCategories') {
            optionFilter = { ...whereFilter("business_company_id", selected.business_company_id) };
        }
        if (stateKey === 'vendorCompanies') {
            optionFilter = {
                ...whereInFilter('business_companies', selected.business_company_id),
                //...whereInFilter('product_categories', selected.product_category_ids),
            };
        }
        if (stateKey === 'vendorFactories') {
            optionFilter = {
	            ...whereInFilter('vendor_companies', selected.vendor_company_ids),
                ...whereInFilter('business_companies', selected.business_company_id),
              //  ...whereInFilter('product_categories', selected.product_category_ids),
            };
        }
        const { data } = await searchFn({ ...optionFilter, limit: 'unlimited' });
        if (roleIndex !== undefined) {
            const assignedRoles = [...this.state.assignedRoles];
            assignedRoles[roleIndex][stateKey] = data;
            assignedRoles[roleIndex][`${stateKey}Loading`] = false;
            this.setState({ assignedRoles });
        } else {
            this.setState({ [stateKey]: data });
        }
    }

    getRole = async () => {
        if (!this.state.roles.length) {
            const { data } = await apiSearchRole({ limit: 'unlimited' });
            this.setState({ roles: data });
        }
    }

    initRules = () => {
        const rules = {
            role_id: [
                { required: true, type: 'number', message: this.props.t('user.pleaseSelectRole'), trigger: 'change' }
            ],
            first_name: [
                { required: true, message: this.props.t('user.pleaseInputFirstName'), trigger: 'change' }
            ],
            last_name: [
                { required: true, message: this.props.t('user.pleaseInputLastName'), trigger: 'change' }
            ],
            email: [
                { required: true, message: this.props.t('user.pleaseInputEmail'), trigger: 'change' },
                { type: 'email', message: this.props.t('global.pleaseInputCorrectEmail'), trigger: 'change' }
            ],
            password: [
                { required: !this.props.data.id, message: this.props.t("profile.pleaseInputPassword"), trigger: 'blur' },
                {
                    validator: (_, value, callback) => {
                        if (value === '') {
                            callback(new Error(this.props.t("profile.pleaseInputPassword")));
                        } else {
                            if (this.props.data.password_confirmation !== '') {
                                this.refs.form.validateField('password_confirmation');
                            }
                            callback();
                        }
                    }
                }
            ],
            password_confirmation: [
                { required: !this.props.data.id, message: this.props.t("profile.pleaseInputConfirmPassword"), trigger: 'blur' },
                {
                    validator: (_, value, callback) => {
                        if (value === '') {
                            callback(new Error(this.props.t("profile.pleaseInputConfirmPassword")));
                        } else if (value !== this.props.data.password) {
                            callback(new Error(this.props.t("profile.passwordDoesNotMatch")));
                        } else {
                            callback();
                        }
                    }
                }
            ]
        };
        this.setState({ rules, changePass: false });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.refs.form.validate((valid) => {
            if (valid) {
                const data = { ...this.props.data };
                const business_company_ids = [];
                let product_category_ids = [];
                let vendor_company_ids = [];
                let vendor_factory_ids = [];
                data.assigned_roles.forEach(item => {
                    business_company_ids.push(item.business_company_id);
                    product_category_ids = [...product_category_ids, ...item.product_category_ids];
                    vendor_company_ids = [...vendor_company_ids, ...item.vendor_company_ids];
                    vendor_factory_ids = [...vendor_factory_ids, ...item.vendor_factory_ids];
                });
                data.business_company_ids = business_company_ids.filter(Number);
                data.product_category_ids = product_category_ids.filter(Number);
                data.vendor_company_ids = vendor_company_ids.filter(Number);
                data.vendor_factory_ids = vendor_factory_ids.filter(Number);
	            data.ignore_userable = this.state.isIgnoreUserable;
                this.props.onChangeState(data, 'selectedItem', () => {
                    this.props.onSubmit();
                });
            }
        });
    }

    onChangeBusinessCompany = (value, selectedItem, idx) => {
        const assigned_roles = [...this.props.data.assigned_roles];
        const product_categories = assigned_roles[idx].product_categories
            .filter(item => item.business_company_id === value);
        const product_category_ids = product_categories.map(item => item.id);

        assigned_roles[idx].business_company_id = value;
        assigned_roles[idx].business_companies = selectedItem;
        assigned_roles[idx].product_categories = product_categories;
        assigned_roles[idx].product_category_ids = product_category_ids;

        this.props.onChangeHandler(assigned_roles, 'assigned_roles', () => {
            this.onChangeProductCategory(product_category_ids, product_categories, idx);
            this.fetchData(apiGetProductCategory, 'productCategories', idx);
        });
    }

    onChangeProductCategory = (values, selectedItems, idx) => {
        const assigned_roles = [...this.props.data.assigned_roles];
        const vendor_companies = assigned_roles[idx].vendor_companies
            .filter(item => values.includes(item.product_category_id));
        const vendor_company_ids = vendor_companies.map(item => item.id);

        assigned_roles[idx].product_category_ids = values;
        assigned_roles[idx].product_categories = selectedItems;
        assigned_roles[idx].vendor_companies = vendor_companies;
        assigned_roles[idx].vendor_company_ids = vendor_company_ids;

        this.props.onChangeHandler(assigned_roles, 'assigned_roles', () => {
            this.onChangeVendorCompany(vendor_company_ids, vendor_companies, idx);
            this.fetchData(apiGetVendorCompany, 'vendorCompanies', idx);
        });
    }

    onChangeVendorCompany = (values, selectedItems, idx) => {
        const assigned_roles = [...this.props.data.assigned_roles];
        const vendor_factories = assigned_roles[idx].vendor_factories
            .filter(item => values.includes(item.vendor_company_id));
        const vendor_factory_ids = vendor_factories.map(item => item.id);

        assigned_roles[idx].vendor_company_ids = values;
        assigned_roles[idx].vendor_companies = selectedItems;
        assigned_roles[idx].vendor_factories = vendor_factories;
        assigned_roles[idx].vendor_factory_ids = vendor_factory_ids;

        this.props.onChangeHandler(assigned_roles, 'assigned_roles', () => {
            this.fetchData(apiGetVendorFactory, 'vendorFactories', idx);
        });
    }

    onChangeVendorFactory = (values, selectedItems, idx) => {
        const assigned_roles = [...this.props.data.assigned_roles];
        assigned_roles[idx].vendor_factory_ids = values;
        assigned_roles[idx].vendor_factories = selectedItems;

        this.props.onChangeHandler(assigned_roles, 'assigned_roles');
    }

	onChangeProductCategoryNotify = (values, selectedItems, idx) => {
		this.props.onChangeHandler(values, 'product_category_notify_ids');
	}

  onChangeMainCompany = (value, selected) => {
		this.props.onChangeHandler(value, 'main_company_id');
	}

    isChangePassword = (value) => {
        const rules = { ...this.state.rules };
        rules.password[0].required = value;
        rules.password_confirmation[0].required = value;
        this.setState({ rules, changePass: value });
    }

	isChangeIgnoreUserable = () => {
		this.setState({isIgnoreUserable: (this.state.isIgnoreUserable ? 0 : 1)}, () => {
			this.props.onChangeHandler(this.state.isIgnoreUserable, 'ignore_userable');
		});
	}

    onAddRole = () => {
        const assigned_roles = [...this.props.data.assigned_roles];
        assigned_roles.push({
            business_company_id: null,
            vendor_company_ids: [],
            vendor_companies: [],
            vendor_factory_ids: [],
            vendor_factories: [],
            product_category_ids: [],
            product_categories: []
        });
        this.props.onChangeHandler(assigned_roles, 'assigned_roles');

        const assignedRoles = [...this.state.assignedRoles];
        assignedRoles.push({});
        this.setState({ assignedRoles });
    }

    onRemoveRole = (index) => {
        const assigned_roles = [...this.props.data.assigned_roles];
        assigned_roles.splice(index, 1);

        this.props.onChangeHandler(assigned_roles, 'assigned_roles');

        const assignedRoles = [...this.state.assignedRoles];
        assignedRoles.splice(index, 1);
        this.setState({ assignedRoles });
    }

    getSelectedBC = (index) => {
        return this.props.data.assigned_roles.filter((item, idx) => idx !== index)
            .map(item => item.business_company_id);
    }

    render() {
        const { t, data } = this.props;
        const { assignedRoles } = this.state;
        if (!data) return null;
        return (
            <Modal backdrop="static" size="lg" isOpen={this.props.visible} toggle={this.props.onCancel}>
                <Form onSubmit={this.handleSubmit} model={data} ref="form" rules={this.state.rules} labelWidth="150">
                    <ModalHeader toggle={this.props.onCancel}>{data.id ? t('user.edit') : t('user.add')}</ModalHeader>
                    <ModalBody className="user-container scrollable-modal">
                        <Layout.Row gutter="20">
                            <Layout.Col md="12">
                                <Form.Item label={t('user.role')} prop="role_id">
                                    <Select
                                        value={data.role_id}
                                        onChange={(value) => this.props.onChangeHandler(value, 'role_id')}
                                        options={this.state.roles}
                                        width="auto"
                                    />
                                </Form.Item>
                                <Form.Item label={t('user.lastName')} prop="last_name">
                                    <Input
	                                    value={data.last_name}
	                                    onChange={(value) => this.props.onChangeHandler(value, 'last_name')}
                                    />
                                </Form.Item>
                                <Form.Item label={t('user.firstName')} prop="first_name">
                                    <Input
	                                    value={data.first_name}
	                                    onChange={(value) => this.props.onChangeHandler(value, 'first_name')}
                                    />
                                </Form.Item>
                                <Form.Item label={t('user.email')} prop="email">
                                    <Input
	                                    type="email"
	                                    value={data.email}
	                                    onChange={(value) => this.props.onChangeHandler(value, 'email')}
                                    />
                                </Form.Item>
                            </Layout.Col>
                            <Layout.Col md="12">
                                <Dropzone onFilesAdded={(file) => this.props.onChangeHandler(file, "image_binary")} fileType="image/*">
                                    {data.image && (typeof data.image === 'string') ?
                                        <img className="user-image" src={`${filePath}/${data.image}`} alt="" />
                                        :
                                        <React.Fragment>
                                            <i className="el-icon-upload"/>
                                            <div className="el-upload__text">{t('global.dropFilesHere')}<em> {t('global.clickToUpload')}</em></div>
                                        </React.Fragment>
                                    }
                                </Dropzone>
                            </Layout.Col>
                        </Layout.Row>
                        <Layout.Row gutter="20">
                            <Layout.Col md="12">
                                <Form.Item label={t('user.phone')} prop="phone">
                                    <Input
	                                    value={data.phone}
	                                    onChange={(value) => this.props.onChangeHandler(value, 'phone')}
                                    />
                                </Form.Item>
                            </Layout.Col>
                            <Layout.Col md="12">
                                <Form.Item label={t('user.gender')} prop="gender">
                                    <Radio.Group value={data.gender} onChange={(value) => this.props.onChangeHandler(value, 'gender')}>
                                        <Radio value="male" checked={data.gender === 'male'}>
	                                        {t('user.male')}
                                        </Radio>
                                        <Radio value="female" checked={data.gender === 'female'}>
	                                        {t('user.female')}
                                        </Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Layout.Col>
                        </Layout.Row>
                        {data.id &&
                            <Form.Item label={t('user.changePassword')}>
                                <Switch
                                    onText=""
                                    offText=""
                                    value={this.state.changePass}
                                    onChange={this.isChangePassword}
                                />
                            </Form.Item>
                        }
	                    <Form.Item label={t('user.showAllBusinesses')}>
		                    <Switch
			                    onText=""
			                    offText=""
			                    value={(!!this.state.isIgnoreUserable)}
			                    onChange={this.isChangeIgnoreUserable}
		                    />
	                    </Form.Item>
                        {(this.state.changePass || !data.id) && (
                            <React.Fragment>
                                <Layout.Row gutter="20">
                                    <Layout.Col md="12">
                                        <Form.Item label={t('user.password')} prop="password">
                                            <Input
	                                            value={data.password}
	                                            type="password"
	                                            onChange={(value) => this.props.onChangeHandler(value, 'password')}
                                            />
                                        </Form.Item>
                                    </Layout.Col>
                                    <Layout.Col md="12">
                                        <Form.Item label={t('user.passwordConfirmation')} prop="password_confirmation" labelWidth="180">
                                            <Input
	                                            value={data.password_confirmation}
	                                            type="password"
	                                            onChange={(value) => this.props.onChangeHandler(value, 'password_confirmation')}
                                            />
                                        </Form.Item>
                                    </Layout.Col>
                                </Layout.Row>
                            </React.Fragment>
                        )}
	                    <Layout.Row>
		                    <Layout.Col>
			                    <hr className="mt-0" />
			                    <Layout.Row utter="20">
                          <Form.Item label={t('user.mainCompany')}>
                            <Select
                              value={data.main_company_id}
                              onChange={(value, selected) => this.onChangeMainCompany(value, selected)}
                              options={data.ignore_userable ? this.state.businessCompanies : data.business_companies}
                              subLabel="code"
                              isFixed
                              isLoading={false}
                              isDisabled={false}
                              placeholder='No main company'
                            />
				                    </Form.Item>
				                    <Form.Item label={t('user.productCategoryNotify')}>
					                    <Select
						                    isMulti
						                    value={data.product_category_notify_ids}
						                    onChange={(value, selected) => this.onChangeProductCategoryNotify(value, selected)}
						                    options={this.state.productCategories}
						                    subLabel="code"
						                    isFixed
						                    isLoading={false}
						                    isDisabled={false}
						                    placeholder={t('user.noProductCategory')}
					                    />
				                    </Form.Item>
			                    </Layout.Row>
		                    </Layout.Col>
	                    </Layout.Row>
                        <Layout.Row>
                            <Layout.Col>
                                <Button type="text" className="pull-right button-add-icon" onClick={this.onAddRole}>
                                    <em className="mr-2 fas fa-plus-circle"/>
                                    {t('global.add')}
                                </Button>
                                {data.assigned_roles.map((role_data, idx) => (
                                    <React.Fragment key={`${role_data.id}-${role_data.business_company_id || 0}-${idx}`}>
                                        <div className="remove-icon">
                                            <Button type="text" onClick={() => this.onRemoveRole(idx)}>
                                                <em className="mr-2 fas fa-minus-circle text-danger"/>
                                            </Button>
                                            <span>{role_data.business_companies ? role_data.business_companies.name : ''}</span>
                                        </div>
                                        <hr className="mt-0" />
                                        <Layout.Row utter="20">
                                            <Layout.Col md="24">
                                                <Form.Item label={t('global.businessCompany')}>
                                                    <Select
                                                        value={role_data.business_company_id}
                                                        onChange={(value, selected) => this.onChangeBusinessCompany(value, selected, idx)}
                                                        options={this.state.businessCompanies.filter(item => !this.getSelectedBC(idx).includes(item.id))}
                                                        subLabel="code"
                                                        isFixed
                                                    />
                                                </Form.Item>
                                            </Layout.Col>
                                            <Layout.Col md="24">
                                                <Form.Item label={t('user.productCategoryView')}>
                                                    <Select
                                                        isMulti
                                                        value={role_data.product_category_ids}
                                                        onChange={(value, selected) => this.onChangeProductCategory(value, selected, idx)}
                                                        options={assignedRoles[idx] && assignedRoles[idx].productCategories ? assignedRoles[idx].productCategories : []}
                                                        subLabel="code"
                                                        isFixed
                                                        isLoading={assignedRoles[idx] && assignedRoles[idx].productCategoriesLoading}
                                                        isDisabled={!role_data.business_company_id || (assignedRoles[idx] && assignedRoles[idx].productCategoriesLoading)}
                                                        placeholder={t('user.allProductCategory')}
                                                    />
                                                </Form.Item>
                                            </Layout.Col>
                                            <Layout.Col md="24">
                                                <Form.Item label={t('global.vendorCompany')}>
                                                    <Select
                                                        isMulti
                                                        value={role_data.vendor_company_ids}
                                                        onChange={(value, selected) => this.onChangeVendorCompany(value, selected, idx)}
                                                        options={assignedRoles[idx] && assignedRoles[idx].vendorCompanies ? assignedRoles[idx].vendorCompanies : []}
                                                        isLoading={assignedRoles[idx] && assignedRoles[idx].vendorCompaniesLoading}
                                                        label="name"
                                                        isFixed
                                                        isDisabled={!role_data.business_company_id || (assignedRoles[idx] && assignedRoles[idx].vendorCompaniesLoading)}
                                                        placeholder={t('user.allVendorCompany')}
                                                    />
                                                </Form.Item>
                                            </Layout.Col>
                                            <Layout.Col md="24">
                                                <Form.Item label={t('global.vendorFactory')}>
                                                    <Select
                                                        isMulti
                                                        value={role_data.vendor_factory_ids}
                                                        onChange={(value, selected) => this.onChangeVendorFactory(value, selected, idx)}
                                                        options={assignedRoles[idx] && assignedRoles[idx].vendorFactories ? assignedRoles[idx].vendorFactories : []}
                                                        isLoading={assignedRoles[idx] && assignedRoles[idx].vendorFactoriesLoading}
                                                        label="name"
                                                        isFixed
                                                        isDisabled={!role_data.business_company_id || (assignedRoles[idx] && assignedRoles[idx].vendorFactoriesLoading)}
                                                        placeholder={t('user.allVendorCompany')}
                                                    />
                                                </Form.Item>
                                            </Layout.Col>
                                        </Layout.Row>
                                    </React.Fragment>
                                ))}
                            </Layout.Col>
                        </Layout.Row>
                    </ModalBody>
                    <ModalFooter>
                        <Form.Item>
                            <Button onClick={this.props.onCancel}>
	                            {t('global.cancel')}
                            </Button>
                            <Button type="primary" nativeType="submit">
	                            {t('global.submit')}
                            </Button>
                        </Form.Item>
                    </ModalFooter>
                </Form>
            </Modal>
        );
    }
}

export default withNamespaces()(UserForm);

export default class BusinessOther {
	static resolveNames(evaluation) {
		let output = [];

		if (!evaluation.ignore_others) {
			const items = evaluation.vendor_factory.business_companies_others;

			this.findItems(items, 'root', evaluation.business_company.id).forEach(item => {
				output.push({id: item.id, isRoot: true, label: this.addStyle(item.business_company.name)});
			})

			this.findItems(items, 'sub', evaluation.business_company.id).forEach(item => {
				output.push({id: item.id, isRoot: false, label: item.business_company.name});
			})
		}

		return output;
	}

	static findItems(items, type, businessCompanyId) {
		return items
			.filter(item =>
				type === item.type &&
				businessCompanyId !== item.business_company_id
			);
	}

	static getBusinessCompanyName(evaluation) {
		const items = evaluation.vendor_factory.business_companies_others;
		const isRoot = items
			.filter(item => 'root' === item.type)
			.map(item => item.business_company_id)
			.includes(evaluation.business_company.id);
		const name = evaluation.business_company.name;
		return isRoot ? this.addStyle(name) : name;
	}

	static addStyle(name) {
		return '<span class="font-weight-bold font-italic">' + name + '</span>';
	}

	static addStyles(items) {
		return items.map(name => this.addStyle(name));
	}
}

import React, { Component } from 'react';
import RSelect, { components } from 'react-select';
import PropTypes from 'prop-types';

class Select extends Component {
    state = {
        selected: this.props.isMulti ? [] : null,
        label: this.props.label ? this.props.label : 'name',
        uniqueKey: this.props.uniqueKey ? this.props.uniqueKey : 'id'
    }

    getSelected = () => {
        let selected;
        if (this.props.isMulti) {
            selected = this.props.options.filter(el => this.props.value.includes(el[this.state.uniqueKey]));
        } else {
            selected = (Array.isArray(this.props.options) ? this.props.options.find(el => el[this.state.uniqueKey] === this.props.value) : null) || null;
        }
        return selected;
    }

    changeHandler = (value) => {
        const { form } = this.context;
        form && form.onFieldChange();
        if (this.props.isMulti) {
            const result = value ? value.map(item => item[this.state.uniqueKey]) : [];
            this.props.onChange(result, value ? value : []);
        } else {
            this.props.onChange(value ? value[this.state.uniqueKey] : value, value);
        }
    }

    render() {
        const { label, uniqueKey } = this.state;
        const {
            options,
            onChange,
            value,
            subLabel,
            isFixed,
            width,
            placeholder,
            isClearable = true,
            isMulti,
            isDisabled,
            isFreezePane,
            ...otherProps
        } = this.props;

        return (
            <RSelect
                {...otherProps}
                className="basic-single"
                value={this.getSelected()}
                isMulti={isMulti}
                placeholder={placeholder ? placeholder : ""}
                menuShouldBlockScroll={isFixed}
                onChange={this.changeHandler}
                options={options}
                isClearable={isClearable}
                isDisabled={isDisabled}
                getOptionValue={option => option[uniqueKey]}
                getOptionLabel={option => option[label]}
                menuPosition={isFixed ? 'fixed' : 'absolute'}
                menuPlacement="auto"
                components={{
                    Option: props => {
                        if (subLabel) {
                            return (
                                <components.Option {...props}>
                                    <span>{props.data[label]}</span>
                                    <span className={`right-part ${props.isSelected ? 'selected' : ''}`}>
                                        {props.data[subLabel]}
                                    </span>
                                </components.Option>
                            )
                        }
                        return <components.Option {...props} />
                    }
                }}
                styles={{
                    option: base => ({
                        ...base,
                        whiteSpace: 'nowrap'
                    }),
                    menu: base => ({
                        ...base,
                        width: width ? width : '100%',
                        zIndex: 2,
                        minWidth: '100%',
                        overflow: 'visible',
                        position: isFreezePane ? 'sticky': 'absolute'
                    }),
                    singleValue: base => ({
                        ...base,
                        textOverflow: "unset"
                    }),
                    multiValue: (styles) => ({
                        ...styles,
                        color: '#00a83f',
                        backgroundColor: 'rgba(0, 168, 63, 0.1)',
                    }),
                    multiValueLabel: (styles) => ({
                        ...styles,
                        color: '#00a83f'
                    }),
                    multiValueRemove: (styles) => ({
                        ...styles,
                        ':hover': {
                            backgroundColor: '#00a83f',
                            color: '#fff',
                        },
                    }),
                    indicatorSeparator: base => ({
                        ...base,
                        // width: 0 // hide the separator between delete button and arrow
                    }),
                    control: base => ({
                        ...base,
                        boxShadow: "none"
                    }),
                    menuPortal: base => ({
                        ...base,
                        zIndex: 9999,
                        position: isFreezePane ? 'sticky': 'fixed'
                    }),
                }}
            />
        )
    }
}

Select.contextTypes = {
    form: PropTypes.any
};

export default Select;

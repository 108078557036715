import {apiGetEmail} from '../../api/email';

export const FETCH_EMAIL = 'FETCH_EMAIL';
export const FILTER_EMAIL = 'FILTER_EMAIL';

const fetchEmailSuccess = (result) => ({
    type: FETCH_EMAIL,
    emails: result.data,
    totalPage: result.last_page,
    total: result.total
});

export const fetchEmail = (filterData) => {
    return async dispatch => {
        try {
            const result = await apiGetEmail(filterData);
            dispatch(fetchEmailSuccess(result));
            return Promise.resolve();
        } catch (err) {
            return Promise.reject(err);
        }
    };
};

export const setFilterEmail = (filterData) => ({
    type: FILTER_EMAIL,
    filterData
});

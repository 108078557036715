export default class CertificationDraftModel {
	constructor(data = {}) {
		this.id = data.id || null;
		this.factory_evaluation = data.factory_evaluation || null;
		this.audit_number = data.factory_evaluation.audit_number || "";
		this.transaction_company_name_ch = data.factory_evaluation.vendor_factory.name_ch || "";
		this.transaction_company_name_jp = data.factory_evaluation.vendor_factory.name_jp || "";
		this.transaction_company_name_en = data.factory_evaluation.vendor_factory.name_en || "";
		this.address_jp = data.factory_evaluation.vendor_factory.address_jp || "";
		this.address_en = data.factory_evaluation.vendor_factory.address_en || "";
		this.address_ch = data.factory_evaluation.vendor_factory.address_ch || "";
		this.audit_date = data.factory_evaluation.audit_date ? new Date(data.factory_evaluation.audit_date) : null;
		this.cert_issuance_date = data.factory_evaluation.effective_date ? new Date(data.factory_evaluation.effective_date) : new Date();
    this.expiration_date = data.factory_evaluation.expiration_date ? new Date(data.factory_evaluation.expiration_date) : this.calc(this.cert_issuance_date, data.factory_evaluation.certification_issuance_id);
		this.country = data.factory_evaluation.vendor_factory.country_id || null;
		this.is_domestic = data.is_domestic || null;
		this.certification_issuance_id = data.factory_evaluation.certification_issuance_id || null;
	}

	calc(issuanceDate, periodId) {
			let expirationDate;
			let year = issuanceDate.getFullYear();
	    let month = issuanceDate.getMonth();
	    let day = issuanceDate.getDate();
			if (periodId && periodId !== 4) {
	    		expirationDate = new Date(year + periodId, month, day);
			}
			return expirationDate;
	}
}

import { setToken, removeToken } from '../../utils/auth';
import { signIn, apiCode, apiUserAuthenticated, apiUpdateProfile } from '../../api/auth';
import { routeList } from '../../Routes';
import { permission } from '../../utils/permission';

export const AUTH_CODE_VERIFY_FAIL = 'AUTH_CODE_VERIFY_FAIL';
export const AUTH_CODE_SET_SESSION = 'AUTH_CODE_SET_SESSION';

const authCodeVerifyFail = (isFail) => ({
	type: AUTH_CODE_VERIFY_FAIL,
	isFail
});

const authCodeSetSession = (session) => ({
	type: AUTH_CODE_SET_SESSION,
	session
});

export const authCodeVerify = (formData) => {
	return async dispatch => {
		try {
			const { data } = await apiCode(formData);
			setToken(data.access_token);
			dispatch(signInSuccess(data.access_token, data.refresh_token));
			return await Promise.resolve();
		} catch (err) {
			dispatch(authCodeVerifyFail(true));
			return await Promise.reject(err);
		}
	}
}

export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_PROFILE = 'AUTH_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_AVATAR = 'UPDATE_AVATAR';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_ROUTES = 'SET_ROUTES';

export const setAccessToken = (access_token, refresh_token) => ({
    type: SET_TOKEN,
    access_token,
    refresh_token,
});

const signInSuccess = (access_token, refresh_token) => ({
    type: AUTH_LOGIN,
    access_token,
    refresh_token
});

export const authSignIn = (formData, callback) => {
    return async dispatch => {
        try {
            const { data, status } = await signIn(formData);
            if (201 === status) {
	            dispatch(authCodeSetSession(data.session));
	            return await Promise.resolve();
            } else {
	            setToken(data.access_token);
	            dispatch(signInSuccess(data.access_token, data.refresh_token));
	            return await Promise.resolve();
            }
        } catch (err) {
            return await Promise.reject(err);
        } finally {
	        callback();
        }
    };
};

const getProfileSuccess = (user) => ({
    type: AUTH_PROFILE,
    user
});

/* get authenticated user */
export const getProfile = () => {
    return async dispatch => {
        try {
            const { data } = await apiUserAuthenticated();
            dispatch(generateRoutes(data.role_permissions, data.role));
            dispatch(getProfileSuccess(data));
            return Promise.resolve(data);
        } catch (err) {
            return Promise.reject(err);
        }
    };
};

const updateProfileSuccess = (user) => ({
    type: UPDATE_PROFILE,
    user
});

/* get authenticated user */
export const updateProfile = (id, formData) => {
    return async dispatch => {
        try {
            const { data } = await apiUpdateProfile(id, formData);
            dispatch(updateProfileSuccess(data));
            return Promise.resolve(data);
        } catch (err) {
            return Promise.reject(err);
        }
    };
};

/* get authenticated user */
export const updateAvatar = (image) => ({
    type: UPDATE_AVATAR,
    image
});

const signOutSuccess = () => ({
    type: AUTH_LOGOUT
});

export const signOut = () => {
    return async dispatch => {
        try {
            dispatch(signOutSuccess());
            removeToken();
            return Promise.resolve();
        } catch (error) {
            return Promise.reject(error);
        }
    };
};


/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
    if (route.meta && route.meta.roles) {
        let allowed = roles.some(role => route.meta.roles.includes(role.slug));
        if (allowed) return allowed;
        for (const route_role of route.meta.roles) {
            if (route_role.includes(':') && permission(route_role, { role_permissions: roles })) {
                return true;
            }
        }
    } else {
        return true;
    }
}

function filterAsyncRoutes(routes, roles) {
    const res = [];

    routes.forEach(route => {
        const tmp = { ...route };
        if (hasPermission(roles, tmp)) {
            if (tmp.submenu) {
                tmp.submenu = filterAsyncRoutes(tmp.submenu, roles);
            }
            res.push(tmp);
        }
    });

    return res;
}

export function generateRoutes(permissions, role) {
    let accessedRoutes;
    if (role.slug === process.env.REACT_APP_IS_ADMIN) {
        accessedRoutes = routeList;
    } else {
        accessedRoutes = filterAsyncRoutes(routeList, permissions);
    }
    return { type: SET_ROUTES, accessedRoutes };
}

import React from 'react';
import { Table } from 'reactstrap';
import { TableActions } from '../Common';
import { withNamespaces } from 'react-i18next';
import permission from '../../utils/permission';
import { apiGetFilters } from '../../api/vendor-factory';
import FilterContent from '../Common/FixedTable/FilterContent';

const VendorFactoryList = (props) => {
    const { vendorFactories, t } = props;
    return (
        <Table striped bordered hover responsive className='vendor-table'>
            <thead>
                <tr>
                    <th className="text-center">#</th>
                    <th><p style={{width: '150px'}}>{t('global.name_jp')}</p>
                        <FilterContent
                            id={8}
                            emptyOnEnd={false}
                            onlyOne={false}
                            loadDataFn={() => apiGetFilters('name_jp')}
                            filterOptions={{
                              id: 'name',
                              label: 'name'
                            }}
                            initData={props.filterData['name_jp']}
                            onFilterChange={(data) => props.onChangeWithFilter(data, 'name_jp')}
                            i18n={t}
                        >
                        <span className="el-table__column-filter-trigger" id={'Popover-' + 8}>
                            <i className={`fa fa-caret-square-down ${(props.filterData['name_jp'] && props.filterData['name_jp'].length) ? "filtered" : ""}`} />
                        </span>
                        </FilterContent>
                    </th>
                    <th><p style={{width: '150px'}}>{t('global.name_ch')}</p>
                        <FilterContent
                            id={7}
                            emptyOnEnd={false}
                            onlyOne={false}
                            loadDataFn={() => apiGetFilters('name_ch')}
                            filterOptions={{
                              id: 'name',
                              label: 'name'
                            }}
                            initData={props.filterData['name_ch']}
                            onFilterChange={(data) => props.onChangeWithFilter(data, 'name_ch')}
                            i18n={t}
                        >
                        <span className="el-table__column-filter-trigger" id={'Popover-' + 7}>
                            <i className={`fa fa-caret-square-down ${(props.filterData['name_ch'] && props.filterData['name_ch'].length) ? "filtered" : ""}`} />
                        </span>
                        </FilterContent>
                    </th>
                    <th><p style={{width: '150px'}}>{t('global.name_en')}</p>
                        <FilterContent
                            id={1}
                            emptyOnEnd={false}
                            onlyOne={false}
                            loadDataFn={() => apiGetFilters('name_en')}
                            filterOptions={{
                              id: 'name',
                              label: 'name'
                            }}
                            initData={props.filterData['name_en']}
                            onFilterChange={(data) => props.onChangeWithFilter(data, 'name_en')}
                            i18n={t}
                        >
                        <span className="el-table__column-filter-trigger" id={'Popover-' + 1}>
                            <i className={`fa fa-caret-square-down ${(props.filterData['name_en'] && props.filterData['name_en'].length) ? "filtered" : ""}`} />
                        </span>
                        </FilterContent>
                    </th>
                    <th><p style={{width: '150px'}}>{t('global.vendorCompany')}</p>
                        <FilterContent
                            id={2}
                            emptyOnEnd={false}
                            withoutNull={false}
                            onlyOne={false}
                            loadDataFn={() => apiGetFilters('vendor_company')}
                            filterOptions={{
                              id: 'id',
                              label: 'name'
                            }}
                            initData={props.filterData['vendor_company']}
                            onFilterChange={(data) => props.onChangeWithFilter(data, 'vendor_company')}
                            i18n={t}
                        >
                        <span className="el-table__column-filter-trigger" id={'Popover-' + 2}>
                            <i className={`fa fa-caret-square-down ${(props.filterData['vendor_company'] && props.filterData['vendor_company'].length) ? "filtered" : ""}`} />
                        </span>
                        </FilterContent>
                    </th>
                    <th><p style={{width: '150px'}}>{t('global.businessCompany')}</p>
                        <FilterContent
                            id={3}
                            emptyOnEnd={false}
                            withoutNull={true}
                            onlyOne={false}
                            loadDataFn={() => apiGetFilters('business_company')}
                            filterOptions={{
                              id: 'id',
                              label: 'name'
                            }}
                            initData={props.filterData['business_company']}
                            onFilterChange={(data) => props.onChangeWithFilter(data, 'business_company')}
                            i18n={t}
                        >
                        <span className="el-table__column-filter-trigger" id={'Popover-' + 3}>
                            <i className={`fa fa-caret-square-down ${(props.filterData['business_company'] && props.filterData['business_company'].length) ? "filtered" : ""}`} />
                        </span>
                        </FilterContent>
                    </th>
                    <th><p style={{width: '150px'}}>{t('global.productCategory')}</p>
                        <FilterContent
                            id={4}
                            emptyOnEnd={false}
                            withoutNull={true}
                            onlyOne={false}
                            loadDataFn={() => apiGetFilters('product_category')}
                            filterOptions={{
                              id: 'id',
                              label: 'name'
                            }}
                            initData={props.filterData['product_category']}
                            onFilterChange={(data) => props.onChangeWithFilter(data, 'product_category')}
                            i18n={t}
                        >
                        <span className="el-table__column-filter-trigger" id={'Popover-' + 4}>
                            <i className={`fa fa-caret-square-down ${(props.filterData['product_category'] && props.filterData['product_category'].length) ? "filtered" : ""}`} />
                        </span>
                        </FilterContent>
                    </th>
                    <th><p style={{width: '150px'}}>{t('global.address')}</p>
                        <FilterContent
                            id={5}
                            emptyOnEnd={false}
                            onlyOne={false}
                            loadDataFn={() => apiGetFilters('address')}
                            filterOptions={{
                              id: 'name',
                              label: 'name'
                            }}
                            initData={props.filterData['address']}
                            onFilterChange={(data) => props.onChangeWithFilter(data, 'address')}
                            i18n={t}
                        >
                        <span className="el-table__column-filter-trigger" id={'Popover-' + 5}>
                            <i className={`fa fa-caret-square-down ${(props.filterData['address'] && props.filterData['address'].length) ? "filtered" : ""}`} />
                        </span>
                        </FilterContent>
                    </th>
                    <th><p style={{width: '150px'}}>{t('company.country')}</p>
                        <FilterContent
                            id={6}
                            emptyOnEnd={false}
                            onlyOne={false}
                            loadDataFn={() => apiGetFilters('country')}
                            filterOptions={{
                              id: 'id',
                              label: 'name'
                            }}
                            initData={props.filterData['country_id']}
                            onFilterChange={(data) => props.onChangeWithFilter(data, 'country_id')}
                            i18n={t}
                        >
                        <span className="el-table__column-filter-trigger" id={'Popover-' + 6}>
                            <i className={`fa fa-caret-square-down ${(props.filterData['country_id'] && props.filterData['country_id'].length) ? "filtered" : ""}`} />
                        </span>
                        </FilterContent>
                    </th>
                    {Array.apply(null, Array(6)).map((i, idx)=> (
                        <React.Fragment key={idx}>
                            <th><p style={{minWidth: '100px'}}>{idx+1}. {t('contact.fullName')}</p></th>
                            <th><p style={{minWidth: '100px'}}>{idx+1}. {t('contact.email')}</p></th>
                            <th><p style={{minWidth: '100px'}}>{idx+1}. {t('contact.mobile')}</p></th>
                            <th><p style={{minWidth: '100px'}}>{idx+1}. {t('contact.phone')}</p></th>
                        </React.Fragment>
                    ))}
                    {permission('vendor-factory:update,delete') && <th/>}
                </tr>
            </thead>
            <tbody>
                {vendorFactories.map((vendor) => (
                    <tr key={vendor.id}>
                        <td align="center">{vendor.id}</td>
                        <td>{vendor.name_jp}</td>
                        <td>{vendor.name_ch}</td>
                        <td>{vendor.name_en}</td>
                        <td>{vendor.vendor_companies && vendor.vendor_companies.map(item => item.name).join(', ')}</td>
                        <td>{vendor.business_companies && vendor.business_companies.map(item => item.name).join(', ')}</td>
                        <td>{vendor.product_categories && vendor.product_categories.map(item => item.name).join(', ')}</td>
                        <td>{vendor.address}</td>
                        <td>{vendor.country && vendor.country.name}</td>
                        {vendor.contacts && vendor.contacts.slice(0,6).map((contact) => (
                            <React.Fragment key={contact.id}>
                                <td>{contact && contact.name}</td>
                                <td>{contact && contact.email}</td>
                                <td>{contact && contact.mobile_number}</td>
                                <td>{contact && contact.phone_number}</td>
                            </React.Fragment>
                        ))}
                        {vendor.contacts && vendor.contacts.length <= 6 && Array.apply(null, Array(6 - vendor.contacts.length)).map((i, idx)=> (
                            <React.Fragment key={idx}>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </React.Fragment>
                        ))}
                        {permission('vendor-factory:update,delete') &&
                            <td align="center">
                                <TableActions
                                    roleName="vendor-factory"
                                    onSelect={() => props.onSelect(vendor)}
                                    onDelete={() => props.onDelete(vendor.id)}
                                />
                            </td>
                        }
                    </tr>
                ))}
                {!vendorFactories.length &&
                    <tr>
                        <td colSpan="9" align="center">
                            {t('global.notMatchingRecordsFound')}
                        </td>
                    </tr>
                }
            </tbody>
        </Table>
    )
}

export default withNamespaces()(VendorFactoryList);
